import { MoloShare, MoloShareState, MoloShareType } from '../types/molo/moloShare';
import { NoteType } from '../types/client';
import { useAppConfig } from '../contexts/configContext';
import { useMutation } from '@tanstack/react-query';

interface Payload {
  docId: string;
  docContentType?: string;
  docTitle: string;
  docImage: string;
  agentId: string;
  shareType: MoloShareType;
  state: MoloShareState;
  processId: number | string;
  clientId: number | string;
  agentName: string;
}

export const useMutationShare = () => {
  const appConfig = useAppConfig();
  return useMutation({
    mutationFn: async ({
      docId,
      docContentType,
      docTitle,
      docImage,
      shareType,
      agentId,
      state,
      processId,
      clientId,
      agentName,
    }: Payload) => {
      const { data } = await appConfig.moloApi.post<MoloShare>(
        '/Molo/Handler.Share.Report.ashx',
        undefined,
        {
          params: {
            agentid: agentId,
            assetuid: docId,
            shareid: 0,
            typeid: shareType,
            contenttype: docContentType,
            contenttitle: docTitle,
            shareimageurl: docImage,
            stateid: state,
            processid: processId,
            clientid: clientId,
          },
        },
      );

      if (shareType === MoloShareType.other) {
        await appConfig.privateOpsApi.post(`/Ops/NoteAdd`, {
          Comment: '',
          ClientID: clientId,
          TypeId: NoteType.share,
          Action: `${agentName} download PDF "${docTitle}"`,
          AssetUID: docId,
          ProcessID: processId,
          ShareTypeID: data.item.typeid,
        });
      } else if (shareType !== MoloShareType.preview) {
        await appConfig.privateOpsApi.post(`/Ops/NoteAdd`, {
          Comment: '',
          ClientID: clientId,
          TypeId: NoteType.share,
          Action: `${agentName} shared "${docTitle || 'a piece of content'}"`,
          AssetUID: docId,
          ProcessID: processId,
          ShareTypeID: data.item.typeid,
        });
      }

      return data;
    },
    onSuccess: (response) => {
      appConfig?.queryClient.invalidateQueries({
        queryKey: [`/Ops/ClientDetail/${response.item.clientid}`],
      });
    },
  });
};
