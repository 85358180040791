import { Agent } from '../../../../common/types/agent';
import { Box, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SAVED_MESSAGE,
  useAppConfig,
} from '../../../../common/contexts/configContext';
import { StepContentProps } from '../../../../common/components/stepDialog';
import { useAllocateClientMutation } from '../../../mutations';
import { useEffect, useState } from 'react';
import { useMyProfile } from '../../../../common/hooks/auth';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useStepDialogApi } from '../../../../common/contexts/stepDialogContext';
import AgentList from '../../../../agents/components/agentList';
import NotesDialog from '../../../../clients/components/notesDialog';
import respondIo from '../../../../common/lib/respondIo';

const AllocateDialog = ({ client }: StepContentProps) => {
  const { addCloseCallback, removeCloseCallback } = useStepDialogApi();
  const { enqueueSnackbar } = useSnackbar();
  const { config } = useAppConfig();
  const [isNotesOpen, setIsNotesOpen] = useState(false);
  const allocateMutation = useAllocateClientMutation();
  const profile = useMyProfile();
  const { data: advisers } = useQuery<Agent[]>({
    queryKey: ['/OpsAdvisers'],
  });

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      ID: client.id,
      AllocatedAgentID: client.allocatedAgentID,
    },
  });

  useEffect(() => {
    addCloseCallback('allocate', () => handleSubmit(onSubmit)());
    return () => {
      removeCloseCallback('allocate');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [advisers]);

  useEffect(
    () =>
      reset({
        ID: client.id,
        AllocatedAgentID: client.allocatedAgentID,
      }),
    [client.id, reset, client.allocatedAgentID],
  );

  const onSubmit = async (data: { ID: number; AllocatedAgentID: number }) => {
    if (!data.AllocatedAgentID || !data.ID) {
      return;
    }

    const adviser = advisers?.find(({ id }) => id === data.AllocatedAgentID);

    await allocateMutation.mutateAsync(
      { ...data, AllocatedAgentFullname: adviser?.fullname ?? '', agentName: profile?.name ?? '' },
      {
        onSuccess: () => {
          enqueueSnackbar(DEFAULT_SAVED_MESSAGE, { variant: 'success' });
          // Send whatsapp message to the adviser with "client allocated" event
          if (adviser) {
            respondIo.sendAllocatedMessageToTheAdviser(
              adviser,
              `${config.rootUrl}/${adviser.username}/dashboard`,
            );
          }
        },
        onError: (error) =>
          enqueueSnackbar((error as Error).message || DEFAULT_ERROR_MESSAGE, {
            variant: 'error',
          }),
      },
    );
  };

  return (
    <Box>
      <Box
        bgcolor="info.main"
        p={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        sx={{ border: 1, borderColor: '#C7C8CA45', elevation: 0, borderRadius: 1 }}
      >
        <Box>
          <Typography variant="body2" fontWeight={700}>
            Client decision
          </Typography>
          <Typography variant="body2">{client.decisions?.[0]?.text || '--'}</Typography>
        </Box>
      </Box>

      <Box mt={6} mb={4} borderBottom={1} pb={1}>
        <Typography variant="h5">
          Select the adviser you would like to link this client to...
        </Typography>
      </Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="AllocatedAgentID"
          control={control}
          render={({ field }) => (
            <AgentList
              type="advisers"
              selectedAgentId={field.value}
              onSelect={(agent) => field.onChange(agent.id)}
            />
          )}
        />
      </form>

      <NotesDialog clientId={client.id} open={isNotesOpen} onClose={() => setIsNotesOpen(false)} />
    </Box>
  );
};

export default AllocateDialog;
