import { useAppConfig } from '../contexts/configContext';
import { useCallback } from 'react';

/**
 * Hook to work with app url (generate full url, etc)
 */
export const useUrl = () => {
  const appConfig = useAppConfig();

  return {
    makeFullUrl: useCallback(
      (path: string) => {
        return `${appConfig?.config?.rootUrl}${path}`;
      },
      [appConfig?.config?.rootUrl],
    ),
  };
};
