import { Box, Tab, Tabs } from '@mui/material';
import { StepContentProps } from '../../../../common/components/stepDialog';
import { useState } from 'react';
import AgentDocs from '../../../../agents/components/agentDocs';
import AgentLeads from '../../../../agents/components/agentLeads';
import DecisionForm from '../../../../common/components/decisionForm';
import Instructions from '../../../../common/components/instructions';

const decisions = [
  'Positive - looking forward to the adviser’s call',
  'Uncertain - still want to use their own adviser',
  'Uncertain - sceptical of value',
  'Allocated to virtual adviser',
  'Couldn’t contact',
  'Deceased',
];

export const dialogsTabStyles = {
  '& .MuiTab-root.Mui-selected': {
    fontWeight: 600,
  },
  '& .MuiTab-root': {
    fontWeight: 500,
  },
  borderBottom: 1,
  borderColor: '#DDDEE5',
};

const ContactDialog = ({ client, onFormLoadingStateChange, process }: StepContentProps) => {
  const [currentTab, setCurrentTab] = useState(0);
  return (
    <>
      <Tabs
        sx={dialogsTabStyles}
        value={currentTab}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        onChange={(_, value) => setCurrentTab(value)}
      >
        <Tab label="Guide" />
        <Tab label="Decision" />
        <Tab label="docs" />
        <Tab label="responses" />
      </Tabs>
      <Box sx={{ mt: 6 }}>
        <Box sx={{ display: currentTab === 0 ? 'block' : 'none' }}>
          <Instructions keyword="contact" />
        </Box>
        <Box sx={{ display: currentTab === 1 ? 'block' : 'none' }}>
          <DecisionForm
            onFormLoadingStateChange={onFormLoadingStateChange}
            decisions={decisions}
            process={process}
            client={client}
          />
        </Box>
        <Box sx={{ display: currentTab === 2 ? 'block' : 'none' }}>
          <AgentDocs clientId={client.id} processId={process.id} keyword="contact" />
        </Box>
        {currentTab === 3 && <AgentLeads clientId={client.id} processId={process.id} />}
      </Box>
    </>
  );
};

export default ContactDialog;
