import { tokenLogin } from '../common/hooks/auth';
import { useBrowserNavigate } from '../common/hooks/useBrowserNavigate';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import PageLoader from '../common/components/pageLoader';

interface AuthCookie {
  token: string;
  parentToken: string;
  id: number;
}

const readAuthCookie = (): AuthCookie | null => {
  try {
    return JSON.parse(Cookies.get('agentSecret') ?? '');
  } catch {
    return null;
  } finally {
    Cookies.remove('agentSecret');
  }
};

/**
 * Intermediate page to authorize by token
 */
const AutomaticLoginPage = () => {
  const { username } = useParams();
  const { changeCurrentWindowUrl } = useBrowserNavigate();

  useEffect(() => {
    const data = readAuthCookie();
    if (data) {
      tokenLogin({ token: data.parentToken, profileId: data.id ? String(data.id) : undefined });
    }
    changeCurrentWindowUrl(`/${username}/dashboard`);
  }, [username, changeCurrentWindowUrl]);

  return <PageLoader />;
};

export default AutomaticLoginPage;
