import { Box, Chip, IconButton, Typography } from '@mui/material';
import { ClientFull, NoteType } from '../../common/types/client';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { formatDateTime } from '../../common/lib/date';
import { Icon } from '@iconify/react';
import { MoloLeads } from '../../common/types/molo/moloLead';
import { useAgentData } from '../../common/hooks/auth';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import LeadDialog from '../../leads/components/leadDialog';
import TableLoadingState from '../../common/components/tableLoadingState';

interface AgentLeadsProps {
  clientId: number;
  processId: number | string;
}

const AgentLeads = ({ clientId, processId }: AgentLeadsProps) => {
  const agentData = useAgentData();
  const [selectedLeadId, setSelectedLeadId] = useState<string | null>(null);
  const { data: leads } = useQuery<MoloLeads>({
    queryKey: [
      '/Molo/Handler.Share.Report.ashx',
      {
        mode: 'leads',
        days: 90,
        sharetypeidlist: '1,2,3,4,5,6,7,8,9,12,99',
        agentid: agentData?.item.id,
        clientid: clientId,
        processidlist: processId,
      },
    ],
    enabled: Boolean(agentData?.item.id),
  });

  const { data: client } = useQuery<ClientFull>({
    queryKey: [`/Ops/ClientDetail/${clientId}`],
  });

  // display leads and letter notes at the same time
  const dataToDisplay = [
    // collect leads data
    ...[leads?.items?.item || []].flat().map((lead) => ({
      id: lead.id,
      fullname: `${lead.contactfirstname} ${lead.contactsurname}`,
      createdate: new Date(lead.createdate),
      sharecontenttitle: lead.sharecontenttitle,
      contenttype: lead.contenttype,
      typenote: lead.typenote,
      isLead: true,
    })),
    // collect letter notes data (only notes with specific processId and typeID)
    ...[client?.notes || []]
      .flat()
      .filter((note) => {
        return (
          note.processID === processId && [NoteType.confirm, NoteType.view].includes(note.typeID)
        );
      })
      .map((note) => ({
        id: note.id,
        fullname: client?.fullname,
        createdate: new Date(note.created),
        sharecontenttitle: note.action,
        contenttype: null,
        typenote: note.typeNote,
        isLead: false,
      })),
  ];

  const columns: GridColumns<typeof dataToDisplay[0]> = [
    {
      field: 'fullname',
      headerName: 'Lead name',
      flex: 1,
      minWidth: 150,
      renderCell: ({ value }) => (
        <Typography fontWeight={600} variant="caption">
          {value}
        </Typography>
      ),
    },
    {
      field: 'createdate',
      headerName: 'Submitted',
      width: 200,
      type: 'dateTime',
      valueFormatter: ({ value }) => formatDateTime(value),
    },
    {
      field: 'sharecontenttitle',
      headerName: 'Content',
      flex: 2,
      minWidth: 250,
      renderCell: ({ value, row }) => (
        <Box pb={1} sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          <Typography variant="caption" fontWeight={600}>
            {value}
          </Typography>
          {row.contenttype && (
            <Box>
              <Chip label={row.contenttype} />
            </Box>
          )}
        </Box>
      ),
    },
    { field: 'typenote', minWidth: 150, headerName: 'Status' },
    {
      field: 'actions',
      type: 'actions',
      minWidth: 150,
      width: 32,
      getActions: ({ row }) =>
        row.isLead
          ? [
              <IconButton
                key="options"
                size="small"
                onClick={() => setSelectedLeadId(String(row.id))}
              >
                <Icon width={24} icon="material-symbols:more-vert" />
              </IconButton>,
            ]
          : [],
    },
  ];

  if (!leads) {
    return <TableLoadingState />;
  }

  return (
    <>
      <DataGrid
        autoHeight
        disableSelectionOnClick
        rows={dataToDisplay}
        columns={columns}
        hideFooter
        disableColumnMenu
        rowHeight={70}
        headerHeight={40}
        initialState={{
          sorting: {
            sortModel: [{ field: 'createdate', sort: 'desc' }],
          },
        }}
      />
      <LeadDialog leadId={selectedLeadId} onClose={() => setSelectedLeadId(null)} />
    </>
  );
};

export default AgentLeads;
