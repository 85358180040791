import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import { Box, Checkbox, FormControlLabel, Skeleton } from '@mui/material';
import { PortableText, PortableTextProps } from '@portabletext/react';
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useStepDialogApi } from '../contexts/stepDialogContext';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';

const getYoutubeId = (url: string): string | undefined =>
  /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[&?]v=)|youtu\.be\/)([^\s"&/?]{11})/gi.exec(
    url,
  )?.[1];

interface InstructionsProps {
  keyword: string;
  onSubmit?: () => void;
}

const Instructions = ({ keyword, onSubmit }: InstructionsProps) => {
  const { addCloseCallback, removeCloseCallback } = useStepDialogApi();
  // do not close the dialog when user clicks "finish" on instruction step if there is a callback
  useEffect(() => {
    addCloseCallback('verifyIdForm', async () => {
      if (onSubmit) {
        onSubmit();
        return false;
      }
    });
    return () => {
      removeCloseCallback('verifyIdForm');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data, isLoading } = useQuery<{
    result: { _id: string; html: PortableTextProps['value'] }[];
  }>({
    queryKey: [
      '/Molo/Handler.Sanity.ashx',
      { query: `*[_type == "instruction" && "${keyword}" == keyword]` },
      'postForm',
    ],
  });

  const instruction = data?.result?.[0];

  return (
    <Box sx={{ border: 1, borderColor: '#C7C8CA8F', p: 4 }}>
      {isLoading && (
        <Box className="space-y-2">
          {Array.from({ length: 3 }).map((_, index) => (
            <Skeleton variant="text" key={index} />
          ))}
        </Box>
      )}
      {instruction?.html && (
        <Box sx={{ '& ul': { m: 0, pl: 0.5 } }}>
          <PortableText
            components={{
              types: {
                youtube: ({ value }) => {
                  const id = getYoutubeId(value.url);
                  return id ? (
                    <div style={{ maxWidth: 700 }}>
                      <LiteYouTubeEmbed id={id} title="instruction video" />
                    </div>
                  ) : null;
                },
              },
              listItem: {
                bullet: ({ children }) => (
                  <Box>
                    <FormControlLabel
                      componentsProps={{
                        typography: {
                          color: 'text.darkGray',
                          variant: 'inherit',
                          fontWeight: 500,
                        },
                      }}
                      control={<Checkbox size="small" sx={{ p: 0.7, mr: 2 }} />}
                      label={children}
                    />
                  </Box>
                ),
              },
            }}
            value={instruction.html}
          />
        </Box>
      )}
    </Box>
  );
};

export default Instructions;
