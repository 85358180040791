import { Box, Chip, Skeleton, Stack } from '@mui/material';
import { Client } from '../types/client';
import { Icon } from '@iconify/react';
import { PROCESS_NOT_STARTED_ID } from '../types/clientProcess';
import { STATE_DONE_ID } from '../types/clientState';
import { useIsClientUnderMonitoring } from '../hooks/useIsClientUnderMonitoring';
import { useQueryProcesses } from '../hooks/useQueryProcesses';
import noop from 'lodash/noop';

interface ProcessIndicatorProps {
  client: Client;
}

/**
 * Component to render client process indicator in the client list
 */
const ProcessIndicator = ({ client }: ProcessIndicatorProps) => {
  const isMonitoring = useIsClientUnderMonitoring(client);
  const { data: clientProcesses, isLoading } = useQueryProcesses(isMonitoring);

  if (isLoading || !clientProcesses) return <Skeleton sx={{ width: 1 }} height={12} />;

  if (client.isClosed) {
    return (
      <Chip
        label="Closed"
        color="warning"
        deleteIcon={<Icon icon="mdi:account-off" />}
        onDelete={noop}
      />
    );
  }

  // Ignore client "not started" process and display the next one.
  // Also show the next step if current step is done.
  let currentPosition = clientProcesses.findIndex(({ id }) => id === client.processID);
  if (
    currentPosition >= 0 &&
    client.stateID === STATE_DONE_ID &&
    clientProcesses[currentPosition + 1]
  ) {
    currentPosition = currentPosition + 1;
  }

  return (
    <Stack direction="row">
      {clientProcesses.map((process, index) => {
        const shouldMakeLastStepActive = index === clientProcesses.length - 1 && client.isSigned;

        return (
          <Box
            key={process.id}
            sx={[
              { display: 'flex', alignItems: 'center' },
              index !== currentPosition &&
                !shouldMakeLastStepActive &&
                index < clientProcesses.length && {
                  '&::before': {
                    width: 6,
                    height: 6,
                    borderRadius: '50%',
                    display: 'block',
                    content: '""',
                    backgroundColor: index < currentPosition ? 'primary.main' : 'gray.main',
                  },
                },
              index < clientProcesses.length - 1 && {
                '&::after': {
                  display: 'block',
                  content: '""',
                  border: 1,
                  height: 0,
                  width: 20,
                  borderColor: index < currentPosition ? 'primary.main' : 'gray.main',
                },
              },
            ]}
          >
            {(shouldMakeLastStepActive || index === currentPosition) && (
              <Chip
                label={shouldMakeLastStepActive ? 'signed' : process.note}
                deleteIcon={
                  shouldMakeLastStepActive ? (
                    <Icon icon="material-symbols:check-small" />
                  ) : undefined
                }
                onDelete={shouldMakeLastStepActive ? noop : undefined}
                color={process.id === PROCESS_NOT_STARTED_ID ? 'default' : 'info'}
              />
            )}
          </Box>
        );
      })}
    </Stack>
  );
};

export default ProcessIndicator;
