import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import ExportToolbar from '../common/components/exportToolbar';

const agentProcesses = new Set(['6', '7', '8']);

interface ProcessAverage {
  id: number;
  processID: number;
  agentID: number;
  averageTimeInStep: string;
  processNote: string;
  agentFullname?: string;
  allocatedAgentFullname?: string;
  allocatedAgentID?: number;
}

const columns: GridColumns = [
  {
    field: 'agentFullname',
    headerName: 'Agent',
    flex: 1,
    valueGetter: (parameters) =>
      parameters.row.agentFullname ?? parameters.row.allocatedAgentFullname,
  },
  { field: 'averageTimeInStep', headerName: 'Average Time In Step', flex: 1 },
];

const ProcessReportPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const isAgentProcess = id && agentProcesses.has(id);
  const { data: processAverageStats } = useQuery<ProcessAverage[]>({
    queryKey: [
      isAgentProcess
        ? '/Ops/ClientProcessAverageTimeAllocatedAgents'
        : '/Ops/ClientProcessAverageTimeAgents',
      { ProcessID: id },
    ],
  });

  return (
    <DataGrid
      autoHeight
      components={{ Toolbar: ExportToolbar }}
      rows={processAverageStats ?? []}
      columns={columns}
      onRowClick={({ row }) =>
        navigate(
          isAgentProcess
            ? `/reports/process/${id}/agents/${row.allocatedAgentID}`
            : `/reports/process/${id}/advisers/${row.agentID}`,
        )
      }
    />
  );
};

export default ProcessReportPage;
