import { AppBar, Box, Divider, Toolbar } from '@mui/material';
import { ReactNode } from 'react';
import Content from '../context';

export const DialogHeaderDivider = () => (
  <Box sx={{ mx: 2, height: 24 }}>
    <Divider orientation="vertical" sx={{ backgroundColor: '#C7C8CA80' }} />
  </Box>
);

interface BaseDialogHeaderProps {
  children: ReactNode;
}

const BaseDialogHeader = ({ children }: BaseDialogHeaderProps) => (
  <AppBar elevation={0} position="static" sx={{ py: 1, '& .MuiToolbar-root': { px: 0 } }}>
    <Content>
      <Toolbar>{children}</Toolbar>
    </Content>
  </AppBar>
);

export default BaseDialogHeader;
