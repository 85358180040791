import { useQueryMe } from '../hooks/auth';
import LoginPage from '../../login/loginPage';
import PageLoader from './pageLoader';

interface PrivateRouteProps {
  element: JSX.Element;
}

/**
 * Require auth before render component (element property)
 */
const PrivateRoute = ({ element }: PrivateRouteProps) => {
  // Initiate loading auth data
  const { isLoading, data } = useQueryMe();

  if (isLoading) {
    return <PageLoader />;
  }

  // Render element if viewer is authenticated
  if (data?.authData) {
    return element;
  }

  // Render login page if viewer is not authenticated (in development mode)
  return <LoginPage />;
};

export default PrivateRoute;
