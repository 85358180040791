import { Box, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import { GridFilterModel } from '@mui/x-data-grid';
import { useMyProfile } from '../common/hooks/auth';
import { useState } from 'react';
import ClientsInProgress from '../clients/components/clientsInProgress';
import Content from '../common/components/context';
import Layout from '../common/components/layout';
import OpsDashboard from './opsDashboard';

export const tabsStyle = {
  mb: '-1px',
  borderBottom: 1,
  borderColor: '#DDDEE5',
  '& .MuiTabs-indicator': { backgroundColor: 'secondary.main' },
  '& .MuiTab-root.Mui-selected': {
    color: 'secondary.main',
    fontWeight: 600,
  },
  '& .MuiTab-root': {
    color: '#B9BBBF',
    fontWeight: 500,
  },
};

export const mainHeaderStyle = {
  backgroundColor: 'common.white',
  maxWidth: { lg: '1150px' },
};

const OpsPage = () => {
  const profile = useMyProfile();
  const [currentTab, setCurrentTab] = useState(0);
  const [filterModel, setFilterModel] = useState<GridFilterModel>();

  const handleFilterClients = (query: string) => {
    setFilterModel({
      items: [],
      quickFilterValues: [query],
    });
  };
  if (!profile) return null;

  return (
    <Layout>
      <Content sx={mainHeaderStyle}>
        <Box
          height={52}
          marginY={2}
          ml={-1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h3" fontWeight={400} color="secondary.main">
            Reintermediations
          </Typography>
          {currentTab !== 0 && (
            <TextField
              onChange={(event) => handleFilterClients(event.target.value)}
              placeholder="Filter clients..."
            />
          )}
        </Box>
        <Stack ml={-3} direction="row" justifyContent="space-between" alignItems="center">
          <Tabs
            sx={tabsStyle}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            value={currentTab}
            onChange={(_, newValue: number) => setCurrentTab(newValue)}
          >
            <Tab label="Dashboard" />
            <Tab label="To allocate" />
            <Tab label="Closed" />
            <Tab label="Allocated" />
          </Tabs>
        </Stack>
      </Content>
      <Box bgcolor="common.white" sx={{ flexGrow: 1 }} paddingY={4}>
        <Content>
          {currentTab === 0 && <OpsDashboard onChangeTab={setCurrentTab} />}
          {currentTab === 1 && (
            <ClientsInProgress
              filterModel={filterModel}
              apiQueryFilters={{ isAllocated: 0, agentId: profile.id }}
            />
          )}
          {currentTab === 3 && (
            <ClientsInProgress
              filterModel={filterModel}
              statusHeaderName="Status with adviser"
              apiQueryFilters={{ isAllocated: 1, agentId: profile.id }}
              subQueryFilters={{ isAllocated: 1, isSigned: 1, agentId: profile.id }}
            />
          )}
          {currentTab === 2 && (
            <ClientsInProgress
              filterModel={filterModel}
              apiQueryFilters={{ IsClosed: 1, isAllocated: 0, agentId: profile.id }}
              subQueryFilters={{ IsClosed: 1, isAllocated: 1, agentId: profile.id }}
            />
          )}
        </Content>
      </Box>
    </Layout>
  );
};

export default OpsPage;
