import './index.css';
import { ConfigProvider, useAppConfig } from './common/contexts/configContext';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import App from './app';
import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import theme from './theme';

/**
 * A wrapper for QueryClientProvider to get app config from context
 */
const AppQueryClientProvider = ({ children }: { children: ReactNode }) => {
  const { queryClient } = useAppConfig();
  return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>;
};

const root = ReactDOM.createRoot(document.querySelector('#root') as HTMLElement);
root.render(
  <React.StrictMode>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <ConfigProvider>
        <AppQueryClientProvider>
          <App />
        </AppQueryClientProvider>
      </ConfigProvider>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
