import { Box, Skeleton } from '@mui/material';

const TableLoadingState = () => (
  <Box>
    <Skeleton variant="rectangular" height={40} />
    {Array.from({ length: 7 }).map((_, index) => (
      <Skeleton variant="rectangular" key={index} height={70} sx={{ mt: 1 }} />
    ))}
  </Box>
);

export default TableLoadingState;
