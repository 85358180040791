import { Box } from '@mui/material';
import { MoloDoc } from '../../common/types/molo/moloDoc';

interface VideoContentProps {
  doc: MoloDoc;
}

const VideoContent = ({ doc }: VideoContentProps) => {
  if (doc.contentType.contentTypeSelection !== 'videoFile') return null;

  return (
    <Box>
      <video controls style={{ width: '100%' }}>
        <source src={doc.contentType.videoFile.url} type="video/mp4" />
        Sorry, your browser doesn&apos;t support embedded videos.
      </video>
    </Box>
  );
};

export default VideoContent;
