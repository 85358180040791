import {
  Box,
  Button,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import {
  ClientFull,
  ClientGender,
  ClientPhone,
  ClientVerification,
  ClientVerificationShareStatus,
} from '../../../../common/types/client';
import { Controller, useForm } from 'react-hook-form';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SAVED_MESSAGE,
} from '../../../../common/contexts/configContext';
import { MoloShareState, MoloShareType } from '../../../../common/types/molo/moloShare';
import { StepContentProps } from '../../../../common/components/stepDialog';
import { useBrowserNavigate } from '../../../../common/hooks/useBrowserNavigate';
import { useEffect, useState } from 'react';
import { useMutationShare } from '../../../../common/hooks/useMutationShare';
import { useMyProfile } from '../../../../common/hooks/auth';
import { useQueryVerifyDoc } from '../../../../common/hooks/useQueryMoloDocs';
import { useSnackbar } from 'notistack';
import { useStepDialogApi } from '../../../../common/contexts/stepDialogContext';
import { useUpdateClientMutation } from '../../../mutations';
import { validateAfricanId, validatePassportId } from '../../../../common/lib/validations';
import ShareDialog from '../../../../docs/components/shareDialog';

/**
 * Additional step to verify the client's ID and mobile phone
 */
const IdForm = ({ client, onSubmit }: StepContentProps & { onSubmit: () => void }) => {
  const { addCloseCallback, removeCloseCallback } = useStepDialogApi();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useUpdateClientMutation();
  const profile = useMyProfile();
  let mobilePhoneIndex = client.phones.findIndex(({ typeID }) => typeID === ClientPhone.mobile);
  const { handleSubmit, control, watch, reset } = useForm({
    defaultValues: {
      ...client,
      phones: [
        ...client.phones,
        // Add a new mobile phone if there is no one
        ...(mobilePhoneIndex === -1
          ? [
              {
                number: '',
                typeID: ClientPhone.mobile,
              },
            ]
          : []),
      ] as ClientFull['phones'],
    },
  });
  const isIDNumber = watch('isIDNumber');
  const birthDate = watch('dateofbirth');
  const genderID = watch('genderID');

  // reset the form when client changes
  useEffect(
    () =>
      reset({
        ...client,
        phones: [
          ...client.phones,
          // Add a new mobile phone if there is no one
          ...(mobilePhoneIndex === -1
            ? [
                {
                  number: '',
                  typeID: ClientPhone.mobile,
                },
              ]
            : []),
        ] as ClientFull['phones'],
      }),
    [client, mobilePhoneIndex, reset],
  );

  // If we add a new mobile phone, we need to update the index
  if (mobilePhoneIndex === -1) {
    mobilePhoneIndex = client.phones.length;
  }

  const makeSubmit = (data: ClientFull) => {
    mutation.mutate(
      { ...data, agentName: profile?.name ?? '' },
      {
        onError: (error) =>
          enqueueSnackbar((error as Error).message || DEFAULT_ERROR_MESSAGE, { variant: 'error' }),
        onSuccess: () => {
          enqueueSnackbar(DEFAULT_SAVED_MESSAGE, { variant: 'success' });
          onSubmit();
        },
      },
    );
  };

  useEffect(() => {
    addCloseCallback('verifyForm', async () => {
      await handleSubmit(makeSubmit)();
      return false;
    });
    return () => {
      removeCloseCallback('verifyForm');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box component="form" onSubmit={handleSubmit(makeSubmit)}>
      <Typography variant="body2" color="text.secondary" sx={{ mt: 4 }}>
        Before you begin, please check the client’s ID and cell number are OK. You will needs this
        information for the rest of the process
      </Typography>
      <Grid sx={{ mt: 0 }} container spacing={4}>
        <Grid item xs={12} alignSelf="flex-end">
          <Controller
            name="isIDNumber"
            control={control}
            render={({ field }) => (
              <RadioGroup
                row
                {...field}
                // FormControlLabel value property doesn't accept boolean values so we need to convert it to string
                value={String(field.value)}
                onChange={(event) => field.onChange(event.target.value === 'true')}
              >
                <FormControlLabel value="true" control={<Radio />} label="Permanent ID" />
                <FormControlLabel value="false" control={<Radio />} label="Passport" />
              </RadioGroup>
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="idNumber"
            control={control}
            rules={{
              required: true,
              validate: (value: string) => {
                if (isIDNumber) {
                  return validateAfricanId(
                    value,
                    genderID === ClientGender.male ? 'male' : 'female',
                    birthDate,
                  );
                }
                return validatePassportId(value);
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                label="Identity number *"
                placeholder="e.g. 123456789"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            name={`phones.${mobilePhoneIndex}.number` as any}
            control={control}
            rules={{
              required: true,
              maxLength: 10,
              minLength: 10,
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                label="Mobile number *"
                placeholder="e.g. 0831234567"
                type="number"
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const ShareTrusty = ({ client }: StepContentProps) => {
  const { openNewTab } = useBrowserNavigate();
  const doc = useQueryVerifyDoc();
  const { mutate } = useMutationShare();
  const profile = useMyProfile();
  const [isShareOpen, setIsShareOpen] = useState(false);

  const isVerificationCompleted = client.verifyStatusID === ClientVerification.verified;

  const handleClickPreview = () => {
    if (doc && profile) {
      mutate(
        {
          docId: doc._id,
          docTitle: doc.metaProperties.title,
          docImage: doc.metaImage,
          shareType: MoloShareType.preview,
          agentId: profile.id,
          state: MoloShareState.authorized,
          clientId: client.id,
          processId: client.processID,
          agentName: profile.name,
        },
        {
          onSuccess: (response) => {
            openNewTab(`/s/${response.item.shortuid}`);
          },
        },
      );
    }
  };

  const handleClickShare = () => {
    setIsShareOpen(true);
  };

  const handleCloseShare = () => {
    setIsShareOpen(false);
  };

  return (
    <Box>
      <Card elevation={6} sx={{ mt: 4, border: 1, borderColor: 'common.borderGray' }}>
        <CardContent sx={{ display: 'flex', p: 4 }}>
          <Box width={84}>
            <img width={84} src={doc?.metaImage} />
          </Box>
          <Box ml={4} flexGrow={1}>
            <Typography variant="h5" fontWeight={600} color="primary.main">
              {doc?.metaProperties.title}
            </Typography>
            <Typography variant="subtitle2" color="text.darkGrey">
              {doc?.metaProperties.description}
            </Typography>
          </Box>
          <Box ml={4} width={80}>
            <Button
              disabled={isVerificationCompleted}
              onClick={handleClickShare}
              fullWidth
              variant="contained"
              color="error"
            >
              Share
            </Button>
            <Button
              onClick={handleClickPreview}
              fullWidth
              variant="outlined"
              color="gray"
              sx={{ mt: 2 }}
            >
              Preview
            </Button>
          </Box>
        </CardContent>
      </Card>
      {profile && client && (
        <ShareDialog
          agentId={profile.id}
          doc={isShareOpen ? doc : undefined}
          onClose={handleCloseShare}
          clientId={client.id}
          processId={client.processID}
          overrideShareType={MoloShareType.verification}
          messageType="verification"
        />
      )}
    </Box>
  );
};

const VerifyIdForm = (props: StepContentProps & { onSubmit: () => void }) => {
  const { addCloseCallback, removeCloseCallback } = useStepDialogApi();
  const [activeStep, setActiveStep] = useState(
    props.client.verifyShareStatusID === ClientVerificationShareStatus.shared
      ? 2
      : props.client.verifyShareStatusID === ClientVerificationShareStatus.opened
      ? 3
      : 0,
  );

  useEffect(() => {
    addCloseCallback('verifyIdForm', async () => {
      if (activeStep !== 0) {
        props.onSubmit();
      }
      return false;
    });
    return () => {
      removeCloseCallback('verifyIdForm');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  return (
    <Card sx={{ backgroundColor: '#FBFCFD', border: 1, borderColor: '#C7C8CA45' }} elevation={0}>
      <CardContent sx={{ p: { xs: 1, sm: 8 } }}>
        <Stepper
          activeStep={activeStep}
          sx={{
            mx: { sm: 12 },
            '& .MuiSvgIcon-root.Mui-completed': {
              color: 'success.main',
            },
          }}
        >
          <Step>
            <StepLabel>Check details</StepLabel>
          </Step>
          <Step>
            <StepLabel>Share Trusty with client</StepLabel>
          </Step>
          <Step>
            <StepLabel>{props.client.verifyShareStatusNote}</StepLabel>
          </Step>
        </Stepper>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            my: 4,
          }}
        >
          <Typography variant="body2" color="text.secondary">
            eVerify status:
          </Typography>
          <Typography sx={{ ml: 2 }} variant="body2" color="text.darkGrey">
            {props.client.verifyStatusID === ClientVerification.unverified
              ? 'Unverified - Please contact client'
              : props.client.verifyStatusNote}
          </Typography>
        </Box>
        {activeStep === 0 && <IdForm {...props} onSubmit={() => setActiveStep(1)} />}
        {activeStep > 0 && <ShareTrusty {...props} />}
      </CardContent>
    </Card>
  );
};

export default VerifyIdForm;
