import { Button } from '@mui/material';
import { MoloDoc } from '../../common/types/molo/moloDoc';
import { MoloShare } from '../../common/types/molo/moloShare';
import { useAppConfig } from '../../common/contexts/configContext';

interface DownloadPdfButtonProps {
  doc: MoloDoc;
  share: MoloShare;
}

const DownloadPdfButton = ({ doc, share }: DownloadPdfButtonProps) => {
  const { config } = useAppConfig();
  const docUrl = `${config.pdfUrl}ops-letter/${share.item.token}?env=${config.environment}`;
  const pdfUrl = `${config.pdfUrl}api/pdf?url=${docUrl}&margin=0&format=a4`;

  return (
    <Button component="a" href={pdfUrl} target="_top" sx={{ mt: 4 }} variant="contained">
      {doc.downloadButtonText}
    </Button>
  );
};

export default DownloadPdfButton;
