import { Client, ClientFull, ClientNote, NoteType } from '../common/types/client';
import { Decision } from '../common/types/decision';
import {
  PROCESS_AGENT_CONTACT_ID,
  PROCESS_ALLOCATE_ID,
  PROCESS_CONTACT_ID,
  PROCESS_VERIFY_ID,
} from '../common/types/clientProcess';
import { STATE_BUSY_ID, STATE_DONE_ID } from '../common/types/clientState';
import { useAppConfig } from '../common/contexts/configContext';
import { useMutation } from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';

const clientFieldsToCompare: Array<string | [string, string]> = [
  ['addresses', 'city'],
  ['addresses', 'code'],
  ['addresses', 'country'],
  ['addresses', 'province'],
  ['addresses', 'street'],
  'dateofbirth',
  ['emails', 'address'],
  'firstname',
  'genderNote',
  'idNumber',
  'initials',
  'isIDNumber',
  'maritalStatus',
  ['phones', 'number'],
  'surname',
  'title',
  'adviserName',
  'previousAdviserName',
  'adviserPhone',
  'previousAdviserPhone',
  'adviserEmail',
  'previousAdviserEmail',
];

/**
 * Get the difference between the previous and current client data
 */
const getClientDataDiff = (
  previousData: ClientFull | undefined,
  data: ClientFull,
): Array<[string, unknown, unknown]> => {
  if (!previousData) return [];

  return clientFieldsToCompare
    .map((key) => {
      if (Array.isArray(key)) {
        const nextValue = data[key[0]].map((item) => item[key[1]]);
        const previousValue = previousData[key[0]].map((item) => item[key[1]]);
        return isEqual(nextValue, previousValue)
          ? false
          : [`${key[0]}:${key[1]}`, previousValue, nextValue];
      }

      if (typeof key === 'string') {
        return isEqual(data[key], previousData[key]) ? false : [key, previousData[key], data[key]];
      }

      return false;
    })
    .filter(Boolean) as Array<[string, unknown, unknown]>;
};

const stringifyValue = (value: unknown) => {
  if (typeof value === 'object') return JSON.stringify(value);
  return String(value);
};

export const useUpdateClientMutation = () => {
  const appConfig = useAppConfig();
  return useMutation({
    mutationFn: async (payload: ClientFull & { agentName: string }) => {
      const previousData = appConfig.queryClient.getQueryData<ClientFull | undefined>([
        `/Ops/ClientDetail/${payload.id}`,
      ]);

      const { data } = await appConfig.privateOpsApi.put<ClientFull>(`/Ops/ClientDetail`, payload);
      const dataDiff = getClientDataDiff(previousData, data);
      // create a note after client update
      if (dataDiff.length > 0) {
        const comment = `${dataDiff
          .map(
            ([key, previousValue, nextValue]) =>
              `${key}: ${stringifyValue(nextValue)} (was ${stringifyValue(previousValue)})`,
          )
          .join('<br />')}`;

        await appConfig.privateOpsApi.post(`/Ops/NoteAdd`, {
          Comment: comment,
          ClientID: data.id,
          TypeId: NoteType.system,
          Action: `${payload.agentName} updated client info`,
          AssetUID: '',
          ProcessID: 0,
          ShareTypeID: 0,
        });
      }

      // Create a note after client verification status change
      if (previousData?.verifyStatusID !== data.verifyStatusID) {
        await appConfig.privateOpsApi.post(`/Ops/NoteAdd`, {
          Comment: `Verification status: ${data.verifyStatusNote} (was: ${previousData?.verifyStatusNote})`,
          ClientID: data.id,
          TypeId: NoteType.verifyclient,
          Action: `${payload.agentName} changed client verification status`,
          AssetUID: '',
          ProcessID: PROCESS_VERIFY_ID,
          ShareTypeID: 0,
        });
      }

      return data;
    },
    onSuccess: (response) => {
      appConfig.queryClient.invalidateQueries({
        queryKey: [`/Ops/ClientDetail/${response.id}`],
      });
      appConfig.queryClient.invalidateQueries({
        queryKey: ['/Ops/ClientLists'],
      });
    },
  });
};

export const useUpdateClientProcessMutation = () => {
  const appConfig = useAppConfig();
  return useMutation({
    mutationFn: async (data: {
      ID: number;
      stateID: number;
      processID: number;
      processNote: string;
      agentName: string;
    }) => {
      const result = await appConfig.privateOpsApi.put(
        '/Ops/ClientListProcess',
        {},
        { params: data },
      );

      if (data.stateID === STATE_DONE_ID) {
        await appConfig.privateOpsApi.post(`/Ops/NoteAdd`, {
          Comment: '',
          ClientID: data.ID,
          TypeId: NoteType.process,
          Action: `${data.agentName} completed "${data.processNote}" step`,
          AssetUID: '',
          ProcessID: data.processID,
          ShareTypeID: 0,
        });
      }

      if (data.stateID === STATE_BUSY_ID) {
        await appConfig.privateOpsApi.post(`/Ops/NoteAdd`, {
          Comment: '',
          ClientID: data.ID,
          TypeId: NoteType.process,
          Action: `${data.agentName} started "${data.processNote}" step`,
          AssetUID: '',
          ProcessID: data.processID,
          ShareTypeID: 0,
        });
      }

      return result;
    },
    onSuccess: (_, variables) => {
      appConfig.queryClient.invalidateQueries({
        queryKey: [`/Ops/ClientDetail/${variables.ID}`],
      });
      appConfig.queryClient.invalidateQueries({
        queryKey: ['/Ops/ClientLists'],
      });
    },
  });
};

export const useCreateClientNoteMutation = () => {
  const appConfig = useAppConfig();
  return useMutation({
    mutationFn: (payload: unknown) => {
      return appConfig.privateOpsApi
        .post<ClientNote>('/Ops/NoteAdd', payload)
        .then(({ data }) => data);
    },
    onSuccess: (response) => {
      appConfig.queryClient.invalidateQueries({
        queryKey: [`/Ops/ClientDetail/${response.clientID}`],
      });
    },
  });
};

export const useUpdateDecisionMutation = () => {
  const appConfig = useAppConfig();
  return useMutation({
    mutationFn: async (payload: {
      ClientID: number;
      ProcessID: number;
      Text?: string;
      agentName: string;
    }) => {
      const { data } = await appConfig.privateOpsApi.post<Decision>(
        `/Ops/ClientDetail/Decision`,
        {},
        { params: payload },
      );

      // Create a note after decision change
      await appConfig.privateOpsApi.post(`/Ops/NoteAdd`, {
        Comment: '',
        ClientID: payload.ClientID,
        TypeId: NoteType.decision,
        Action: `${payload.agentName} - Client decided "${payload.Text}"`,
        AssetUID: '',
        ProcessID: PROCESS_CONTACT_ID,
        ShareTypeID: 0,
      });

      return data;
    },
    onSuccess: () => {
      appConfig.queryClient.invalidateQueries({
        queryKey: ['/Ops/Decisions'],
      });
    },
  });
};

export const useCloseClientMutation = () => {
  const appConfig = useAppConfig();
  return useMutation({
    mutationFn: async (payload: { ID: number; agentName: string }) => {
      await appConfig.privateOpsApi.put(`/Ops/ClientClosed`, undefined, {
        params: {
          ID: payload.ID,
          IsClosed: true,
        },
      });

      await appConfig.privateOpsApi.post(`/Ops/NoteAdd`, {
        Comment: '',
        ClientID: payload.ID,
        TypeId: NoteType.system,
        Action: `${payload.agentName} - closed the client`,
        AssetUID: '',
        ProcessID: PROCESS_ALLOCATE_ID,
        ShareTypeID: 0,
      });
    },
    onSuccess: (_, variables) => {
      appConfig.queryClient.invalidateQueries({
        queryKey: ['/Ops/ClientLists'],
      });
      appConfig.queryClient.invalidateQueries({
        queryKey: [`/Ops/ClientDetail/${variables.ID}`],
      });
    },
  });
};

export const useAllocateClientMutation = () => {
  const appConfig = useAppConfig();
  return useMutation({
    mutationFn: async (payload: {
      ID: number;
      AllocatedAgentID: number;
      AllocatedAgentFullname: string;
      agentName: string;
    }) => {
      const isClosingClient = payload.AllocatedAgentFullname === 'Virtual Adviser';
      const { data } = await appConfig.privateOpsApi.put<Client>(
        `/Ops/ClientListAllocated`,
        {},
        { params: payload },
      );

      // Create a note after client allocation
      await appConfig.privateOpsApi.post(`/Ops/NoteAdd`, {
        Comment: '',
        ClientID: payload.ID,
        TypeId: NoteType.allocation,
        Action: `${payload.agentName} - Client was allocated to ${payload.AllocatedAgentFullname}`,
        AssetUID: '',
        ProcessID: PROCESS_ALLOCATE_ID,
        ShareTypeID: 0,
      });

      await appConfig.privateOpsApi.post(`/Ops/NoteAdd`, {
        Comment: '',
        ClientID: payload.ID,
        TypeId: NoteType.process,
        Action: `${payload.agentName} completed "Allocate" step`,
        AssetUID: '',
        ProcessID: PROCESS_ALLOCATE_ID,
        ShareTypeID: 0,
      });

      // specific logic when client is allocated to Virtual Adviser
      if (!isClosingClient) {
        await appConfig.privateOpsApi.post(`/Ops/NoteAdd`, {
          Comment: '',
          ClientID: payload.ID,
          TypeId: NoteType.process,
          Action: `${payload.agentName} started "Contact" step`,
          AssetUID: '',
          ProcessID: PROCESS_AGENT_CONTACT_ID,
          ShareTypeID: 0,
        });
      }

      return data;
    },
    onSuccess: (_, variables) => {
      appConfig.queryClient.invalidateQueries({
        queryKey: [`/Ops/ClientDetail/${variables.ID}`],
      });
      appConfig.queryClient.invalidateQueries({
        queryKey: ['/Ops/ClientLists'],
      });
    },
  });
};
