import { MoloAgentExt } from '../../types/molo/moloAgentExt';
import { MoloShare } from '../../types/molo/moloShare';
import { ReactNode } from 'react';
import LayoutFooter from './layoutFooter';
import LayoutHeader from './layoutHeader';

interface LayoutProps {
  children: ReactNode;
  agent?: MoloAgentExt;
  share?: MoloShare;
}

const Layout = ({ children, agent, share }: LayoutProps) => (
  <>
    <LayoutHeader agent={agent} share={share} />
    {children}
    <LayoutFooter />
  </>
);

export default Layout;
