import { Agent } from '../../common/types/agent';
import { Box, Radio, TextField } from '@mui/material';
import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { useDebounce } from 'usehooks-ts';
import { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import TableLoadingState from '../../common/components/tableLoadingState';

interface AgentListProps {
  type: 'advisers' | 'agents';
  selectedAgentId?: number;
  onSelect: (agent: Agent) => void;
}

const AgentList = ({ type, selectedAgentId, onSelect }: AgentListProps) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debounceSearchQuery = useDebounce(searchQuery, 500);
  const { data: agents } = useQuery<Agent[]>({
    queryKey: [type === 'advisers' ? '/OpsAdvisers' : '/OpsAgents'],
  });

  const agentsToRender = useMemo(() => {
    if (!agents) return [];

    return agents.filter((agent) => {
      if (!debounceSearchQuery) return true;

      return [agent.fullname.toLowerCase(), agent.email.toLowerCase(), agent.phone]
        .join(' ')
        .includes(debounceSearchQuery.toLowerCase());
    });
  }, [agents, debounceSearchQuery]);

  const columns: GridColumns<Agent> = [
    {
      field: 'actions',
      type: 'actions',
      minWidth: 150,
      width: 32,
      getActions: ({ row }) => [
        <Radio
          key="radio"
          checked={row.id === selectedAgentId}
          onChange={(_, selected: boolean) => selected && onSelect(row)}
        />,
      ],
    },
    {
      field: 'fullname',
      headerName: type === 'advisers' ? 'Adviser' : 'Agent',
      minWidth: 150,
      flex: 2,
    },
    { field: 'email', headerName: 'Email address', minWidth: 150, flex: 1 },
    { field: 'phone', headerName: 'Cellphone number', minWidth: 150, flex: 1 },
    { field: 'agentCode', headerName: 'Broker code', minWidth: 150, flex: 1 },
    { field: 'region', headerName: 'Province', minWidth: 150, flex: 1 },
  ];

  if (!agents) {
    return <TableLoadingState />;
  }

  return (
    <Box>
      <TextField
        sx={{ marginBottom: 2 }}
        onChange={(event) => setSearchQuery(event.target.value)}
        placeholder="Search..."
      />
      <DataGrid
        autoHeight
        disableSelectionOnClick
        rows={agentsToRender}
        columns={columns}
        hideFooter
        disableColumnMenu
        rowHeight={70}
        headerHeight={40}
      />
    </Box>
  );
};

export default AgentList;
