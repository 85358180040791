import { useIsAdviser } from '../common/hooks/auth';
import AdvisersPage from '../advisers/advisersPage';
import OpsPage from '../ops/opsPage';

/**
 * Render Ops or Adviser dashboard depends on current agent type
 */
const DashboardPage = () => {
  const isAdviser = useIsAdviser();
  return isAdviser ? <AdvisersPage /> : <OpsPage />;
};

export default DashboardPage;
