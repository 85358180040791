import {
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
} from '@mui/material';
import { DAY_MS, formatDate } from '../../common/lib/date';
import { Icon } from '@iconify/react';
import { MoloDoc } from '../../common/types/molo/moloDoc';
import { MoloShareState, MoloShareType } from '../../common/types/molo/moloShare';
import { useAgentData, useMyProfile } from '../../common/hooks/auth';
import { useAppConfig } from '../../common/contexts/configContext';
import { useBrowserNavigate } from '../../common/hooks/useBrowserNavigate';
import { useMutationShare } from '../../common/hooks/useMutationShare';
import { useQueryMoloDocs } from '../../common/hooks/useQueryMoloDocs';
import { useState } from 'react';
import castArray from 'lodash/castArray';
import ShareDialog from '../../docs/components/shareDialog';

interface AgentDocsProps {
  keyword?: string;
  clientId: number;
  processId: number;
}

const AgentDocs = ({ keyword, clientId, processId }: AgentDocsProps) => {
  const { config } = useAppConfig();
  const agentData = useAgentData();
  const profile = useMyProfile();
  const { mutate } = useMutationShare();
  const { openNewTab } = useBrowserNavigate();
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [activeDoc, setActiveDoc] = useState<{ anchorEl: HTMLElement | null; doc: MoloDoc } | null>(
    null,
  );
  const documentIds = [
    ...castArray(agentData?.item.DashboardContent?.item).map(({ contentuid }) => contentuid),
    ...castArray(agentData?.item.LibraryContent?.item).map(({ contentuid }) => contentuid),
  ];
  const { data: docs } = useQueryMoloDocs(documentIds, keyword);

  const handleClickPreview = () => {
    if (activeDoc && profile) {
      mutate(
        {
          docId: activeDoc.doc._id,
          docContentType: activeDoc.doc.type?.code,
          docTitle: activeDoc.doc.metaProperties.title,
          docImage: activeDoc.doc.metaImage,
          shareType: MoloShareType.preview,
          agentId: profile.id,
          state: MoloShareState.authorized,
          clientId,
          processId,
          agentName: profile.name,
        },
        {
          onSuccess: (response) => {
            openNewTab(`/s/${response.item.shortuid}`);
          },
        },
      );
    }
    setActiveDoc(null);
  };
  const handleClickShare = () => {
    setIsShareOpen(true);
    setActiveDoc((value) =>
      value
        ? {
            ...value,
            anchorEl: null,
          }
        : null,
    );
  };

  const handleClickDownloadPdf = () => {
    if (!activeDoc || !profile) {
      return;
    }

    mutate(
      {
        docId: activeDoc.doc._id,
        docContentType: activeDoc.doc.type?.code,
        docTitle: activeDoc.doc.metaProperties.title,
        docImage: activeDoc.doc.metaImage,
        shareType: MoloShareType.other,
        agentId: profile.id,
        state: MoloShareState.authorized,
        clientId,
        processId,
        agentName: profile.name,
      },
      {
        onSuccess: (response) => {
          const docUrl = `${config.pdfUrl}ops-letter/${response.item.token}?env=${config.environment}`;
          const pdfUrl = `${config.pdfUrl}api/pdf?url=${docUrl}&margin=0&format=a4`;
          openNewTab(pdfUrl);
        },
      },
    );

    setActiveDoc((value) =>
      value
        ? {
            ...value,
            anchorEl: null,
          }
        : null,
    );
  };

  const handleCloseShare = () => {
    setActiveDoc(null);
    setIsShareOpen(false);
  };

  if (!agentData || !docs || !profile) {
    return <Skeleton variant="rectangular" height={160} />;
  }

  return (
    <Box>
      {docs.result.map((doc) => {
        const updatedAt = new Date(doc._updatedAt);
        return (
          <Card key={doc._id} elevation={6} sx={{ mb: 4 }}>
            <CardContent>
              <Grid
                container
                sx={{ flexWrap: { xs: 'nowrap', md: 'wrap' } }}
                spacing={{ xs: 2, md: 3 }}
                justifyContent="center"
                columns={{ xs: 4, sm: 8, md: 12 }}
              >
                <Grid item xs={6} sx={{ display: 'flex' }}>
                  <Box
                    component="img"
                    sx={{
                      height: 112,
                      width: 112,
                      mr: 3,
                      objectFit: 'cover',
                    }}
                    alt=""
                    src={doc.metaImage}
                  />
                  <Box>
                    <Typography variant="h5" fontWeight={700}>
                      {doc.metaProperties.title}
                    </Typography>
                    <Typography variant="subtitle2" color="text.secondary" sx={{ mt: 1 }}>
                      By {doc.author?.name} - {formatDate(updatedAt)}
                    </Typography>
                    <Box sx={{ mt: 1 }}>
                      {Date.now() - updatedAt.getTime() < 3 * DAY_MS && (
                        <Chip color="info" label="new" sx={{ mr: 2 }} />
                      )}
                      {doc.type && <Chip label={doc.type.name} />}
                    </Box>
                    <Typography
                      variant="subtitle2"
                      color="text.secondary"
                      sx={{ flexGrow: 1, display: { xs: 'block', md: 'none' } }}
                    >
                      {doc.metaProperties.description}
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    flexBasis: { xs: '20%', md: '50%' },
                  }}
                >
                  <Divider
                    orientation="vertical"
                    sx={{ minHeight: 128, mr: 3, display: { xs: 'none', md: 'block' } }}
                  />
                  <Typography
                    variant="subtitle2"
                    color="text.secondary"
                    sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' } }}
                  >
                    {doc.metaProperties.description}
                  </Typography>
                  <Box>
                    <IconButton
                      size="small"
                      onClick={({ currentTarget }) =>
                        setActiveDoc({ anchorEl: currentTarget, doc })
                      }
                    >
                      <Icon width={24} icon="material-symbols:more-vert" />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        );
      })}
      <Menu
        anchorEl={activeDoc?.anchorEl}
        open={Boolean(activeDoc?.anchorEl)}
        onClose={() => setActiveDoc(null)}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {activeDoc?.doc.downloadingEnabled ? (
          <MenuItem onClick={handleClickDownloadPdf}>Download PDF</MenuItem>
        ) : (
          <>
            <MenuItem onClick={handleClickPreview}>Preview</MenuItem>
            <MenuItem onClick={handleClickShare}>Share</MenuItem>
          </>
        )}
      </Menu>
      <ShareDialog
        agentId={profile.id}
        doc={isShareOpen ? activeDoc?.doc : undefined}
        onClose={handleCloseShare}
        clientId={clientId}
        processId={processId}
        messageType="doc"
      />
    </Box>
  );
};

export default AgentDocs;
