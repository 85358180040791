import { ClientFull } from '../../types/client';
import { Dialog, Skeleton } from '@mui/material';
import { OptionModel } from '../../types/optionModel';
import { ReactNode } from 'react';
import { useQuery } from '@tanstack/react-query';
import Content from '../context';
import StepDialogHeader, { StepDialogHeaderProps } from './stepDialogHeader';

export type CloseReason = 'close' | 'finish' | 'next';

export interface StepContentProps {
  client: ClientFull;
  process: OptionModel;
  /**
   * Callback when the form loading state changes
   * @param isLoading Indicates if the form is in loading state
   */
  onFormLoadingStateChange: (isLoading: boolean) => void;
}

interface StepDialogProps
  extends Pick<StepDialogHeaderProps, 'process' | 'onClose' | 'defaultOpenDialog'> {
  children: (client: ClientFull) => ReactNode;
  clientId: number;
  /**
   * Indicates if the dialog is in loading state
   */
  isLoading: boolean;
  /**
   * Optional label for the submit button
   */
  submitButtonLabel?: string;
}

const StepDialog = ({
  defaultOpenDialog,
  clientId,
  process,
  onClose,
  children,
  isLoading,
  submitButtonLabel,
}: StepDialogProps) => {
  const { data: client } = useQuery<ClientFull>({ queryKey: [`/Ops/ClientDetail/${clientId}`] });

  return (
    <Dialog fullScreen open onClose={onClose}>
      {client ? (
        <>
          <StepDialogHeader
            client={client}
            process={process}
            onClose={onClose}
            isLoading={isLoading}
            defaultOpenDialog={defaultOpenDialog}
            submitButtonLabel={submitButtonLabel}
          />
          <Content sx={{ flexGrow: 1 }}>{children(client)}</Content>
        </>
      ) : (
        <>
          <Skeleton variant="rectangular" />
          <Skeleton variant="rectangular" />
        </>
      )}
    </Dialog>
  );
};

export default StepDialog;
