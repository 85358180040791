import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import { DocLabelsToReplace, replaceLabels } from './docHtmlContent';
import { MoloAgentExt } from '../../common/types/molo/moloAgentExt';
import { MoloDoc } from '../../common/types/molo/moloDoc';
import { MoloShare } from '../../common/types/molo/moloShare';
import { useState } from 'react';
import ConnectDialog from '../../common/components/dialog/connectDialog';

interface DocHeaderProps {
  doc: MoloDoc;
  share?: MoloShare;
  agent: MoloAgentExt;
  labelsToReplace: DocLabelsToReplace;
  onConfirm: () => void;
}

/**
 * Confirmation block for sharing documents with checkbox and confirmation dialog
 */
const DocConfirmation = ({ agent, share, doc, labelsToReplace, onConfirm }: DocHeaderProps) => {
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [showConfirmedDialog, setShowConfirmedDialog] = useState(false);
  const [isConnectOpen, setIsConnectOpen] = useState(false);

  const handleConfirm = () => {
    setShowConfirmedDialog(true);
    setIsConfirmed(true);
    onConfirm();
  };

  return (
    <Card elevation={0} sx={{ bgcolor: 'info.main', p: 4 }}>
      <Box display="flex" alignItems="center">
        <Checkbox
          size="medium"
          disabled={isConfirmed}
          sx={{
            mr: 2,
            color: 'white.main',
            '&.Mui-disabled': {
              color: 'primary.main',
            },
          }}
          onChange={(event) => event.target.checked && handleConfirm()}
        />
        <Typography color="text.darkGray" variant="body2">
          {replaceLabels(doc.confirmationMessage || '', labelsToReplace)}
        </Typography>
      </Box>

      <Dialog open={showConfirmedDialog} onClose={() => setShowConfirmedDialog(false)}>
        <DialogTitle>Thank you!</DialogTitle>
        <DialogContent>
          Thank you for confirming you have read this letter. If you have any questions or comments,
          you can contact me using the ‘Get in touch’ button. If not, just close this window now and
          carry on with your day :)
        </DialogContent>
        <DialogActions>
          {share && (
            <Button variant="contained" color="secondary" onClick={() => setIsConnectOpen(true)}>
              Get in touch
            </Button>
          )}
          <Button variant="contained" onClick={() => setShowConfirmedDialog(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {share && (
        <ConnectDialog
          share={share}
          agent={agent}
          open={isConnectOpen}
          onClose={() => setIsConnectOpen(false)}
        />
      )}
    </Card>
  );
};

export default DocConfirmation;
