import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  Typography,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import { useMutationLogout, useMyProfile, useQueryMe } from '../../hooks/auth';
import { useNavigate } from 'react-router-dom';

interface SlideMenuProps {
  open: boolean;
  onClose: () => void;
}

const SlideMenu = ({ open, onClose }: SlideMenuProps) => {
  const { data: userData } = useQueryMe();
  const navigate = useNavigate();
  const currentProfile = useMyProfile();
  const [type, setType] = useState('');
  const [isProfilesVisible, setIsProfilesVisible] = useState(true);
  const logoutMutation = useMutationLogout();

  useEffect(() => {
    const onKeyPress = (event: KeyboardEvent) => {
      if (open) {
        setType((previous) => previous + event.key);
      }
    };
    document.addEventListener('keypress', onKeyPress);
    return () => document.removeEventListener('keypress', onKeyPress);
  }, [open]);

  // To open the reports page user should type 'reports' in the slide menu
  useEffect(() => {
    if (type === 'reports') {
      navigate('/reports');
    }
  }, [type, navigate]);

  useEffect(() => {
    if (!open) {
      setType('');
    }
  }, [open]);

  if (!currentProfile) return null;

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box bgcolor="common.white" sx={{ width: 480, p: 5 }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={onClose} size="small">
            <Icon width={24} icon="material-symbols:close" />
          </IconButton>
        </Box>
        <Box
          sx={{
            mt: 3,
            p: 4,
            border: '1px solid #ECECEC',
            bgcolor: 'background.default',
            display: 'flex',
          }}
        >
          {currentProfile.mediaurl ? (
            <img src={currentProfile.mediaurl} width={80} alt="" />
          ) : (
            <Avatar alt={currentProfile.name} sx={{ width: 51, height: 51 }} />
          )}
          <Box sx={{ ml: 2 }}>
            <Typography variant="h4">{currentProfile.name}</Typography>
            <Typography sx={{ mt: 1 }} variant="subtitle2" color="text.secondary">
              {currentProfile.type}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ ml: 2, mt: 5, borderLeft: 2, borderColor: 'secondary.main', pl: 1.5 }}>
          <Typography variant="body2" fontWeight={600} color="secondary">
            Dashboard
          </Typography>
        </Box>

        <Divider sx={{ my: 5 }} orientation="horizontal" />
        {userData?.authData?.Profiles.item.length && (
          <>
            <Box
              sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 4 }}
            >
              <Typography variant="body1" fontWeight={600}>
                Your other mConnect profiles
              </Typography>
              <Button
                color="gray"
                size="small"
                variant="text"
                onClick={() => setIsProfilesVisible((value) => !value)}
              >
                {isProfilesVisible ? 'Hide' : 'Show'}
              </Button>
            </Box>
            <Collapse in={isProfilesVisible}>
              {userData.authData.Profiles.item.map((profile) =>
                profile.id === currentProfile.id ? null : (
                  <ButtonBase
                    key={profile.id}
                    sx={{
                      width: '100%',
                      border: 1,
                      borderColor: '#E4E4E4',
                      mb: 2,
                      p: 2,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                      color: 'inherit',
                      textDecoration: 'none',
                    }}
                    onClick={() => {
                      if (top) {
                        top.window.location.href = `https://${profile.domain}/agentauth/${profile.username}?token=${profile.token}&parentToken=${userData.authData?.Profiles.token}&domain=${profile.domain}`;
                      }
                    }}
                  >
                    <img src={profile.mediaurl} width={55} alt="" />
                    <Box sx={{ ml: 2 }}>
                      <Typography textAlign="left" variant="subtitle2" fontWeight={600}>
                        {profile.name}
                      </Typography>
                      <Typography variant="subtitle2" color="text.secondary">
                        ({profile.type} | {profile.islinkedowner === 'no' ? 'Member' : 'Owner'})
                      </Typography>
                    </Box>
                  </ButtonBase>
                ),
              )}
            </Collapse>
          </>
        )}
        <Button variant="text" color="inherit">
          <Typography variant="body2" fontWeight={600} onClick={() => logoutMutation.mutate()}>
            Log out
          </Typography>
        </Button>
      </Box>
    </Drawer>
  );
};

export default SlideMenu;
