import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { ClientFull, ClientVerification } from '../../../../common/types/client';
import { Controller, useForm } from 'react-hook-form';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SAVED_MESSAGE,
} from '../../../../common/contexts/configContext';
import { OptionModel } from '../../../../common/types/optionModel';
import { StepContentProps } from '../../../../common/components/stepDialog';
import {
  useCloseClientMutation,
  useUpdateClientMutation,
  useUpdateClientProcessMutation,
} from '../../../mutations';
import { useEffect } from 'react';
import { useMyProfile } from '../../../../common/hooks/auth';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useStepDialogApi } from '../../../../common/contexts/stepDialogContext';

// List of statuses that should close the client
const closeClientStatuses = new Set([
  ClientVerification.deceased,
  ClientVerification.doesNotExist,
  ClientVerification.unableToContact,
]);

const ConfirmVerifyStatus = ({ client, onFormLoadingStateChange }: StepContentProps) => {
  const { addCloseCallback, removeCloseCallback } = useStepDialogApi();
  const closeClientMutation = useCloseClientMutation();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useUpdateClientMutation();
  const clientProcessMutation = useUpdateClientProcessMutation();
  const profile = useMyProfile();

  const { data: statuses } = useQuery<OptionModel[]>({
    queryKey: ['/Ops/ClientVerifyStatuss'],
  });

  useEffect(
    () =>
      onFormLoadingStateChange(
        mutation.isLoading || closeClientMutation.isLoading || clientProcessMutation.isLoading,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutation.isLoading, closeClientMutation.isLoading, clientProcessMutation.isLoading],
  );

  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      ...client,
    },
  });

  const makeSubmit = async (data: ClientFull) => {
    if (data.verifyStatusID === ClientVerification.notstarted) {
      enqueueSnackbar('Please select an option.', { variant: 'error' });
      return false;
    }

    try {
      await mutation.mutateAsync({ ...data, agentName: profile?.name ?? '' });
      enqueueSnackbar(DEFAULT_SAVED_MESSAGE, { variant: 'success' });
      // Close client if not appropriate status was selected
      if (data.verifyStatusID && closeClientStatuses.has(Number(data.verifyStatusID))) {
        await closeClientMutation.mutateAsync({
          ID: client.id,
          agentName: profile?.name ?? '',
        });
      }
    } catch (error) {
      enqueueSnackbar((error as Error).message || DEFAULT_ERROR_MESSAGE, { variant: 'error' });
    }
  };

  useEffect(() => {
    addCloseCallback('confirmStatus', async () => {
      // Also check here because we have to return false if form is invalid
      if (watch('verifyStatusID') === ClientVerification.notstarted) {
        enqueueSnackbar('Please select an option.', { variant: 'error' });
        return false;
      }

      await handleSubmit(makeSubmit)();
      return true;
    });
    return () => {
      removeCloseCallback('confirmStatus');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  return (
    <Box component="form" onSubmit={handleSubmit(makeSubmit)} mb={4}>
      <Box sx={{ borderBottom: 1, py: 1 }}>
        <Typography variant="h5">Was the client...</Typography>
      </Box>
      <Controller
        name="verifyStatusID"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl error={!!fieldState.error} variant="standard">
            <RadioGroup {...field} onChange={(_, value) => field.onChange(value)}>
              {statuses?.map((status) => (
                <FormControlLabel
                  key={status.id}
                  value={status.id}
                  control={<Radio />}
                  disabled={status.id === ClientVerification.notstarted}
                  label={status.note}
                />
              ))}
            </RadioGroup>
            <FormHelperText>{fieldState.error?.message}</FormHelperText>
          </FormControl>
        )}
      />
    </Box>
  );
};

export default ConfirmVerifyStatus;
