import { ClientFull } from '../common/types/client';
import { MoloAgentExt } from '../common/types/molo/moloAgentExt';
import { MoloShare } from '../common/types/molo/moloShare';
import { Navigate, useParams } from 'react-router-dom';
import { useAppConfig } from '../common/contexts/configContext';
import { useQuery } from '@tanstack/react-query';
import DocContent from './components/docContent';
import PageLoader from '../common/components/pageLoader';

export const DeprecatedSharePage = () => {
  const parameters = useParams();
  const { data: share } = useQuery<MoloShare>({
    queryKey: [`/Molo/Handler.Share.ashx?token=${parameters.id}`],
  });

  if (share?.item.shortuid) {
    return <Navigate to={`/s/${share.item.shortuid}`} />;
  }

  return <PageLoader />;
};

const SharePage = () => {
  const appConfig = useAppConfig();
  const parameters = useParams();
  const { data: share } = useQuery<MoloShare['item']>({
    queryKey: [`/Molo/Handler.Share.Report.ashx?shareuid=${parameters.id}`],
  });

  const { data: agent } = useQuery<MoloAgentExt>({
    queryKey: ['/Molo/Handler.Agent.Ext.ashx', { username: share?.agentusername }],
    enabled: Boolean(share),
  });

  const { data: client } = useQuery<ClientFull>({
    queryKey: [`/Ops/ClientDetail/${share?.clientid}`],
    enabled: Boolean(share),
  });

  return (
    <DocContent
      agent={agent}
      client={client}
      share={share ? { item: share } : undefined}
      urlParameters={{
        sharetoken: share?.token,
        environment: appConfig?.config?.environment,
        process_id: share?.processid,
        client_id: share?.clientid,
        firstname: agent?.item.firstname,
        lastname: agent?.item.surname,
        email: agent?.item.email,
        telephone: agent?.item.cellphone,
      }}
    />
  );
};

export default SharePage;
