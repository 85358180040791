import { Box, Tab, Tabs } from '@mui/material';
import { dialogsTabStyles } from '../contactDialog';
import { StepContentProps } from '../../../../common/components/stepDialog';
import { useIsAdviser } from '../../../../common/hooks/auth';
import { useState } from 'react';
import AgentDocs from '../../../../agents/components/agentDocs';
import AgentLeads from '../../../../agents/components/agentLeads';
import Instructions from '../../../../common/components/instructions';

const MonitoringDialog = ({ client, process }: StepContentProps) => {
  const [currentTab, setCurrentTab] = useState(0);
  const isAdviser = useIsAdviser();

  return (
    <>
      <Tabs
        sx={dialogsTabStyles}
        value={currentTab}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        onChange={(_, value) => setCurrentTab(value)}
      >
        <Tab label="Guide" />
        <Tab label="docs" />
        <Tab label="responses" />
      </Tabs>
      <Box sx={{ mt: 6 }}>
        <Box sx={{ display: currentTab === 0 ? 'block' : 'none' }}>
          <Instructions keyword={isAdviser ? 'adviser-monitoring' : 'monitoring'} />
        </Box>
        <Box sx={{ display: currentTab === 1 ? 'block' : 'none' }}>
          <AgentDocs
            clientId={client.id}
            processId={process.id || client.processID}
            keyword={isAdviser ? 'adviser-monitoring' : 'monitoring'}
          />
        </Box>
        {/* For the monitoring leads we ask for leads for every process (step) */}
        {currentTab === 2 && <AgentLeads clientId={client.id} processId="1,2,3,4,5,6,7,8,9,10" />}
      </Box>
    </>
  );
};

export default MonitoringDialog;
