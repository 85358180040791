import { Avatar, Box, Button, Card, CardContent, Grid, Stack, Typography } from '@mui/material';
import { calculateAge } from '../../lib/date';
import { ClientFull } from '../../types/client';
import { ClientProcess, monitorFakeProcess } from '../../types/clientProcess';
import { CloseReason } from '.';
import { Icon } from '@iconify/react';
import { LoadingButton } from '@mui/lab';
import { useQueryProcesses } from '../../hooks/useQueryProcesses';
import { useState } from 'react';
import BaseDialogHeader, { DialogHeaderDivider } from '../dialog/baseDialogHeader';
import Content from '../context';
import InfoDialog from '../../../clients/components/infoDialog';
import NotesDialog from '../../../clients/components/notesDialog';

const buttonSx = {
  color: 'primary.main',
  borderColor: 'common.borderGray',
  fontWeight: 500,
  width: 135,
};

const formatClientName = (value: string) => {
  return value
    .split(' ')
    .map((el) => `${el.slice(0, 1).toUpperCase()}${el.slice(1).toLowerCase()}`)
    .join(' ');
};

export interface StepDialogHeaderProps {
  client: ClientFull;
  process: ClientProcess;
  onClose: (reason: CloseReason) => void;
  defaultOpenDialog: OpenDialogState;
  isLoading: boolean;
  submitButtonLabel?: string;
}

type OpenDialogState = 'history' | 'info' | null;

const StepDialogHeader = ({
  defaultOpenDialog = null,
  client,
  process,
  onClose,
  isLoading,
  submitButtonLabel,
}: StepDialogHeaderProps) => {
  const [openedDialog, setOpenedDialog] = useState<OpenDialogState>(defaultOpenDialog);
  const { data: clientProcesses, isLoading: clientProcessesLoading } = useQueryProcesses();
  const currentClientProcessIndex = clientProcesses
    ? clientProcesses.findIndex(({ id }) => id === client.processID)
    : -1;
  const selectedProcessIndex = clientProcesses
    ? clientProcesses.findIndex(({ id }) => id === process.id)
    : -1;
  const isSelectedProcessActive = client.processID === process.id;
  const canChangeProcessStatus =
    isSelectedProcessActive || selectedProcessIndex === currentClientProcessIndex + 1;

  const handleCloseSubDialog = () => setOpenedDialog(null);

  return (
    <>
      <BaseDialogHeader>
        <LoadingButton
          loading={isLoading || clientProcessesLoading}
          startIcon={<Icon icon="mdi:chevron-left" />}
          variant="outlined"
          color="white"
          data-reason="close"
          onClick={() => onClose('close')}
        >
          Back
        </LoadingButton>
        <DialogHeaderDivider />
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h3">{process.note}</Typography>
        </Box>
        {/* Hide status control for monitoring */}
        {process.id !== monitorFakeProcess.id && (
          <>
            <LoadingButton
              loading={isLoading || clientProcessesLoading}
              color={canChangeProcessStatus ? 'success' : 'white'}
              variant={canChangeProcessStatus ? 'contained' : 'outlined'}
              onClick={() => onClose(canChangeProcessStatus ? 'finish' : 'next')}
              sx={(theme) => ({
                '&.MuiLoadingButton-loading': {
                  backgroundColor: '#ffffff33',
                },
                '& .MuiLoadingButton-loadingIndicator': {
                  color: theme.palette.common.white,
                },
              })}
            >
              {submitButtonLabel || (canChangeProcessStatus ? 'Finish Step' : 'Completed')}
            </LoadingButton>
          </>
        )}
      </BaseDialogHeader>
      <Content sx={{ py: 4 }}>
        <Card
          sx={{ backgroundColor: '#FBFCFD', border: 1, borderColor: '#C7C8CA45' }}
          elevation={0}
        >
          <CardContent sx={{ p: { xs: 1, sm: 4 } }}>
            <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
              <Grid item xs={6} sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar alt={client.fullname} sx={{ width: 80, height: 80 }} />
                <Box sx={{ ml: 3 }}>
                  <Typography variant="h2" color="primary.main">
                    {formatClientName(client.fullname)}
                  </Typography>
                  <Typography sx={{ mt: 1 }} variant="body2" color="text.secondary">
                    Age: {client.dateofbirth ? calculateAge(client.dateofbirth) : '-'} |{' '}
                    {client.genderNote} | {client.maritalStatus}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Stack
                  spacing={1}
                  direction={{ xs: 'column', sm: 'row' }}
                  alignItems="center"
                  justifyContent="flex-end"
                  height="100%"
                  width={{ xs: 350, sm: '100%' }}
                >
                  <Button
                    fullWidth
                    sx={buttonSx}
                    variant="outlined"
                    onClick={() => setOpenedDialog('info')}
                  >
                    Client Info
                  </Button>
                  <Button
                    fullWidth
                    sx={buttonSx}
                    onClick={() => setOpenedDialog('history')}
                    variant="outlined"
                    color="primary"
                  >
                    <Box mr={1} display="flex" justifyContent="center">
                      <Icon icon="ic:baseline-history" width={24} />
                    </Box>
                    History
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <NotesDialog
          clientId={client.id}
          open={openedDialog === 'history'}
          onClose={handleCloseSubDialog}
        />
        <InfoDialog client={client} open={openedDialog === 'info'} onClose={handleCloseSubDialog} />
      </Content>
    </>
  );
};

export default StepDialogHeader;
