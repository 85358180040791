import {
  Box,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import { ClientFull, ClientGender } from '../../../../common/types/client';
import { Controller, FieldArray, FieldPath, useFieldArray, useForm } from 'react-hook-form';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SAVED_MESSAGE,
} from '../../../../common/contexts/configContext';
import { OptionModel } from '../../../../common/types/optionModel';
import { StepContentProps } from '../../../../common/components/stepDialog';
import { useEffect } from 'react';
import { useMyProfile } from '../../../../common/hooks/auth';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useStepDialogApi } from '../../../../common/contexts/stepDialogContext';
import { useUpdateClientMutation } from '../../../mutations';
import { validateAfricanId, validatePassportId } from '../../../../common/lib/validations';
import ArrayField from '../../arrayField';
import AsyncSelect from '../../../../common/components/asyncSelect';
import DateTextField from '../../../../common/components/dateTextField';

const VerifyForm = ({
  client,
  onFormLoadingStateChange,
  onSubmit,
}: StepContentProps & { onSubmit: () => void }) => {
  const { addCloseCallback, removeCloseCallback } = useStepDialogApi();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useUpdateClientMutation();
  const profile = useMyProfile();

  // Filter phone types that haven't yet been used for the client, and use them for new phones
  const { data: phoneTypes } = useQuery<OptionModel[]>({
    queryKey: ['/Ops/PhoneTypes'],
  });
  const clientUsedPhoneTypes = new Set(client.phones.map(({ typeID }) => typeID));
  const availablePhoneTypes = phoneTypes?.filter(({ id }) => !clientUsedPhoneTypes.has(id));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onFormLoadingStateChange(mutation.isLoading), [mutation.isLoading]);

  const { handleSubmit, control, reset, watch } = useForm({
    defaultValues: {
      ...client,
      emails:
        client.emails.length > 0 ? client.emails : ([{ address: '' }] as ClientFull['emails']),
    },
  });
  const isIDNumber = watch('isIDNumber');
  const birthDate = watch('dateofbirth');
  const genderID = watch('genderID');

  const { fields: addresses } = useFieldArray({
    control,
    name: 'addresses',
  });

  useEffect(
    () =>
      reset({
        ...client,
        emails:
          client.emails.length > 0 ? client.emails : ([{ address: '' }] as ClientFull['emails']),
      }),
    [client, reset],
  );

  const makeSubmit = (data: ClientFull) => {
    return mutation.mutateAsync(
      { ...data, agentName: profile?.name ?? '' },
      {
        onError: (error) =>
          enqueueSnackbar((error as Error).message || DEFAULT_ERROR_MESSAGE, { variant: 'error' }),
        onSuccess: () => {
          enqueueSnackbar(DEFAULT_SAVED_MESSAGE, { variant: 'success' });
          onSubmit();
        },
      },
    );
  };

  useEffect(() => {
    addCloseCallback('verifyForm', async () => {
      await handleSubmit(makeSubmit)();
      return false;
    });
    return () => {
      removeCloseCallback('verifyForm');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputProps = {
    fullWidth: true,
    disabled: mutation.isLoading,
  };

  return (
    <Box component="form" onSubmit={handleSubmit(makeSubmit)} mb={4}>
      <Box sx={{ borderBottom: 1, py: 1 }}>
        <Typography variant="h5">Basic information</Typography>
      </Box>
      <Grid sx={{ mt: 0 }} container spacing={4}>
        <Grid item xs={4}>
          <Controller
            name="title"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                {...inputProps}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                label="Title"
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="idNumber"
            control={control}
            rules={{
              required: true,
              validate: (value: string) => {
                if (isIDNumber) {
                  return validateAfricanId(
                    value,
                    genderID === ClientGender.male ? 'male' : 'female',
                    birthDate,
                  );
                }
                return validatePassportId(value);
              },
            }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                {...inputProps}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                label="Identity number *"
                placeholder="e.g. 123456789"
              />
            )}
          />
        </Grid>
        <Grid item xs={4} alignSelf="flex-end">
          <Controller
            name="isIDNumber"
            control={control}
            render={({ field }) => (
              <RadioGroup
                row
                {...field}
                // FormControlLabel value property doesn't accept boolean values so we need to convert it to string
                value={String(field.value)}
                onChange={(event) => field.onChange(event.target.value === 'true')}
              >
                <FormControlLabel value="true" control={<Radio />} label="Permanent ID" />
                <FormControlLabel value="false" control={<Radio />} label="Passport" />
              </RadioGroup>
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="firstname"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                {...inputProps}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                label="First name(s) as per ID document *"
                placeholder="e.g. Peter"
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="surname"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                {...inputProps}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                label="Last name as per ID document *"
                placeholder="e.g. Lambie"
              />
            )}
          />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <Controller
            name="initials"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                {...inputProps}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                label="Initials"
                placeholder="PL"
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <Controller
            name="genderID"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <AsyncSelect
                {...field}
                {...inputProps}
                queryKey="/Ops/ClientGenders"
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                label="Gender *"
              />
            )}
          />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <Controller
            name="dateofbirth"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <DateTextField
                {...field}
                {...inputProps}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                label="Date of birth *"
                placeholder="E.g. 1970 / 06 / 01"
              />
            )}
          />
        </Grid>
        <Grid item xs={4}>
          <ArrayField
            name="phones"
            control={control}
            blankItem={
              {
                number: '',
                typeID: availablePhoneTypes?.[0]?.id || phoneTypes?.[0]?.id,
                typeNote: availablePhoneTypes?.[0]?.note || phoneTypes?.[0]?.note,
              } as FieldArray<ClientFull, 'phones'>
            }
            getItemName={(index) => `phones.${index}.number` as FieldPath<ClientFull>}
            render={({ field, fieldState, item }) => (
              <TextField
                {...field}
                {...inputProps}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                label={`${item.typeNote || 'Phone number'} *`}
                placeholder="e.g. 0831234567"
                type="number"
              />
            )}
          />
        </Grid>
        <Grid item xs={4} />
        <Grid item xs={4}>
          <ArrayField
            name="emails"
            control={control}
            blankItem={{ address: '' } as FieldArray<ClientFull, 'emails'>}
            getItemName={(index) => `emails.${index}.address` as FieldPath<ClientFull>}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                {...inputProps}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                label="Email address *"
                placeholder="e.g. email@johndoe.com"
              />
            )}
          />
        </Grid>
      </Grid>

      {addresses.map((address, index) => (
        <Box key={address.id}>
          <Box sx={{ borderBottom: 1, py: 1, mt: 6 }}>
            <Typography variant="h5" sx={{ textTransform: 'capitalize' }}>
              {address.typeName} address
            </Typography>
          </Box>
          <Grid sx={{ mt: 0 }} container spacing={4}>
            <Grid item xs={8}>
              <Controller
                name={`addresses.${index}.street` as FieldPath<ClientFull>}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    {...inputProps}
                    label="Street address"
                    placeholder="e.g. 15 Rosehill Drive"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={8}>
              <Controller
                name={`addresses.${index}.city` as FieldPath<ClientFull>}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    {...inputProps}
                    label="City"
                    placeholder="e.g. Johannesburg"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={6}>
              <Controller
                name={`addresses.${index}.province` as FieldPath<ClientFull>}
                control={control}
                render={({ field }) => <TextField {...field} {...inputProps} label="Province" />}
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <Controller
                name={`addresses.${index}.country` as FieldPath<ClientFull>}
                control={control}
                render={({ field }) => <TextField {...field} {...inputProps} label="Country" />}
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={4}>
              <Controller
                name={`addresses.${index}.code` as FieldPath<ClientFull>}
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    {...inputProps}
                    label="Postal code"
                    placeholder="e.g. 1234"
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default VerifyForm;
