import { Decision } from './decision';

export enum AgentRole {
  active = 'Active',
  inactive = 'Inactive',
  monitor = 'Monitor',
}

export enum NoteType {
  view = 8,
  confirm = 9,
  comment = 2,
  system = 1,
  process = 3,
  share = 5,
  verifyclient = 10,
  decision = 4,
  allocation = 7,
}

export enum ClientGender {
  male = 1,
  female = 2,
  other = 3,
}

export enum ClientPhone {
  mobile = 1,
  home = 2,
  work = 3,
}

export enum ClientVerification {
  verified = 1,
  notstarted = 2,
  unverified = 3,
  serverError = 7,
  deceased = 9,
  doesNotExist = 6,
  unableToContact = 15,
}

export enum ClientVerificationShareStatus {
  notshared = 1,
  shared = 2,
  opened = 3,
}

export interface ClientNote {
  id: number;
  created: string;
  clientID: number;
  action: string;
  comment: string;
  leadID?: number;
  leadsShareContentTitle?: string;
  typeID: NoteType;
  typeName: string;
  typeNote: string;
  processID?: number;
  shareTypeID: number;
}

export interface Client {
  id: number;
  closed: boolean;
  isClosed: boolean;
  isSigned: boolean;
  agentID: number;
  allocatedAgentID: number;
  isAllocated: boolean;
  number: string;
  title: string;
  firstname: string;
  surname: string;
  fullname: string;
  phone: string;
  email: string | null;
  street: string;
  city: string;
  province: string;
  country: string;
  code: string;
  addressTypeID: number;
  addressType: string;
  idNumber: string;
  genderID: ClientGender;
  gender: string;
  initials: string;
  dateofbirth: string;
  processID: number;
  process: string;
  stateID: number;
  state: string;
  policyNumber: string;
  policyProduct: string;
  policyPremium: string;
  policySumAssured: string;
  policyInvestoValue: string;
  policyWealthValue: string;
  policyNote: string;
  agentRole: AgentRole;
  adviserRole: AgentRole;
  adviserName: string;
  adviserPhone: string;
  adviserEmail: string;
  previousAdviserName: string;
  previousAdviserPhone: string;
  previousAdviserEmail: string;
}

export interface ClientFull extends Client {
  id: number;
  agentID: number;
  allocatedAgentID: number;
  created: string;
  updated: string;
  title: string;
  firstname: string;
  surname: string;
  idNumber: string;
  isIDNumber: boolean;
  initials: string;
  dateofbirth: string;
  maritalStatus?: string;
  stateID: number;
  stateName: string;
  stateNote: string;
  processID: number;
  processName: string;
  processNote: string;
  genderID: number;
  genderName: string;
  genderNote: string;
  agentFullname: string;
  agentPhone: string;
  agentEmail: string;
  allocatedAgentFullname: string;
  allocatedAgentPhone: string;
  allocatedAgentEmail: string;
  fullname: string;
  emails: [
    {
      id: number;
      clientID: number;
      address: string;
    },
  ];
  phones: [
    {
      id: number;
      clientID: number;
      number: string;
      typeID: ClientPhone;
      typeName: string;
      typeNote: string;
    },
  ];
  addresses: [
    {
      id: number;
      clientID: number;
      street: string;
      city: string;
      province: string;
      country: string;
      code: string;
      typeID: number;
      typeName: string;
      typeNote: string;
    },
  ];
  policies: [
    {
      id: number;
      clientID: number;
      product: string;
      number: string;
      premium: string;
      sumAssured: string;
      investoValue: string;
      wealthValue: string;
      note: string;
      adviserFeeOngoing: string;
      adviserFeeDistribution: string;
      adviserFeeTotal: string;
    },
  ];
  decisions?: Decision[];
  notes: ClientNote[];
  verifyStatusNote?: string;
  verifyStatusID?: ClientVerification;
  verifyShareStatusNote?: string;
  verifyShareStatusID?: ClientVerificationShareStatus;
}
