import { Box } from '@mui/material';
import { completeParametrizedUrl } from '../../common/lib/url';
import { MoloDoc } from '../../common/types/molo/moloDoc';
import { useEffect } from 'react';

interface DocIframeContentProps {
  doc: MoloDoc;
  urlParameters: Record<string, string | undefined>;
}

const DocIframeContent = ({ doc, urlParameters }: DocIframeContentProps) => {
  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (
        event.origin === 'https://momentumfp.typeform.com' &&
        event.data?.type === 'thank-you-screen-redirect' &&
        event.data.url
      ) {
        (top ? top.window : window).location.href = event.data.url;
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  let source: string | null = null;
  switch (doc.contentType.contentTypeSelection) {
    case 'url': {
      source = doc.contentType.url;
      break;
    }
    case 'image': {
      source = doc.contentType.image.url;
      break;
    }
    case 'pdfFile': {
      source = doc.contentType.pdfFile.url;
      break;
    }
  }
  if (!source) return null;

  return (
    <Box>
      <iframe
        frameBorder={0}
        src={completeParametrizedUrl(source, urlParameters)}
        style={{ width: '100%', minHeight: 600, height: '100%' }}
      />
    </Box>
  );
};

export default DocIframeContent;
