import { Avatar, Box, Button, Link } from '@mui/material';
import { MoloAgentExt } from '../../types/molo/moloAgentExt';
import { MoloShare } from '../../types/molo/moloShare';
import { useAgentMediaUrl } from '../../hooks/useAgentMediaUrl';
import { useAppConfig } from '../../contexts/configContext';
import { useMyProfile } from '../../hooks/auth';
import { useState } from 'react';
import ConnectDialog from '../dialog/connectDialog';
import Content from '../context';
import SlideMenu from './slideMenu';

interface LayoutHeaderProps {
  agent?: MoloAgentExt;
  share?: MoloShare;
}

const LayoutHeader = ({ agent, share }: LayoutHeaderProps) => {
  const { config } = useAppConfig();
  const profile = useMyProfile();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isConnectOpen, setIsConnectOpen] = useState(false);
  const mediaUrl = useAgentMediaUrl(agent, 'Image') || profile?.mediaurl;
  const fullName = agent ? `${agent?.item.firstname} ${agent?.item.surname}` : profile?.name;

  return (
    <Box bgcolor="common.white">
      <Content>
        <Box paddingBottom={2} display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Link href={profile ? `/${profile?.username}/dashboard` : undefined}>
              <img height={64} src={config.logoimage} />
            </Link>
            {agent && (
              <Box marginLeft={2}>
                {mediaUrl ? (
                  <img src={mediaUrl} height={51} alt="" />
                ) : (
                  <Avatar alt={fullName} sx={{ width: 51, height: 51 }} />
                )}
              </Box>
            )}
          </Box>
          {agent && share && (
            <>
              <Button variant="contained" color="secondary" onClick={() => setIsConnectOpen(true)}>
                Get in touch
              </Button>
              <ConnectDialog
                share={share}
                agent={agent}
                open={isConnectOpen}
                onClose={() => setIsConnectOpen(false)}
              />
            </>
          )}
          {!agent && profile && (
            <>
              <Button variant="outlined" onClick={() => setIsMenuOpen(true)} color="gray">
                Menu
              </Button>
              <SlideMenu open={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
            </>
          )}
        </Box>
      </Content>
    </Box>
  );
};

export default LayoutHeader;
