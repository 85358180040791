import { Button, Dialog, DialogProps, Grid, TextField } from '@mui/material';
import { ClientFull } from '../../../common/types/client';
import { Controller, useForm } from 'react-hook-form';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SAVED_MESSAGE,
} from '../../../common/contexts/configContext';
import { useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useUpdateClientMutation } from '../../../ops/mutations';
import Content from '../../../common/components/context';

interface AdviserDialogProps extends DialogProps {
  client: ClientFull;
  open: boolean;
  onClose: () => void;
  resetData: {
    adviserName?: string;
    adviserPhone?: string;
    adviserEmail?: string;
    previousAdviserName?: string;
    previousAdviserPhone?: string;
    previousAdviserEmail?: string;
  };
  defaultValues: {
    adviserName?: string;
    adviserPhone?: string;
    adviserEmail?: string;
    previousAdviserName?: string;
    previousAdviserPhone?: string;
    previousAdviserEmail?: string;
  };
  name: 'adviserName' | 'previousAdviserName';
  phone: 'adviserPhone' | 'previousAdviserPhone';
  email: 'adviserEmail' | 'previousAdviserEmail';
}

const AdviserDetailsDialog = ({
  client,
  resetData,
  defaultValues,
  ...props
}: AdviserDialogProps) => {
  const { handleSubmit, control, reset } = useForm({
    defaultValues: defaultValues,
  });
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useUpdateClientMutation();

  const onSubmit = (data) => {
    mutation.mutate(
      { ...client, ...data },
      {
        onError: (error) =>
          enqueueSnackbar((error as Error).message || DEFAULT_ERROR_MESSAGE, { variant: 'error' }),
        onSuccess: () => {
          enqueueSnackbar(DEFAULT_SAVED_MESSAGE, { variant: 'success' });
          props.onClose();
        },
      },
    );
  };
  useEffect(() => reset(resetData), [client, reset, resetData]);
  return (
    <Dialog
      sx={{ '& .MuiDialog-container': { justifyContent: 'flex-end' } }}
      PaperProps={{ sx: { width: 1, height: 1, maxHeight: 1, maxWidth: 600, m: 0 } }}
      {...props}
    >
      <Content sx={{ py: 4 }}>
        <Grid
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ mt: 0, flexDirection: 'column' }}
          container
          spacing={4}
        >
          <Grid item xs={4}>
            <Controller
              name={props.name}
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={Boolean(fieldState.error)}
                  sx={{ width: '250px' }}
                  helperText={fieldState.error?.message}
                  label="Own adviser’s full name*"
                  placeholder="e.g. John Doe"
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name={props.phone}
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                  sx={{ width: '250px' }}
                  label="Contact number *"
                  placeholder="e.g. 0831234567"
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Controller
              name={props.email}
              control={control}
              rules={{ required: true }}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                  sx={{ width: '380px' }}
                  label="Email address *"
                  placeholder="e.g. email@johndoe.com"
                />
              )}
            />
          </Grid>
          <Button
            sx={{ width: '100px', mt: 4, ml: 4 }}
            type="submit"
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </Grid>
      </Content>
    </Dialog>
  );
};
export default AdviserDetailsDialog;
