import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogProps,
  Divider,
  FormControlLabel,
  LinearProgress,
  Switch,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { ClientFull } from '../../../common/types/client';
import { formatDateTime } from '../../../common/lib/date';
import { tabsStyle } from '../../../ops/opsPage';
import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import AddNoteDialog from './addNoteDialog';
import Content from '../../../common/components/context';
import DialogHeader from '../../../ops/components/dialogHeader';
import LeadDialog from '../../../leads/components/leadDialog';
import RichTextEditor from '../../../common/components/richTextEditor';

interface NotesDialogProps extends DialogProps {
  clientId: number;
}

const noteChipBackground = {
  2: '#BD38AA',
  3: 'common.red',
  4: '#DA662E',
  5: '#D02F6D',
  6: '#245779',
  7: '#E9A239',
  8: '#50B0DF',
  9: '#55BA78',
};

const NotesDialog = ({ clientId, ...props }: NotesDialogProps) => {
  const {
    data: client,
    refetch,
    isFetching,
  } = useQuery<ClientFull>({
    queryKey: [`/Ops/ClientDetail/${clientId}`],
  });
  const [currentTab, setCurrentTab] = useState(0);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [selectedLeadId, setSelectedLeadId] = useState<number | null>(null);
  const [isSortNewest, setIsSortNewest] = useState(true);
  const availableNoteTypes = useMemo(
    () => new Set(client?.notes.map((note) => note.typeNote)),
    [client?.notes],
  );
  const notesToDisplay = useMemo(() => {
    if (currentTab === 0) {
      return client?.notes;
    }
    const selectedType = [...availableNoteTypes].at(currentTab - 1);
    return client?.notes.filter((note) => note.typeNote === selectedType);
  }, [currentTab, availableNoteTypes, client?.notes]);

  const sortedData = notesToDisplay?.sort((a, b) =>
    isSortNewest
      ? new Date(b.created).getTime() - new Date(a.created).getTime()
      : new Date(a.created).getTime() - new Date(b.created).getTime(),
  );

  const handleToggle = (event) => {
    setIsSortNewest(event.target.checked);
  };

  // Refetching client data when user opens the dialog
  useEffect(() => {
    if (props.open) {
      refetch();
    }
  }, [props.open, refetch]);

  return (
    <Dialog fullScreen {...props}>
      <DialogHeader
        onClose={(event) => props.onClose?.(event, 'escapeKeyDown')}
        title={`${client?.fullname} - History`}
      />
      <Content sx={{ py: 4 }}>
        <Box display="flex" mb={4} alignItems="center" justifyContent="space-between">
          <Tabs
            sx={tabsStyle}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            value={currentTab}
            onChange={(_, newValue: number) => setCurrentTab(newValue)}
          >
            <Tab label="All" />
            {[...availableNoteTypes].map((noteType) => (
              <Tab key={noteType} label={noteType} />
            ))}
          </Tabs>
          <Button
            variant="contained"
            sx={{ fontSize: { xs: 14, md: 16 } }}
            color="primary"
            onClick={() => setShowAddDialog(true)}
          >
            comment
          </Button>
        </Box>
        <Box display="flex">
          <Box width={{ xs: '80px', md: '170px' }}></Box>
          <FormControlLabel
            control={<Switch checked={isSortNewest} onChange={handleToggle} />}
            label={isSortNewest ? 'Newest at top' : 'Oldest at top'}
          />
        </Box>

        <LinearProgress sx={{ visibility: isFetching ? 'visible' : 'hidden' }} />
        {sortedData?.map((note) => {
          return (
            <Box
              key={note.id}
              sx={{
                mt: 2,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Box display="flex" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                  <Typography
                    sx={{ display: { xs: 'none', md: 'block' } }}
                    color="text.secondary"
                    variant="small"
                  >
                    {formatDateTime(note.created)}
                  </Typography>
                  <Chip
                    sx={{
                      height: 48,
                      minWidth: 48,
                      mx: 2,
                      fontWeight: 700,
                      borderRadius: 30,
                      fontSize: '18px',
                      color: 'white.main',
                      backgroundColor: noteChipBackground[note.typeID] || 'default',
                    }}
                    label={`${note.typeNote.split(' ')[0]?.[0] ?? ''}${
                      note.typeNote.split(' ')[1]?.[0] ?? ''
                    }`}
                  />
                  <Box>
                    {currentTab === 0 && (
                      <Typography
                        sx={{
                          textTransform: 'uppercase',
                          color: 'text.secondary',
                          fontSize: '12px',
                        }}
                      >
                        {note.typeNote}
                      </Typography>
                    )}
                    <Typography
                      sx={{ display: { xs: 'block', md: 'none' } }}
                      color="text.secondary"
                      variant="small"
                    >
                      {formatDateTime(note.created)}
                    </Typography>
                    <Typography sx={{ mt: 1 }} fontWeight={500} variant="body2">
                      {note.action}
                    </Typography>
                    {note.shareTypeID === 8 && (
                      <Typography sx={{ fontSize: 14, color: 'text.secondary' }}>
                        via copied link
                      </Typography>
                    )}
                    {note.shareTypeID === 1 && (
                      <Typography sx={{ fontSize: 14, color: 'text.secondary' }}>
                        via WhatsApp
                      </Typography>
                    )}
                    {note.comment && (
                      <Box sx={{ fontSize: 14, color: 'text.secondary' }}>
                        <RichTextEditor value={note.comment} editable={false} />
                      </Box>
                    )}
                    {Boolean(note.leadID) && (
                      <Box mt={1}>
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={() => note.leadID && setSelectedLeadId(note.leadID)}
                        >
                          View response
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  backgroundColor: '#C7C8CA80',
                  height: '55px',
                  width: '1px',
                  ml: '130px',
                  mt: 2,
                }}
              >
                <Divider orientation="vertical" />
              </Box>
            </Box>
          );
        })}
      </Content>
      <LeadDialog leadId={selectedLeadId} onClose={() => setSelectedLeadId(null)} />
      {client && (
        <AddNoteDialog
          {...props}
          client={client}
          open={showAddDialog}
          onClose={() => setShowAddDialog(false)}
        />
      )}
    </Dialog>
  );
};

export default NotesDialog;
