import { Box, Button, Card, CardContent, Divider, Typography } from '@mui/material';
import { formatDate } from '../../common/lib/date';
import { MoloAgentExt } from '../../common/types/molo/moloAgentExt';
import { MoloDoc } from '../../common/types/molo/moloDoc';
import { useState } from 'react';
import ShareDialog from './shareDialog';

interface DocHeaderProps {
  doc: MoloDoc;
  agent: MoloAgentExt;
}

const DocHeader = ({ doc, agent }: DocHeaderProps) => {
  const [isShareOpen, setIsShareOpen] = useState(false);

  if (doc?._type === 'letter') {
    return (
      <Box>
        <Typography variant="h2" fontWeight={400}>
          {doc.metaProperties.title}
        </Typography>
        <Divider sx={{ my: 6 }} />
      </Box>
    );
  }

  return (
    <Card elevation={6}>
      <CardContent sx={{ display: 'flex', p: 4, flexDirection: { xs: 'column', sm: 'row' } }}>
        <Box sx={{ width: { xs: 1, sm: 0.3 }, maxWidth: { sm: 340 }, flexShrink: 0 }}>
          <img src={doc.metaImage} width="100%" alt="" />
        </Box>
        <Box sx={{ ml: { sm: 4 } }}>
          <Typography variant="h2" fontWeight={600} sx={{ mb: 2 }}>
            {doc.metaProperties.title}
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <Typography color="text.secondary" sx={{ minWidth: 100 }}>
              Author
            </Typography>
            <Typography>{doc.author?.name}</Typography>
          </Box>
          <Box sx={{ display: 'flex', mt: 1 }}>
            <Typography color="text.secondary" sx={{ minWidth: 100 }}>
              Published
            </Typography>
            <Typography>{formatDate(doc._updatedAt)}</Typography>
          </Box>
          <Button sx={{ mt: 2 }} variant="outlined" onClick={() => setIsShareOpen(true)}>
            Share
          </Button>
        </Box>
      </CardContent>
      <ShareDialog
        clientId={0}
        processId={0}
        agentId={agent.item.id}
        doc={isShareOpen ? doc : undefined}
        onClose={() => setIsShareOpen(false)}
        messageType="doc"
      />
    </Card>
  );
};

export default DocHeader;
