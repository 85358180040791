import { Box, Typography } from '@mui/material';
import { StepContentProps } from '../../../../common/components/stepDialog';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const EficaForm = (props: StepContentProps) => (
  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: 300 }}>
    <Typography color="secondary.main">Process would rely on integration to third party</Typography>
  </Box>
);

export default EficaForm;
