import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { getAuthToken, removeAuthToken } from '../lib/accessTokens';
import { MoloConfig } from '../types/molo/moloConfig';
import { QueryClient, QueryFunction } from '@tanstack/react-query';
import axios, { AxiosInstance } from 'axios';
import PageLoader from '../components/pageLoader';

export const DEFAULT_ERROR_MESSAGE = 'Oops, something went wrong';
export const DEFAULT_SAVED_MESSAGE = 'Changes have been saved';

const makeForm = (data?: Record<string, unknown>): FormData | null => {
  if (!data) return null;
  const form = new FormData();
  Object.entries(data).map(([key, value]) => form.set(key, value as string));
  return form;
};

export type AppConfig = {
  config: MoloConfig;
  queryClient: QueryClient;
  moloApi: AxiosInstance;
  privateOpsApi: AxiosInstance;
  opsApi: AxiosInstance;
};

const ConfigContext = createContext<AppConfig | null>(null);

export const ConfigProvider = ({ children }: { children: ReactNode }) => {
  const [config, setConfig] = useState<MoloConfig | null>(null);
  useEffect(() => {
    axios
      .get<MoloConfig>(
        process.env.NODE_ENV !== 'production' || process.env.REACT_APP_VERCEL
          ? 'https://mfp.bethink.co.za/Config'
          : '/Config',
      )
      .then(({ data }) => setConfig(data));
  }, []);

  /**
   * Axios client to work with ops API authorized endpoints
   */
  const privateOpsApi = useMemo(() => {
    const client = axios.create({
      baseURL: config?.opsapiBaseUrl,
    });

    client.interceptors.request.use((config) => ({
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${getAuthToken('opsToken')}`,
      },
    }));

    client.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          removeAuthToken();
          window.location.reload();
        }
        throw error;
      },
    );

    return client;
  }, [config]);

  /**
   * Axios instance to work with unauthorized ops api endpoints (login, etc)
   */
  const opsApi = useMemo(() => {
    return axios.create({
      baseURL: config?.opsapiBaseUrl,
    });
  }, [config]);

  const moloApi = useMemo(() => {
    const client = axios.create({
      baseURL: config?.apiBaseUrl,
    });

    client.interceptors.request.use((axiosRequestConfig) => ({
      ...axiosRequestConfig,
      headers: {
        ...axiosRequestConfig.headers,
        Authorization: `Basic ${config?.token}`,
      },
    }));

    return client;
  }, [config]);

  /**
   * Query client for apollo library
   */
  const queryClient = useMemo(() => {
    const defaultQueryFunction: QueryFunction<unknown, ReadonlyArray<unknown>> = ({
      queryKey: [url, data, method],
    }) => {
      if ((url as string).startsWith('/Molo')) {
        return moloApi({
          method: method === 'postForm' ? 'post' : method ? String(method) : 'get',
          url: url as string,
          ...((!method || method === 'get') && { params: data }),
          ...(method === 'post' && { data }),
          ...(method === 'postForm' && { data: makeForm(data as Record<string, unknown>) }),
        }).then(({ data }) => data);
      }
      return privateOpsApi.get(url as string, { params: data }).then(({ data }) => data);
    };

    return new QueryClient({
      defaultOptions: {
        queries: {
          queryFn: defaultQueryFunction,
          refetchOnWindowFocus: false,
        },
      },
    });
  }, [moloApi, privateOpsApi]);

  if (!config) {
    return <PageLoader />;
  }
  return (
    <ConfigContext.Provider value={{ config, queryClient, opsApi, moloApi, privateOpsApi }}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useAppConfig = () => {
  return useContext(ConfigContext) as AppConfig;
};
