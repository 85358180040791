import { Box, Divider, Skeleton } from '@mui/material';
import { ClientFull, NoteType } from '../../common/types/client';
import { MoloAgentExt } from '../../common/types/molo/moloAgentExt';
import { MoloDoc } from '../../common/types/molo/moloDoc';
import { MoloShare, MoloShareType } from '../../common/types/molo/moloShare';
import { useCreateClientNoteMutation } from '../../ops/mutations';
import { useEffect, useMemo } from 'react';
import { useQueryMoloDoc } from '../../common/hooks/useQueryMoloDocs';
import AudioContent from './audioContent';
import Content from '../../common/components/context';
import DocConfirmation from './docConfirmation';
import DocHeader from './docHeader';
import DocHtmlContent, { DocLabelsToReplace } from './docHtmlContent';
import DocIframeContent from './docIframeContent';
import DownloadPdfButton from './downloadPdfButton';
import Layout from '../../common/components/layout';
import VerificationDoc from './verificationDoc';
import VideoContent from './videoContent';

const mapContentTypeToComponent: Record<
  MoloDoc['contentType']['contentTypeSelection'],
  (props: {
    doc: MoloDoc;
    labelsToReplace?: DocLabelsToReplace;
    urlParameters: Record<string, string | undefined>;
  }) => JSX.Element | null
> = {
  audioFile: AudioContent,
  pdfFile: DocIframeContent,
  videoFile: VideoContent,
  url: DocIframeContent,
  image: DocIframeContent,
  html: DocHtmlContent,
};

interface DocContentProps {
  share?: MoloShare;
  agent?: MoloAgentExt;
  client?: ClientFull;
  urlParameters?: Record<string, string | undefined>;
}

const DocContent = ({ share, agent, client, urlParameters = {} }: DocContentProps) => {
  const { mutate: noteMutate } = useCreateClientNoteMutation();
  const { data: docs } = useQueryMoloDoc(share?.item.assetuid);
  const doc = useMemo(() => docs?.result[0], [docs]);
  const isTypeForm =
    doc?.contentType.contentTypeSelection === 'url' && doc?.contentType.url.includes('typeform');
  const DocContentComponent = doc
    ? mapContentTypeToComponent[doc.contentType.contentTypeSelection]
    : null;

  const isVerificationDoc = doc?._type === 'verification';

  const labelsToReplace: DocLabelsToReplace = {
    '{adviserName}': `${agent?.item.firstname} ${agent?.item.surname}`,
    '{adviserFirstname}': agent?.item.firstname,
    '{adviserLastname}': agent?.item.surname,
    '{adviserPhoneNumber}': agent?.item.cellphone,
    '{adviserEmail}': agent?.item.email,
    '{clientFirstname}': client?.firstname,
    '{clientFirstName}': client?.firstname,
    '{clientLastname}': client?.surname,
    '{clientLastName}': client?.surname,
    '{externalAdviserEmail}': client?.adviserEmail,
    '{externalAdviserName}': client?.adviserName,
    '{externalAdviserContactNumber}': client?.adviserPhone,
    '{policyNumber}': client?.policies?.map(({ number }) => number).join(', '),
    '{productName}': client?.policies?.map(({ product }) => product).join(', '),
    '{previousAdviserName}': client?.previousAdviserName,
    '{previousAdviserEmail}': client?.previousAdviserEmail,
    '{previousAdviserContactNumber}': client?.previousAdviserPhone,
  };

  // Generate view note
  useEffect(() => {
    if (share && client && doc && share?.item.typeid !== String(MoloShareType.preview)) {
      noteMutate({
        ClientID: client.id,
        TypeID: NoteType.view,
        Action: `${client.fullname} opened "${doc.metaProperties.title}"`,
        AssetUID: share.item.assetuid,
        ProcessID: share.item.processid,
        Comment: '',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [share?.item.id, client?.id, doc?._id]);

  // Persist user confirmation (generate note)
  const handleConfirm = () => {
    if (share && client && doc && share?.item.typeid !== String(MoloShareType.preview)) {
      noteMutate({
        ClientID: client.id,
        TypeID: NoteType.confirm,
        Action: `${client.fullname} confirmed they read "${doc.metaProperties.title}"`,
        AssetUID: share.item.assetuid,
        ProcessID: share.item.processid,
        Comment: '',
      });
    }
  };

  return (
    <Layout agent={agent} share={share}>
      <Content sx={{ py: 4 }}>
        {doc && agent ? (
          <>
            {!isTypeForm && !isVerificationDoc && (
              <Box sx={{ mb: 4 }}>
                <DocHeader doc={doc} agent={agent} />
              </Box>
            )}
            {DocContentComponent ? (
              <DocContentComponent
                doc={doc}
                labelsToReplace={labelsToReplace}
                urlParameters={urlParameters}
              />
            ) : null}
            {isVerificationDoc && share && client && (
              <VerificationDoc doc={doc} share={share} client={client} />
            )}
            {doc.confirmationEnabled && (
              <>
                <Divider sx={{ my: 6 }} />
                <DocConfirmation
                  onConfirm={handleConfirm}
                  labelsToReplace={labelsToReplace}
                  doc={doc}
                  agent={agent}
                  share={share}
                />
              </>
            )}
            {doc.downloadingEnabled && share && <DownloadPdfButton doc={doc} share={share} />}
          </>
        ) : (
          <>
            <Skeleton height={250} sx={{ mb: 4 }} variant="rectangular" />
            <Skeleton height={500} variant="rectangular" />
          </>
        )}
      </Content>
    </Layout>
  );
};

export default DocContent;
