import { Button, Typography } from '@mui/material';
import { Icon } from '@iconify/react';
import { MouseEvent } from 'react';
import BaseDialogHeader, {
  DialogHeaderDivider,
} from '../../common/components/dialog/baseDialogHeader';

interface DialogHeaderProps {
  title: string;
  onClose: (event: MouseEvent) => void;
}

const DialogHeader = ({ onClose, title }: DialogHeaderProps) => (
  <BaseDialogHeader>
    <Button
      startIcon={<Icon icon="mdi:chevron-left" />}
      variant="outlined"
      color="white"
      onClick={onClose}
    >
      Back
    </Button>
    <DialogHeaderDivider />
    <Typography variant="h4">{title}</Typography>
  </BaseDialogHeader>
);

export default DialogHeader;
