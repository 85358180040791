import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import ExportToolbar from '../common/components/exportToolbar';

interface StatusStats {
  id: number;
  allocatedAgentID?: number;
  agentID?: number;
  processID: number;
  clientCount: number;
  closed: boolean;
  processNote: string;
  agentFullname?: string;
  allocatedAgentFullname?: string;
}

const processColumns: GridColumns = [
  { field: 'name', headerName: 'Process', flex: 1 },
  { field: 'total', headerName: 'Closed', flex: 1 },
];

const agentColumns: GridColumns = [
  { field: 'name', headerName: 'Agent', flex: 1 },
  { field: 'total', headerName: 'Closed', flex: 1 },
];

const ClosedClientsReportPage = () => {
  const { data: advisersStats } = useQuery<StatusStats[]>({
    queryKey: ['/Ops/ClientStatusAgentSummarys'],
  });
  const { data: agentsStats } = useQuery<StatusStats[]>({
    queryKey: ['/Ops/ClientStatusAllocatedAgentSummarys'],
  });
  const rows = [...(advisersStats ?? []), ...(agentsStats ?? [])];
  const closedByProcess = rows.reduce((accumulator, row) => {
    if (!row.closed) {
      return accumulator;
    }

    accumulator.set(row.processID, {
      total: (accumulator.get(row.processID)?.total ?? 0) + row.clientCount,
      name: row.processNote,
      id: row.processID,
    });

    return accumulator;
  }, new Map<number, { total: number; name: string; id: number }>());

  const closedByAgent = rows.reduce((accumulator, row) => {
    const id = row.agentID || row.allocatedAgentID;
    if (!row.closed || !id) {
      return accumulator;
    }

    accumulator.set(id, {
      total: (accumulator.get(id)?.total ?? 0) + row.clientCount,
      name: row.agentFullname || row.allocatedAgentFullname || 'Unknown',
      id,
    });

    return accumulator;
  }, new Map<number, { total: number; name: string; id: number }>());

  return (
    <div>
      <DataGrid
        autoHeight
        components={{ Toolbar: ExportToolbar }}
        rows={[...closedByProcess.values()]}
        columns={processColumns}
        hideFooter
      />
      <DataGrid
        sx={{ mt: 8 }}
        autoHeight
        components={{ Toolbar: ExportToolbar }}
        rows={[...closedByAgent.values()]}
        columns={agentColumns}
        hideFooter
      />
    </div>
  );
};

export default ClosedClientsReportPage;
