import { Box, Tab, Tabs } from '@mui/material';
import { StepContentProps } from '../../../../common/components/stepDialog';
import { useState } from 'react';
import DecisionForm from '../../../../common/components/decisionForm';
import Instructions from '../../../../common/components/instructions';

const decisions = [
  'They want to appoint me as their adviser',
  'They want to use their own adviser',
  'They want to withdraw their money',
  'They want to use another Momentum adviser',
  'They want to cancel / have cancelled their policy',
  'They are on a policy holiday',
  "They don't want an adviser",
];

const AdviserDecisionDialog = ({ client, onFormLoadingStateChange, process }: StepContentProps) => {
  const [currentTab, setCurrentTab] = useState(0);
  return (
    <>
      <Tabs
        sx={{ borderBottom: 1, borderColor: '#DDDEE5' }}
        value={currentTab}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        onChange={(_, value) => setCurrentTab(value)}
      >
        <Tab label="Guide" />
        <Tab label="Decision" />
      </Tabs>
      <Box sx={{ mt: 6 }}>
        <Box sx={{ display: currentTab === 0 ? 'block' : 'none' }}>
          <Instructions keyword="decision" />
        </Box>
        <Box sx={{ display: currentTab === 1 ? 'block' : 'none' }}>
          <DecisionForm
            onFormLoadingStateChange={onFormLoadingStateChange}
            decisions={decisions}
            process={process}
            client={client}
          />
        </Box>
      </Box>
    </>
  );
};

export default AdviserDecisionDialog;
