import { AdviserStats } from '../common/types/agentStats';
import { ArcElement, Chart, Legend, Tooltip } from 'chart.js';
import { DashboardCard } from '../ops/opsDashboard';
import { Grid, Typography } from '@mui/material';
import { useMyProfile } from '../common/hooks/auth';
import { useQuery } from '@tanstack/react-query';

Chart.register(ArcElement, Tooltip, Legend);

interface AdvisersDashboardProps {
  onChangeTab: (tab: number) => void;
}

const AdvisersDashboard = ({ onChangeTab }: AdvisersDashboardProps) => {
  const profile = useMyProfile();
  const { data: agentsStats } = useQuery<AdviserStats[]>({
    queryKey: [
      '/Ops/AdviserClientCounts',
      {
        AllocatedAgentID: profile?.id,
      },
    ],
    enabled: Boolean(profile?.id),
  });
  const agentStats = agentsStats?.[0];

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3} sm={4}>
        <DashboardCard title="Allocated clients">
          <Typography variant="h1" mb={4} color="text.darkGray">
            {agentStats?.totalAllocated ?? '-'}
          </Typography>
        </DashboardCard>
      </Grid>
      <Grid item xs={12} md={3} sm={4}>
        <DashboardCard
          onClick={() => onChangeTab(1)}
          title="In progress"
          percentage={
            ((agentStats?.totalInProgress ?? 0) / (agentStats?.totalAllocated ?? 0)) * 100
          }
          percentageColor="#FF9533"
          percentageText="of allocated clients"
        >
          <Typography variant="h1" mb={4} color="text.darkGray">
            {agentStats?.totalInProgress ?? '-'}
          </Typography>
        </DashboardCard>
      </Grid>
      <Grid item xs={12} md={3} sm={4}>
        <DashboardCard
          onClick={() => onChangeTab(2)}
          title="Closed"
          percentage={((agentStats?.totalClosed ?? 0) / (agentStats?.totalAllocated ?? 0)) * 100}
          percentageColor="#E11B22"
          percentageText="of allocated clients"
        >
          <Typography variant="h1" mb={4} color="text.darkGray">
            {agentStats?.totalClosed ?? '-'}
          </Typography>
        </DashboardCard>
      </Grid>
      <Grid item xs={12} md={3} sm={4}>
        <DashboardCard
          onClick={() => onChangeTab(3)}
          title="Signed"
          percentage={((agentStats?.totalSigned ?? 0) / (agentStats?.totalAllocated ?? 0)) * 100}
          percentageColor="#64AE42"
          percentageText="of allocated clients"
        >
          <Typography variant="h1" mb={4} color="text.darkGray">
            {agentStats?.totalSigned ?? '-'}
          </Typography>
        </DashboardCard>
      </Grid>
    </Grid>
  );
};

export default AdvisersDashboard;
