import { Box, MenuItem, Skeleton, TextField, TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';
import { OptionModel } from '../types/optionModel';
import { useQuery } from '@tanstack/react-query';

interface AsyncSelectProps {
  queryKey: string;
}

const AsyncSelect = forwardRef(({ queryKey, ...props }: TextFieldProps & AsyncSelectProps, ref) => {
  const { data: items, isLoading } = useQuery<OptionModel[]>({
    queryKey: [queryKey],
  });

  if (isLoading || !items) {
    return (
      <Box>
        <Skeleton height={16} width={100} />
        <Skeleton sx={{ mt: 1 }} variant="rounded" height={36} />
      </Box>
    );
  }

  // Not sure why but after client data is fetched and value passed to this component it doesn't refresh the selected item.
  // As a temporary fix I used the "key" property.
  return (
    <TextField key={props.value as string} inputRef={ref} {...props} select>
      {items.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {item.name}
        </MenuItem>
      ))}
    </TextField>
  );
});

AsyncSelect.displayName = 'AsyncSelect';

export default AsyncSelect;
