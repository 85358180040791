import luhn from 'fast-luhn';

/**
 * Validate South African ID number
 * @param id ID to validate
 * @param gender Gender to validate
 * @param birthDate Date of birth to validate ("YYMMDD" format)
 * @returns True if the ID is valid, false otherwise
 */
export const validateAfricanId = (
  id: string,
  gender: 'male' | 'female',
  birthDate: string,
): boolean => {
  const normalizeBirthDate = birthDate.replace(/^(?:\d{2})?(\d{2})\/(\d{2})\/(\d{2})$/, '$1$2$3');
  if (id.length !== 13) {
    return false;
  }
  const matches = /^(\d{6})(\d{4})\d{3}$/.exec(id);
  if (!matches) {
    return false;
  }
  const [, idBirthDate, idGender] = matches;
  // Entered date of birth must match the ID date birth
  if (idBirthDate !== normalizeBirthDate) {
    return false;
  }
  // Entered gender must match the ID gender
  if (gender === 'male' && Number(idGender) < 5000) {
    return false;
  }

  return luhn(id);
};

/**
 * Validate passport ID
 */
export const validatePassportId = (id: string): boolean => {
  return /^[a-z]\d{8}$/i.test(id);
};
