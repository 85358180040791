import './docHtmlContent.css';
import { Box } from '@mui/material';
import { MoloDoc } from '../../common/types/molo/moloDoc';
import { PortableText, PortableTextProps } from '@portabletext/react';

export type DocLabelsToReplace = Record<string, string | undefined>;

/**
 * Replace labels in a string with actual values.
 */
export const replaceLabels = (value: string, labels: DocLabelsToReplace): string => {
  return Object.entries(labels).reduce((result, [pattern, replacement]) => {
    result = result.replace(pattern, replacement || pattern);
    return result;
  }, value);
};

/**
 * Some documents have labels that need to be replaced with actual values.
 */
export const replaceLabelsInSanityBlocks = (
  blocks: PortableTextProps['value'],
  labels: DocLabelsToReplace,
): PortableTextProps['value'] => {
  return (Array.isArray(blocks) ? blocks : [blocks]).map((block) => ({
    ...block,
    children: block.children?.map((child) => ({
      ...child,
      ...(child.text && { text: replaceLabels(child.text, labels) }),
    })),
  }));
};

/**
 * Sanity keep redundant newlines at the start and end of blocks. And this breaks the layout.
 */
const trimSanityBlocks = (blocks: PortableTextProps['value']): PortableTextProps['value'] => {
  return (Array.isArray(blocks) ? blocks : [blocks]).map((block) => ({
    ...block,
    children: block.children?.map((child) => ({
      ...child,
      ...(child.text && { text: child.text?.trim() }),
    })),
  }));
};

interface DocHtmlContentProps {
  doc: MoloDoc;
  labelsToReplace?: DocLabelsToReplace;
}

const DocHtmlContent = ({ doc, labelsToReplace }: DocHtmlContentProps) => {
  if (doc.contentType.contentTypeSelection !== 'html') return null;
  const isLetter = doc?._type === 'letter';
  const blocks = labelsToReplace
    ? replaceLabelsInSanityBlocks(doc.contentType.html, labelsToReplace)
    : doc.contentType.html;

  return (
    <Box className={`sanity-content ${isLetter ? 'sanity-letter' : ''}`}>
      <PortableText value={trimSanityBlocks(blocks)} />
    </Box>
  );
};

export default DocHtmlContent;
