import { Box, Button, ButtonBase, Dialog, DialogContent, Divider, Typography } from '@mui/material';
import { ClientFull } from '../../common/types/client';
import { DEFAULT_ERROR_MESSAGE } from '../../common/contexts/configContext';
import { Icon } from '@iconify/react';
import { MoloDoc } from '../../common/types/molo/moloDoc';
import { MoloShare, MoloShareState, MoloShareType } from '../../common/types/molo/moloShare';
import { ReactNode } from 'react';
import { useIsAdviser, useMyProfile } from '../../common/hooks/auth';
import { useMutationShare } from '../../common/hooks/useMutationShare';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useUrl } from '../../common/hooks/useUrl';
import respondIo, { ShareMessageType } from '../../common/lib/respondIo';

interface ActionButtonProps {
  title: string;
  icon: ReactNode;
  onClick: () => void;
}

const ActionButton = ({ title, icon, onClick }: ActionButtonProps) => (
  <ButtonBase
    onClick={onClick}
    sx={{ display: 'inline-flex', flexDirection: 'column', alignItems: 'center', mr: 3 }}
  >
    <Box
      sx={{
        color: 'secondary.main',
        border: 1,
        borderColor: '#ECEDF2',
        borderRadius: '50%',
        width: 64,
        height: 64,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        mb: 1,
      }}
    >
      {icon}
    </Box>
    <Typography variant="small">{title}</Typography>
  </ButtonBase>
);

interface ShareDialogProps {
  agentId: string;
  doc?: MoloDoc;
  onClose: () => void;
  clientId: number;
  processId: number;
  overrideShareType?: MoloShareType;
  /**
   * Depends on the message type we decide what message template should be used in respond.io.
   * Only two types are supported: "doc" and "verification" (verification - only for verification doc, doc - for all other docs)
   */
  messageType: ShareMessageType;
}

/**
 * Modal dialog to share document
 */
const ShareDialog = ({
  doc,
  agentId,
  onClose,
  clientId,
  processId,
  overrideShareType,
  messageType,
}: ShareDialogProps) => {
  const { mutate } = useMutationShare();
  const { makeFullUrl } = useUrl();
  const { enqueueSnackbar } = useSnackbar();
  const profile = useMyProfile();
  const isAdviser = useIsAdviser();
  const { data: client } = useQuery<ClientFull>({
    queryKey: [`/Ops/ClientDetail/${clientId}`],
    enabled: clientId > 0,
  });

  const handleShareViaWhatsapp = async (doc: MoloDoc, share: MoloShare) => {
    const shareUrl = makeFullUrl(`/s/${share.item.shortuid}`);
    if (isAdviser) {
      // Adviser should send whatsapp messages as usual (via own whatsapp app)
      window.open(
        `https://wa.me/?text=${encodeURIComponent(`${doc.metaProperties.title} ${shareUrl}`)}`,
        'share',
        'height=500,width=500',
      );
    } else {
      // Agents should send whatsapp messages via respond.io
      try {
        if (!client) {
          throw new Error('Client is not defined');
        }
        await respondIo.sendShareDocToClientMessage(client, shareUrl, messageType);
        enqueueSnackbar('The message was sent successfully', { variant: 'success' });
      } catch (error) {
        enqueueSnackbar((error as Error).message || DEFAULT_ERROR_MESSAGE, { variant: 'error' });
      }
    }
  };

  const processCreatedShare = (doc: MoloDoc, share: MoloShare, shareType: MoloShareType) => {
    switch (shareType) {
      case MoloShareType.whatsapp: {
        handleShareViaWhatsapp(doc, share);
        break;
      }
      case MoloShareType.email: {
        window.open(
          `mailto:?body=${encodeURIComponent(
            `${doc.metaProperties.title} ${makeFullUrl(`/s/${share.item.shortuid}`)}`,
          )}`,
          '_blank',
        );
        break;
      }
      case MoloShareType.other: {
        window.open(
          `sms:?&body=${encodeURIComponent(
            `${doc.metaProperties.title} ${makeFullUrl(`/s/${share.item.shortuid}`)}`,
          )}`,
          '_blank',
        );
        break;
      }
      case MoloShareType.link: {
        navigator.clipboard.writeText(makeFullUrl(`/s/${share.item.shortuid}`));
        enqueueSnackbar('Copied to clipboard', { variant: 'success' });
        break;
      }
    }
  };

  const handleShare = (shareType: MoloShareType) => {
    if (!doc) return;

    mutate(
      {
        docId: doc._id,
        docContentType: 'type' in doc ? doc.type?.code : undefined,
        docTitle: doc.metaProperties.title,
        docImage: doc.metaImage,
        shareType: overrideShareType || shareType,
        agentId: agentId,
        state: MoloShareState.authorized,
        processId,
        clientId,
        agentName: profile?.name ?? '',
      },
      {
        onSuccess: (response) => {
          processCreatedShare(doc, response, shareType);
        },
      },
    );
  };

  return (
    <Dialog open={Boolean(doc)} PaperProps={{ sx: { width: 1, maxWidth: 470 } }} onClose={onClose}>
      <Box sx={{ p: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h4">Send</Typography>
        <Button variant="outlined" color="gray" onClick={onClose}>
          Close
        </Button>
      </Box>
      <Divider />
      {doc && (
        <DialogContent>
          <Box sx={{ display: 'flex' }}>
            <Box>
              <img src={doc.metaImage} width={70} />
            </Box>
            <Box sx={{ ml: 2 }}>
              <Typography variant="h5" fontWeight={600}>
                {doc.metaProperties.title}
              </Typography>
              <Typography variant="subtitle2" color="text.secondary" lineHeight={1.1}>
                {doc.metaProperties.description}
              </Typography>
            </Box>
          </Box>
          {doc.metaProperties.suggestedSharingCopy && (
            <Box bgcolor="background.default" sx={{ p: 2, mt: 3 }}>
              <Typography variant="subtitle2">Suggested copy to use when sharing</Typography>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                lineHeight={1.1}
                sx={{ mt: 1 }}
              >
                {doc.metaProperties.suggestedSharingCopy}
              </Typography>
            </Box>
          )}
          <Divider sx={{ my: 3 }} />
          <Box>
            <ActionButton
              title="WhatsApp"
              icon={<Icon width={24} icon="mdi:whatsapp" />}
              onClick={() => handleShare(MoloShareType.whatsapp)}
            />
            {/* <ActionButton
              title="SMS"
              icon={<Icon width={24} icon="material-symbols:phone-enabled-outline" />}
              onClick={() => handleShare(MoloShareType.sms)}
            />
            <ActionButton
              title="Email"
              icon={<Icon width={24} icon="material-symbols:mail-outline-sharp" />}
              onClick={() => handleShare(MoloShareType.email)}
            /> */}
            <ActionButton
              title="Copy link"
              icon={<Icon width={24} icon="mdi:link" />}
              onClick={() => handleShare(MoloShareType.link)}
            />
          </Box>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default ShareDialog;
