export interface MoloShare {
  item: {
    id: string;
    token: string;
    assetuid: string;
    contenttitle: string;
    shareimageurl: string;
    contenttype: string;
    agentusername: string;
    processid: string;
    clientid: string;
    typeid: string;
    shortuid: string;
  };
}

export enum MoloShareType {
  other = 99,
  email = 5,
  whatsapp = 1,
  link = 8,
  contactForm = 9,
  preview = 13,
  verification = 14,
}

export enum MoloShareState {
  unauthorized = 1,
  authorized = 2,
}
