import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import ExportToolbar from '../common/components/exportToolbar';

const PROCESS_AGENT_CONTACT = 6;
const PROCESS_AGENT_SIGNED = 8;

interface ClientsTotal {
  id: number;
  totalClients: number;
  unallocated: number;
  unallocatedPercentage: number;
  allocatedToAdviser: number;
  allocatedToAdviserPercentage: number;
  allocatedToVirtualAdviser: number;
  allocatedToVirtualAdviserPercentage: number;
}

interface AllocatedAgent {
  allocatedAgentID: number;
  totalClients: number;
  contact: number;
  decision: number;
  signed: number;
  signRatio: number;
  allocatedAgentFullname: string;
}

interface ProcessAverage {
  id: number;
  processID: number;
  averageTimeInStep: string;
  processNote: string;
}

interface ProcessStats {
  id: number;
  processID: number;
  clientCount: number;
  closed: boolean;
  processNote: string;
}

const percentFormatter = ({ value }: { value: number }) => (value ? `${value}%` : '0%');

const statusColumns: GridColumns = [
  { field: 'id', headerName: 'Status', flex: 1 },
  { field: 'value', headerName: 'Count', flex: 1, type: 'number' },
];

const columns: GridColumns = [
  { field: 'allocatedAgentFullname', headerName: 'Agent', flex: 2 },
  { field: 'totalClients', headerName: 'Total Clients', flex: 1, type: 'number' },
  { field: 'contact', headerName: 'Contact', flex: 1, type: 'number' },
  { field: 'decision', headerName: 'Decision', flex: 1, type: 'number' },
  { field: 'signed', headerName: 'Signed', flex: 1, type: 'number' },
  {
    field: 'signRatio',
    headerName: 'Sign Ratio',
    flex: 1,
    type: 'number',
    valueFormatter: percentFormatter,
  },
];

const totalsColumns: GridColumns = [
  { field: 'totalClients', headerName: 'Total Clients', flex: 1, type: 'number' },
  { field: 'unallocated', headerName: 'Unallocated', flex: 1, type: 'number' },
  {
    field: 'unallocatedPercentage',
    headerName: 'Unallocated %',
    flex: 1,
    type: 'number',
    valueFormatter: percentFormatter,
  },
  { field: 'allocatedToAdviser', headerName: 'Allocated to Adviser', flex: 1, type: 'number' },
  {
    field: 'allocatedToAdviserPercentage',
    headerName: 'Allocated to Adviser %',
    flex: 1,
    type: 'number',
    valueFormatter: percentFormatter,
  },
  {
    field: 'allocatedToVirtualAdviser',
    headerName: 'Allocated to Virtual Adviser',
    flex: 1,
    type: 'number',
  },
  {
    field: 'allocatedToVirtualAdviserPercentage',
    headerName: 'Allocated to Virtual Adviser %',
    flex: 1,
    type: 'number',
    valueFormatter: percentFormatter,
  },
];

const columnsProcessAverage: GridColumns = [
  { field: 'processNote', headerName: 'Process', flex: 1 },
  { field: 'averageTimeInStep', headerName: 'Average Time in Step', flex: 1 },
];

/**
 * Renders the page with clients reports
 */
const ReportsPage = () => {
  const navigate = useNavigate();
  const { data: processStats } = useQuery<ProcessStats[]>({
    queryKey: ['/Ops/ClientStatusSummarys'],
  });
  const statusStats = processStats?.reduce(
    (accumulator, item) => {
      if (item.closed) {
        accumulator.closed += item.clientCount;
      }
      if (item.processID === PROCESS_AGENT_CONTACT && !item.closed) {
        accumulator.allocated += item.clientCount;
      }
      if (item.processID === PROCESS_AGENT_SIGNED && !item.closed) {
        accumulator.signed += item.clientCount;
      }

      return accumulator;
    },
    { closed: 0, allocated: 0, signed: 0 },
  );

  const { data: clientsTotal } = useQuery<ClientsTotal[]>({ queryKey: ['/Ops/ClientTotals'] });

  const { data: allocatedAgents } = useQuery<AllocatedAgent[]>({
    queryKey: ['/Ops/ClientTotalsAllocatedAgents'],
  });

  const rows = (allocatedAgents || []).map((allocatedAgent) => ({
    ...allocatedAgent,
    id: allocatedAgent.allocatedAgentID,
  }));

  // Avg time by process
  const { data: processAverageStats } = useQuery<ProcessAverage[]>({
    queryKey: ['/Ops/ClientProcessAverageTimes'],
  });

  return (
    <div>
      <DataGrid
        autoHeight
        rows={clientsTotal ?? []}
        components={{ Toolbar: ExportToolbar }}
        columns={totalsColumns}
        hideFooter
      />
      <DataGrid
        autoHeight
        rows={Object.entries(statusStats ?? {}).map(([key, value]) => ({ id: key, value }))}
        components={{ Toolbar: ExportToolbar }}
        columns={statusColumns}
        onRowClick={({ row }) => navigate(`/reports/${row.id}`)}
        hideFooter
      />
      <DataGrid
        sx={{ mt: 8 }}
        autoHeight
        hideFooter
        components={{ Toolbar: ExportToolbar }}
        rows={processAverageStats ?? []}
        getRowId={(row) => row.processID}
        columns={columnsProcessAverage}
        onRowClick={({ row }) => navigate(`/reports/process/${row.processID}`)}
      />
      <DataGrid
        autoHeight
        sx={{ mt: 8 }}
        rows={rows}
        hideFooter
        columns={columns}
        components={{ Toolbar: ExportToolbar }}
        onRowClick={({ row }) => navigate(`/reports/agents/${row.allocatedAgentID}`)}
      />
    </div>
  );
};

export default ReportsPage;
