import { Container, SxProps, Theme } from '@mui/material';
import { ReactNode } from 'react';

interface ContentProps {
  children: ReactNode;
  sx?: SxProps<Theme>;
}

const Content = ({ children, sx }: ContentProps) => (
  <Container maxWidth="lg" sx={sx}>
    {children}
  </Container>
);

export default Content;
