import {
  Box,
  Button,
  ButtonBase,
  Dialog,
  DialogContent,
  Divider,
  Skeleton,
  Typography,
} from '@mui/material';
import { MoloLeadAnswer, MoloLeadFull, MoloLeadQuestion } from '../../common/types/molo/moloLead';
import { MoloShareState, MoloShareType } from '../../common/types/molo/moloShare';
import { ReactNode, useMemo } from 'react';
import { useAppConfig } from '../../common/contexts/configContext';
import { useBrowserNavigate } from '../../common/hooks/useBrowserNavigate';
import { useMutationShare } from '../../common/hooks/useMutationShare';
import { useMyProfile } from '../../common/hooks/auth';
import { useQuery } from '@tanstack/react-query';
import { useQueryMoloDoc } from '../../common/hooks/useQueryMoloDocs';

interface AnswerProps {
  question: MoloLeadQuestion;
  answer?: MoloLeadAnswer;
}

const Answer = ({ question, answer }: AnswerProps) => {
  if (!answer) {
    return null;
  }
  if (answer.type === 'boolean') {
    return <Typography variant="subtitle2">{answer.boolean ? 'Yes' : 'No'}</Typography>;
  }
  if (answer.type === 'email') {
    return (
      <a href={`mailto:{answer.email}`}>
        <Typography variant="subtitle2">{answer.email}</Typography>
      </a>
    );
  }
  if (['choice', 'choices'].includes(answer.type)) {
    return (
      <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
        <Box>
          <Typography variant="subtitle2" fontWeight={600}>
            Options were:
          </Typography>
          <ul>
            {question.choices?.map(({ id, label }) => (
              <li key={id}>
                <Typography variant="subtitle2">{label}</Typography>
              </li>
            ))}
          </ul>
        </Box>
        <Box>
          <Typography variant="subtitle2" fontWeight={600}>
            Choice were:
          </Typography>
          <ul>
            {(answer.choices?.labels || [answer.choice?.label]).map((label) => (
              <li key={label}>
                <Typography variant="subtitle2">{label}</Typography>
              </li>
            ))}
          </ul>
        </Box>
      </Box>
    );
  }
  return <Typography variant="subtitle2">{answer[answer.type] || '-'}</Typography>;
};

interface LeadValueProps {
  label: string;
  value: ReactNode;
}

const LeadValue = ({ label, value }: LeadValueProps) => (
  <Box mt={2}>
    <Box bgcolor="#F3F4F5" p={1}>
      <Typography variant="subtitle2">{label}</Typography>
    </Box>
    <Box m={1}>{value}</Box>
    <Divider />
  </Box>
);

interface LeadDialogProps {
  leadId?: string | number | null;
  onClose: () => void;
}

/**
 * Modal dialog to display lead details
 */
const LeadDialog = ({ leadId, onClose }: LeadDialogProps) => {
  const { config } = useAppConfig();
  const profile = useMyProfile();
  const shareMutation = useMutationShare();
  const { openNewTab } = useBrowserNavigate();
  const { data: lead } = useQuery<MoloLeadFull>({
    queryKey: ['/Molo/Handler.Lead.Ext.ashx', { id: leadId }],
    enabled: Boolean(leadId),
  });

  const { data: docs } = useQueryMoloDoc(lead?.shareassetuid);
  const doc = useMemo(() => docs?.result[0], [docs]);

  // Collect answers to the questions
  const questions = (lead?.responsejson?.form_response?.definition?.fields || []).map((field) => {
    return {
      question: field,
      answer: lead?.responsejson?.form_response?.answers?.find(
        (answer) => answer.field.id === field.id,
      ),
    };
  });

  // Open lead document's preview page
  const handleClickPreview = () => {
    if (!lead || !profile) return;
    shareMutation.mutate(
      {
        docId: lead.shareassetuid,
        docContentType: lead.sharetypename,
        docImage: lead.shareimageurl,
        docTitle: lead.sharecontenttitle,
        shareType: MoloShareType.preview,
        agentId: profile.id,
        state: MoloShareState.authorized,
        processId: 0,
        clientId: 0,
        agentName: profile.name,
      },
      {
        onSuccess: (response) => {
          openNewTab(`/s/${response.item.shortuid}`);
        },
      },
    );
  };

  return (
    <Dialog
      open={Boolean(leadId)}
      onClose={onClose}
      sx={{ '& .MuiDialog-container': { justifyContent: 'flex-end' } }}
      PaperProps={{ sx: { width: 1, height: 1, maxHeight: 1, maxWidth: 740, m: 0 } }}
    >
      <Box sx={{ p: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h4">Client details</Typography>
        <Button variant="outlined" color="gray" onClick={onClose}>
          Close
        </Button>
      </Box>
      <Divider />
      <DialogContent>
        {lead ? (
          <>
            <Typography variant="h3" fontWeight={600} mb={2}>
              {lead.sharecontenttitle}
            </Typography>
            <Box height={325}>
              <img
                src={lead.shareimageurl}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Box>
            <Box
              sx={{
                boxShadow: 6,
                borderRadius: 2,
                p: 3,
                mt: -8,
                mx: 12,
                bgcolor: 'common.white',
                position: 'relative',
                zIndex: 2,
              }}
            >
              <Typography component="p" variant="caption" fontWeight={600}>
                Hi {lead.agentfirstname} {lead.agentsurname},
              </Typography>
              <Typography component="p" variant="caption" fontWeight={600} mt={2}>
                You have a new response from - {lead.sharecontenttitle} - You can{' '}
                <ButtonBase onClick={handleClickPreview}>
                  <Typography
                    variant="caption"
                    fontWeight={600}
                    color="primary.main"
                    sx={{ textDecoration: 'underline' }}
                  >
                    preview this here
                  </Typography>
                </ButtonBase>
                .
              </Typography>

              {doc?._type === 'letterForm' && (
                <Button
                  component="a"
                  target="_blank"
                  href={`${config.webUrl}/Letter/${lead?.token}`}
                  variant="contained"
                  sx={{ mt: 4 }}
                >
                  View signed pdf
                </Button>
              )}
            </Box>
            <Box
              sx={{
                boxShadow: 6,
                borderRadius: 2,
                p: 3,
                mx: 12,
                mt: 4,
                bgcolor: 'common.white',
              }}
            >
              <Box>
                <Typography variant="body2" color="text.secondary">
                  {lead.typenote}
                </Typography>
                <Typography variant="body2">{lead.createdate}</Typography>
                <Typography variant="body2" color="text.secondary" mt={2}>
                  Allocated to
                </Typography>
                <Typography variant="body2">
                  {lead.agentfirstname} {lead.agentsurname}
                </Typography>
              </Box>
            </Box>

            <Typography variant="h5" fontWeight={600} my={4}>
              Here are the questions and the person’s answers:
            </Typography>
            <LeadValue
              label="Submitted to mConnect (GMT)"
              value={
                <Typography variant="subtitle2">
                  {lead.responsejson?.form_response?.submitted_at}
                </Typography>
              }
            />
            {questions.map(({ question, answer }) => (
              <LeadValue
                key={question.id}
                label={question.title}
                value={<Answer question={question} answer={answer} />}
              />
            ))}
          </>
        ) : (
          <Box>
            <Skeleton />
            <Skeleton variant="rectangular" height={325} />
            {Array.from({ length: 10 }).map((_, index) => (
              <Skeleton key={index} variant="rectangular" height={100} sx={{ mt: 2 }} />
            ))}
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default LeadDialog;
