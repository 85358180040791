import 'remirror/styles/all.css';
import {
  BoldExtension,
  BulletListExtension,
  HardBreakExtension,
  HeadingExtension,
  ItalicExtension,
  LinkExtension,
  MarkdownExtension,
  OrderedListExtension,
} from 'remirror/extensions';
import { Box, IconButton, MenuItem, Select } from '@mui/material';
import {
  EditorComponent,
  ReactExtensions,
  ReactFrameworkOutput,
  Remirror,
  useActive,
  useChainedCommands,
  useCommands,
  useRemirror,
} from '@remirror/react';
import { forwardRef, useImperativeHandle } from 'react';
import { Icon } from '@iconify/react';

const extensions = () => [
  new BulletListExtension(),
  new OrderedListExtension(),
  new HeadingExtension(),
  new LinkExtension(),
  new HardBreakExtension(),
  new BoldExtension(),
  new ItalicExtension(),
  new HeadingExtension(),
  new MarkdownExtension(),
];

type Extensions = ReactExtensions<
  | OrderedListExtension
  | HeadingExtension
  | LinkExtension
  | BulletListExtension
  | HardBreakExtension
  | BoldExtension
  | ItalicExtension
  | HeadingExtension
  | MarkdownExtension
>;

export type RichTextEditorRef = ReactFrameworkOutput<Extensions> | undefined;

const Menu = () => {
  const chain = useChainedCommands();
  const active = useActive();
  const { toggleBold, toggleOrderedList, toggleBulletList, toggleItalic, toggleHeading } =
    useCommands();

  const handleToggleHeading = (level: number) => {
    if (level === 0) {
      chain.toggleHeading().focus().run();
    } else {
      chain.toggleHeading({ level }).focus().run();
    }
  };

  const currentActiveHeading =
    Array.from({ length: 5 }).findIndex((_, index) => active.heading({ level: index + 1 })) + 1;

  return (
    <Box>
      <Select
        value={currentActiveHeading}
        sx={{ width: 200, mr: 2 }}
        onChange={(event) => handleToggleHeading(event.target.value as number)}
      >
        <MenuItem value={0}>Normal text</MenuItem>
        <MenuItem disabled={!toggleHeading.enabled({ level: 1 })} value={1}>
          H1 - Heading 1
        </MenuItem>
        <MenuItem disabled={!toggleHeading.enabled({ level: 2 })} value={2}>
          H2 - Heading 2
        </MenuItem>
        <MenuItem disabled={!toggleHeading.enabled({ level: 3 })} value={3}>
          H3 - Heading 3
        </MenuItem>
        <MenuItem disabled={!toggleHeading.enabled({ level: 4 })} value={4}>
          H4 - Heading 4
        </MenuItem>
        <MenuItem disabled={!toggleHeading.enabled({ level: 5 })} value={5}>
          H5 - Heading 5
        </MenuItem>
      </Select>
      <IconButton
        onClick={() => chain.toggleBold().focus().run()}
        color={active.bold() ? 'primary' : 'default'}
        disabled={!toggleBold.enabled()}
        size="small"
      >
        <Icon width={24} icon="material-symbols:format-bold" />
      </IconButton>
      <IconButton
        onClick={() => chain.toggleItalic().focus().run()}
        color={active.italic() ? 'primary' : 'default'}
        disabled={!toggleItalic.enabled()}
        size="small"
      >
        <Icon width={24} icon="material-symbols:format-italic" />
      </IconButton>
      <IconButton
        onClick={() => chain.toggleBold().focus().run()}
        color={active.bold() ? 'primary' : 'default'}
        disabled={!toggleBold.enabled()}
        size="small"
      >
        <Icon width={24} icon="material-symbols:link" />
      </IconButton>
      <IconButton
        onClick={() => chain.toggleBulletList().focus().run()}
        color={active.bulletList() ? 'primary' : 'default'}
        disabled={!toggleBulletList.enabled()}
        size="small"
      >
        <Icon width={24} icon="material-symbols:format-list-bulleted" />
      </IconButton>
      <IconButton
        onClick={() => chain.toggleOrderedList().focus().run()}
        color={active.orderedList() ? 'primary' : 'default'}
        disabled={!toggleOrderedList.enabled()}
        size="small"
      >
        <Icon width={24} icon="material-symbols:format-list-numbered" />
      </IconButton>
    </Box>
  );
};

interface RichTextEditorProps {
  value: string;
  editable?: boolean;
  onChange?: (value: string) => void;
}

const RichTextEditor = forwardRef<RichTextEditorRef, RichTextEditorProps>(
  ({ value, onChange, editable = true }, ref) => {
    const { manager, state, getContext } = useRemirror({
      content: value,
      selection: 'start',
      stringHandler: 'markdown',
      extensions,
    });

    useImperativeHandle(ref, () => getContext(), [getContext]);

    return (
      <Box
        className="remirror-theme"
        sx={[
          !editable && {
            '&.remirror-theme .ProseMirror, &.remirror-theme .ProseMirror:focus': {
              boxShadow: 'none',
              minHeight: 'auto',
              border: 'none',
              padding: 0,
              overflow: 'visible',
              fontSize: 'inherit',
              lineHeight: 'inherit',
            },
            '&.remirror-theme .remirror-editor-wrapper': {
              paddingTop: 0,
            },
          },
          editable && {
            '&.remirror-theme .ProseMirror, &.remirror-theme .ProseMirror:focus': {
              boxShadow: '0px 1px 2px 0px #1018280D',
              border: '1px solid #D0D5DD',
              padding: '10px 14px',
              borderRadius: '8px',
              overflow: 'visible',
              fontSize: '14px',
              lineHeight: 1.4,
            },
            '&.remirror-theme .ProseMirror:focus': {
              borderColor: 'primary.main',
            },
          },
        ]}
      >
        <Remirror
          editable={editable}
          placeholder="e.g.. Contact this client after 5pm"
          manager={manager}
          initialContent={state}
          onChange={(event) => onChange?.(event.helpers.getMarkdown(event.state))}
        >
          {editable && (
            <>
              <Menu />
              <EditorComponent />
            </>
          )}
        </Remirror>
      </Box>
    );
  },
);

RichTextEditor.displayName = 'RichTextEditor';

export default RichTextEditor;
