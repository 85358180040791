import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { StepDialogProvider } from './common/contexts/stepDialogContext';
import { useAppConfig } from './common/contexts/configContext';
import { useEffect, useMemo } from 'react';
import { useMutationLoginOpsApi } from './common/hooks/auth';
import AgentReportPage from './reports/agentReportPage';
import AutomaticLoginPage from './login/automaticLoginPage';
import ClosedClientsReportPage from './reports/closedClientsReportPage';
import DashboardPage from './dashboard/dashboardPage';
import IdleTimeoutDialog from './common/components/idleTimeoutDialog';
import LoginPage from './login/loginPage';
import PageLoader from './common/components/pageLoader';
import PrivateRoute from './common/components/privateRoute';
import ProcessAdviserReportPage from './reports/processAdviserReportPage';
import ProcessAgentReportPage from './reports/processAgentReportPage';
import ProcessReportPage from './reports/processReportPage';
import ReportsPage from './reports/reportsPage';
import SharePage, { DeprecatedSharePage } from './docs/sharePage';

const App = () => {
  const appConfig = useAppConfig();
  const { mutate: doOpsApiAuth, isLoading: isLoadingOpsAuth } = useMutationLoginOpsApi();
  const privateRouter = useMemo(() => {
    const basename = appConfig?.config?.appUrl.replace(/^https?:\/\/[^/]+/, '');
    // eslint-disable-next-line no-console
    console.warn('App base url is:', basename);
    return createBrowserRouter(
      [
        {
          path: '/:username/dashboard',
          element: <PrivateRoute element={<DashboardPage />} />,
        },
        {
          path: '/reports',
          element: <PrivateRoute element={<ReportsPage />} />,
        },
        {
          path: '/reports/closed',
          element: <PrivateRoute element={<ClosedClientsReportPage />} />,
        },
        {
          path: '/reports/agents/:id',
          element: <PrivateRoute element={<AgentReportPage />} />,
        },
        {
          path: '/reports/process/:id',
          element: <PrivateRoute element={<ProcessReportPage />} />,
        },
        {
          path: '/reports/process/:id/advisers/:agentId',
          element: <PrivateRoute element={<ProcessAdviserReportPage />} />,
        },
        {
          path: '/reports/process/:id/agents/:agentId',
          element: <PrivateRoute element={<ProcessAgentReportPage />} />,
        },
        {
          path: '/:username',
          element: <AutomaticLoginPage />,
        },
        {
          path: '/login',
          element: <LoginPage />,
        },
        {
          path: '/share/:id',
          element: <DeprecatedSharePage />,
        },
        {
          path: '/s/:id',
          element: <SharePage />,
        },
        {
          path: '/agentauth/:username',
          element: <AutomaticLoginPage />,
        },
        { path: '*', element: <LoginPage /> },
      ],
      { basename },
    );
  }, [appConfig?.config?.appUrl]);

  useEffect(() => {
    doOpsApiAuth();
  }, [doOpsApiAuth]);

  if (!appConfig?.config || isLoadingOpsAuth) {
    return <PageLoader />;
  }

  return (
    <StepDialogProvider>
      <SnackbarProvider maxSnack={3}>
        <IdleTimeoutDialog />
        <RouterProvider router={privateRouter} />
      </SnackbarProvider>
    </StepDialogProvider>
  );
};

export default App;
