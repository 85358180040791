import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useMutationLogout } from '../hooks/auth';

const IDLE_TIMEOUT = 1000 * 60 * 10; // 10 minutes

/**
 * Simple implementation of idle timeout dialog (show dialog after 5 minutes of inactivity)
 */
const IdleTimeoutDialog = () => {
  const [idleDialogVisible, setIdleDialogVisible] = useState(false);
  const logoutMutation = useMutationLogout();

  const handleClose = () => {
    logoutMutation.mutate();
    setIdleDialogVisible(false);
  };

  useEffect(() => {
    let timeout = setTimeout(() => setIdleDialogVisible(true), IDLE_TIMEOUT);

    const documentClickHandler = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => setIdleDialogVisible(true), IDLE_TIMEOUT);
    };

    document.addEventListener('click', documentClickHandler);

    return () => {
      clearTimeout(timeout);
      document.removeEventListener('click', documentClickHandler);
    };
  }, []);

  return (
    <Dialog open={idleDialogVisible} onClose={handleClose}>
      <DialogTitle>Your session has expired</DialogTitle>
      <DialogContent>
        <DialogContentText>For your security, we are going to sign you out.</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IdleTimeoutDialog;
