interface AccessTokens {
  opsToken: string;
  moloToken: string;
}

const LS_TOKEN_KEY = 'at';
const LS_PROFILE_ID_KEY = 'pid';
let accessTokens: AccessTokens | undefined;
try {
  accessTokens = JSON.parse(localStorage.getItem(LS_TOKEN_KEY) as string);
} catch {
  accessTokens = undefined;
}

export const persistAuthTokens = (tokens: Partial<AccessTokens>) => {
  accessTokens = {
    ...accessTokens,
    ...(tokens as AccessTokens),
  };
  localStorage.setItem(LS_TOKEN_KEY, JSON.stringify(tokens));
};

export const getAuthToken = (key: keyof AccessTokens) => accessTokens?.[key];

export const removeAuthToken = () => {
  accessTokens = undefined;
  localStorage.removeItem(LS_TOKEN_KEY);
};

export const persistProfileId = (profileId?: string) =>
  profileId
    ? localStorage.setItem(LS_PROFILE_ID_KEY, profileId)
    : localStorage.removeItem(LS_PROFILE_ID_KEY);

export const getProfileId = () => localStorage.getItem(LS_PROFILE_ID_KEY);
