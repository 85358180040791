const intlDate = new Intl.DateTimeFormat('en-ZA', {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
});
const intlDateTime = new Intl.DateTimeFormat('en-ZA', {
  month: 'short',
  day: 'numeric',
  year: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
});

export const DAY_MS = 86_400_000;

export const formatDate = (date: Date | string): string => {
  return intlDate.format(typeof date === 'string' ? new Date(date) : date);
};

export const formatDateTime = (date: Date | string): string => {
  return intlDateTime.format(typeof date === 'string' ? new Date(date) : date);
};

export const calculateAge = (birthday: Date | string): number => {
  const birthdayDate = new Date(birthday);
  const ageDifMs = Date.now() - birthdayDate.getTime();
  const ageDate = new Date(ageDifMs);
  return Math.abs(ageDate.getUTCFullYear() - 1970);
};
