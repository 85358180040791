import { OptionModel } from './optionModel';

export type ClientProcess = OptionModel & { step: number };

export const PROCESS_NOT_STARTED_ID = 1;
export const PROCESS_CONTACT_ID = 4;
export const PROCESS_ALLOCATE_ID = 5;
export const PROCESS_AGENT_DECISION_ID = 7;
export const PROCESS_AGENT_CONTACT_ID = 6;
export const PROCESS_SIGNED_ID = 8;
export const PROCESS_VERIFY_ID = 3;

export const monitorFakeProcess: ClientProcess = {
  id: 0,
  step: 0,
  name: 'monitor',
  note: 'Monitoring',
};
