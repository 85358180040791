import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import ExportToolbar from '../common/components/exportToolbar';

interface ClientStats {
  id: number;
  clientID: number;
  processID: number;
  agentID: number;
  allocatedAgentID: number;
  timeInStep: string;
  processNote: string;
  agentFullname: string;
  allocatedAgentFullname: string;
  clientFullname: string;
}

const columns: GridColumns = [
  { field: 'clientFullname', headerName: 'Client', flex: 1 },
  { field: 'timeInStep', headerName: 'Time In Step', flex: 1 },
];

const ProcessAdviserReportPage = () => {
  const { id, agentId } = useParams();
  const { data: clients } = useQuery<ClientStats[]>({
    queryKey: ['/Ops/ClientProcessSummarys', { ProcessID: id, AgentID: agentId }],
  });

  return (
    <DataGrid
      autoHeight
      components={{ Toolbar: ExportToolbar }}
      rows={clients ?? []}
      columns={columns}
    />
  );
};

export default ProcessAdviserReportPage;
