import { Box, Button, Tab, Tabs, Typography } from '@mui/material';
import { ClientFull, ClientVerification } from '../../common/types/client';
import { Icon } from '@iconify/react';
import { MoloDoc } from '../../common/types/molo/moloDoc';
import { MoloShare } from '../../common/types/molo/moloShare';
import { PortableText } from '@portabletext/react';
import { QRCodeSVG } from 'qrcode.react';
import { useAppConfig } from '../../common/contexts/configContext';
import { useState } from 'react';

interface VerificationDocProps {
  share: MoloShare;
  doc: MoloDoc;
  client: ClientFull;
}
/**
 * Client verification document
 */
const VerificationDoc = ({ doc, client, share }: VerificationDocProps) => {
  const { config } = useAppConfig();
  const [currentTab, setCurrentTab] = useState(0);
  const statusDescription =
    client?.verifyStatusID === ClientVerification.verified
      ? 'There’s nothing left for you to do. Thank you!'
      : client?.verifyStatusID === ClientVerification.notstarted
      ? 'Its online, secure and much easier than printing, filling in and scanning documents.'
      : client?.verifyStatusID === ClientVerification.serverError
      ? 'When you’re ready, please try to verify your ID number again.'
      : 'We need to call you to check a few things about the information you sent through.';

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box
        sx={{ display: 'flex', p: 3, flexDirection: { xs: 'column', sm: 'row' }, maxWidth: 685 }}
      >
        <Box sx={{ width: { xs: 1, sm: 0.3 }, maxWidth: { sm: 220 }, flexShrink: 0 }}>
          <img src={doc?.metaImage} width="100%" alt="" />
        </Box>
        <Box ml={{ xs: 0, md: 4 }} flexGrow={1}>
          <Typography variant="h3" fontWeight={700} color="primary.main">
            {doc?.metaProperties.title}
          </Typography>
          {doc?.content && (
            <Box sx={{ mt: -1 }}>
              <PortableText value={doc.content} />
            </Box>
          )}
        </Box>
      </Box>

      <Box
        bgcolor="background.default"
        sx={{
          p: 4,
          mb: 10,
          width: '100%',
          maxWidth: 530,
          borderRadius: 2,
          display: 'grid',
          placeItems: 'center',
          gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
          gap: 2,
          border: 1,
          borderColor: 'common.borderGray',
        }}
      >
        <Button
          disabled={
            client.verifyStatusID !== ClientVerification.notstarted &&
            client.verifyStatusID !== ClientVerification.serverError
          }
          variant="outlined"
          component="a"
          href={`${config.rootUrl}/Trusty/${share.item.token}`}
          target="_top"
          color="secondary"
          sx={{
            lineHeight: 1,
            width: '100%',
            fontSize: 22,
            padding: '15px',
            bgcolor: 'common.white',
            borderColor: 'common.borderGray',
            color: 'primary.main',
            '&.Mui-disabled': {
              borderColor: 'transparent',
              bgcolor: 'transparent',
              color: 'primary.main',
            },
          }}
          startIcon={
            <Box
              sx={{
                color: 'common.white',
                bgcolor:
                  client.verifyStatusID === ClientVerification.verified
                    ? 'success.main'
                    : client.verifyStatusID === ClientVerification.notstarted ||
                      client.verifyStatusID === ClientVerification.serverError
                    ? 'secondary.main'
                    : 'primary.main',
                borderRadius: 100,
                width: 24,
                height: 24,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Icon
                icon={
                  client.verifyStatusID === ClientVerification.verified
                    ? 'material-symbols:check-small'
                    : client.verifyStatusID === ClientVerification.notstarted ||
                      client.verifyStatusID === ClientVerification.serverError
                    ? 'mdi:chevron-right'
                    : 'uil:info'
                }
              />
            </Box>
          }
        >
          <Box sx={{ ml: 1 }}>
            <Box>
              {client.verifyStatusID === ClientVerification.verified
                ? 'You are verified!'
                : client.verifyStatusID === ClientVerification.notstarted
                ? 'Get started'
                : client.verifyStatusID === ClientVerification.serverError
                ? 'Try again'
                : 'We’ll be in touch'}
            </Box>
            <Typography color="text.darkGray" variant="body2">
              {client.verifyStatusID === ClientVerification.verified
                ? ''
                : client.verifyStatusID === ClientVerification.notstarted
                ? 'It takes 2 mins!'
                : client.verifyStatusID === ClientVerification.serverError
                ? 'There was a problem'
                : 'Thank you'}
            </Typography>
          </Box>
        </Button>
        <Typography variant="body2" color="text.darkGray">
          {statusDescription}
        </Typography>
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          borderBottom: 1,
          borderBottomColor: 'common.borderGray',
        }}
      >
        <Tabs value={currentTab} onChange={(_, newValue: number) => setCurrentTab(newValue)}>
          <Tab label="Why?" />
          <Tab label="How it works" />
          <Tab label="On your computer?" />
        </Tabs>
      </Box>
      <Box bgcolor="background.default" sx={{ p: { xs: 2, md: 8 }, width: '100%' }}>
        {currentTab === 0 && doc?.instructionContent && (
          <PortableText value={doc.instructionContent} />
        )}
        {currentTab === 1 && doc?.howItWorksContent && (
          <Box
            sx={{
              '& h1': {
                fontSize: 28,
                color: 'primary.main',
                fontWeight: 700,
                mt: 6,
                mb: 0,
              },

              '& h3': {
                fontSize: 22,
                color: 'text.darkGray',
                fontWeight: 500,
                mb: 0,
                mt: 4,
              },
              '& p': {
                mt: 1,
              },
            }}
          >
            <PortableText value={doc.howItWorksContent} />
          </Box>
        )}
        {currentTab === 2 && (
          <Box sx={{ maxWidth: 520, mx: 'auto' }}>
            {doc?.verifyContent && <PortableText value={doc.verifyContent} />}
            <Box mt={4} display="flex" justifyContent="center">
              <QRCodeSVG value={`${config.rootUrl}/Trusty/${share.item.token}`} />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default VerificationDoc;
