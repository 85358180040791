import { Agent } from '../types/agent';
import { ClientFull } from '../types/client';
import axios from 'axios';
import trimStart from 'lodash/trimStart';

export type ShareMessageType = 'doc' | 'verification';

interface RespondIoContactPayload {
  phone: string;
  email?: string | null;
  firstName: string;
  lastName: string;
}

const respondIoClient = axios.create({
  baseURL: 'https://api.respond.io/v2',
  headers: {
    Authorization:
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTY2NCwic3BhY2VJZCI6MTA1Mjk3LCJvcmdJZCI6MTA4Nzc3LCJ0eXBlIjoiYXBpIiwiaWF0IjoxNjgxMjE3Njg3fQ.G79lccEqGTCZYCQNMsjs1-_BoVRLsjc2r9pqlkzZkHg',
  },
});

/**
 * Generate whatsapp template payload for share doc to client
 */
export const shareDocToClientTemplate = (shareUrl: string) => ({
  channelId: 164_591,
  message: {
    type: 'whatsapp_template',
    template: {
      name: 'mfp_ops_outboundshare_v1',
      languageCode: 'en',
      components: [
        {
          type: 'body',
          text: 'Hi {{1}}, I have an important document to share regarding your Momentum Policy. You can read it here ({{2}}). Please contact our team with any questions or comments.',
          parameters: [
            { type: 'text', text: '{{$contact.firstname}} ' },
            {
              type: 'text',
              text: shareUrl,
            },
          ],
        },
      ],
    },
  },
});

/**
 * Generate whatsapp template payload for share verification doc to client
 */
export const shareVerificationToClientTemplate = (shareUrl: string) => ({
  channelId: 164_591,
  message: {
    type: 'whatsapp_template',
    template: {
      name: 'mfp_ops_verifyid',
      languageCode: 'en',
      components: [
        {
          type: 'body',
          text: `Before we can discuss your policy details with you over phone, email or WhatsApp, we need to match you to your ID number.\n\nWe have made this really easy and you can do it all online. If everything verifies, it might only take a few minutes.\n\nUse this link ({{1}}) to get started.`,
          parameters: [
            {
              type: 'text',
              text: shareUrl,
            },
          ],
        },
      ],
    },
  },
});

/**
 * Generate whatsapp template payload for adviser "client allocated" event
 */
export const allocateClientToAdviserTemplate = (dashboardUrl: string) => ({
  channelId: 164_591,
  message: {
    type: 'whatsapp_template',
    template: {
      name: 'mfp_ops_notifyallocation',
      languageCode: 'en',
      components: [
        {
          type: 'body',
          text: 'Hi {{1}}, you have been allocated a new client to reintermediate on mConnect. You can check their details on your mConnect Reintermediations dashboard ({{2}}).',
          parameters: [
            { type: 'text', text: '{{$contact.firstname}} ' },
            {
              type: 'text',
              text: dashboardUrl,
            },
          ],
        },
      ],
    },
  },
});

const respondIo = {
  /**
   * Sending whatsapp share template to the client via respond.io
   */
  async sendWhatsappTemplate(contactId: string, requestPayload: unknown) {
    return respondIoClient.post(`/contact/id:${contactId}/message`, requestPayload);
  },

  /**
   * Get client contact id from respond.io
   */
  async getContactId(payload: RespondIoContactPayload) {
    const apiEndpoint = `/contact/${this.getContactContactIdentifier(payload.phone)}`;
    try {
      // get contact id if exists
      const { data } = await respondIoClient.get(apiEndpoint);
      return data.id;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response?.status !== 404) {
        throw error;
      }
      // create a new contact if not found
      await respondIoClient.post(apiEndpoint, {
        ...payload,
        language: 'en',
        countryCode: 'ZA',
        phone: this.getInternationalPhoneNumber(payload.phone),
      });
      // return the newly created contact id
      const { data } = await respondIoClient.get(apiEndpoint);
      return data.id;
    }
  },

  /**
   * Get contact identifier for respond.io (international phone number)
   */
  getContactContactIdentifier(phone: string) {
    return `phone:${this.getInternationalPhoneNumber(phone)}`;
  },

  /**
   * Get international phone number
   */
  getInternationalPhoneNumber(phone: string) {
    const normalizePhone = phone.trim();
    if (normalizePhone.startsWith('+')) {
      return normalizePhone;
    } else if (normalizePhone.startsWith('27')) {
      return `+${normalizePhone}`;
    }
    return `+27${trimStart(normalizePhone, '0')}`;
  },

  /**
   * Send whatsapp message to the client with the share url
   */
  async sendShareDocToClientMessage(
    client: ClientFull,
    shareUrl: string,
    messageType: ShareMessageType,
  ) {
    const phone = client.phones?.find(({ typeName }) => typeName === 'mobile')?.number;
    if (!phone) {
      throw new Error('Client phone is required');
    }
    const contactId = await this.getContactId({
      phone,
      email: client.email,
      firstName: client.firstname,
      lastName: client.surname,
    });

    if (messageType === 'verification') {
      return this.sendWhatsappTemplate(contactId, shareVerificationToClientTemplate(shareUrl));
    }

    return this.sendWhatsappTemplate(contactId, shareDocToClientTemplate(shareUrl));
  },

  /**
   * Send whatsapp message to the adviser with "allocated client" event
   */
  async sendAllocatedMessageToTheAdviser(adviser: Agent, dashboardUrl: string) {
    const phone = adviser.whatsapp || adviser.phone;
    if (!phone) {
      throw new Error('Adviser phone is required');
    }
    const contactId = await this.getContactId({
      phone,
      email: adviser.email,
      firstName: adviser.firstname,
      lastName: adviser.surname,
    });
    return this.sendWhatsappTemplate(contactId, allocateClientToAdviserTemplate(dashboardUrl));
  },
};

export default respondIo;
