import { Box, Dialog, DialogProps, TextField, Typography } from '@mui/material';
import { ClientFull, NoteType } from '../../../common/types/client';
import { Controller, useForm } from 'react-hook-form';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SAVED_MESSAGE,
} from '../../../common/contexts/configContext';
import { LoadingButton } from '@mui/lab';
import { useCreateClientNoteMutation } from '../../../ops/mutations';
import { useRef } from 'react';
import { useSnackbar } from 'notistack';
import Content from '../../../common/components/context';
import DialogHeader from '../../../ops/components/dialogHeader';
import RichTextEditor, { RichTextEditorRef } from '../../../common/components/richTextEditor';

interface AddNoteDialogProps extends DialogProps {
  client: ClientFull;
}

const AddNoteDialog = ({ client, ...props }: AddNoteDialogProps) => {
  const editorRef = useRef<RichTextEditorRef>();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useCreateClientNoteMutation();
  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      typeID: NoteType.comment,
      ClientID: client.id,
      Action: '',
      Comment: '',
    },
  });

  const onSubmit = (data: unknown) => {
    mutation.mutate(data, {
      onSuccess: () => {
        reset();
        props.onClose?.({}, 'escapeKeyDown');
        editorRef.current?.setContent({ type: 'doc', content: [] });
        enqueueSnackbar(DEFAULT_SAVED_MESSAGE, { variant: 'success' });
      },
      onError: (error) =>
        enqueueSnackbar((error as Error).message || DEFAULT_ERROR_MESSAGE, { variant: 'error' }),
    });
  };

  return (
    <Dialog
      sx={{ '& .MuiDialog-container': { justifyContent: 'flex-end' } }}
      PaperProps={{ sx: { width: 1, height: 1, maxHeight: 1, maxWidth: 600, m: 0 } }}
      {...props}
    >
      <DialogHeader
        onClose={(event) => props.onClose?.(event, 'escapeKeyDown')}
        title={`${client.fullname} - Add comment`}
      />
      <Content sx={{ py: 4 }}>
        <Typography fontWeight={500} sx={{ mb: 2 }}>
          Add new comment
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="Action"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                sx={{ minWidth: 300 }}
                fullWidth
                disabled={mutation.isLoading}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                label="Title *"
              />
            )}
          />
          <Box sx={{ mt: 4, mb: 2 }}>
            <Controller
              name="Comment"
              control={control}
              render={({ field }) => (
                <RichTextEditor ref={editorRef} value={field.value} onChange={field.onChange} />
              )}
            />
          </Box>
          <LoadingButton loading={mutation.isLoading} type="submit" variant="contained">
            Save
          </LoadingButton>
        </form>
      </Content>
    </Dialog>
  );
};

export default AddNoteDialog;
