import { FormControl, Input, InputLabel, TextFieldProps } from '@mui/material';
import { PatternFormat } from 'react-number-format';

export const dateFieldPattern = /^(\d{4})\/(\d{2})\/(\d{2})$/;

interface DateTextFieldProps extends Omit<TextFieldProps, 'onChange'> {
  onChange: (value: string) => void;
}

/**
 * A text field for entering dates.
 */
const DateTextField = ({ label, error, value, onChange }: DateTextFieldProps): JSX.Element => (
  <FormControl fullWidth error={error} sx={{ '& .MuiInput-root': { marginTop: '24px' } }}>
    <InputLabel shrink>{label}</InputLabel>
    <PatternFormat
      format="####/##/##"
      mask={['Y', 'Y', 'Y', 'Y', 'M', 'M', 'D', 'D']}
      customInput={Input}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      value={value as any}
      allowEmptyFormatting
      onValueChange={({ formattedValue }): void => {
        onChange(formattedValue);
      }}
    />
  </FormControl>
);

export default DateTextField;
