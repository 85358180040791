import { createContext, ReactNode, useCallback, useContext, useState } from 'react';

type CloseCallback = () => Promise<boolean | undefined | void>;

export type StepDialogApi = {
  /**
   * Add close listener
   * @param key Unique key of the listener, needed to avoid the same listener being added multiple times
   * @param callback Close event callback
   */
  addCloseCallback: (key: string, callback: CloseCallback) => void;
  /**
   * Remove close listener
   * @param key Unique key of the listener
   */
  removeCloseCallback: (key: string) => void;
  /**
   * Run all close listeners
   * @returns true if all listeners returned true, false if at least one listener returned false
   */
  performCloseCallbacks: () => Promise<boolean>;
};

const StepDialogContext = createContext<StepDialogApi | null>(null);

export const StepDialogProvider = ({ children }: { children: ReactNode }) => {
  const [closeCallbacks, setCloseCallbacks] = useState<Record<string, CloseCallback>>({});

  const addCloseCallback = useCallback((key: string, callback) => {
    setCloseCallbacks((value) => ({ ...value, [key]: callback }));
  }, []);

  const removeCloseCallback = useCallback((key: string) => {
    setCloseCallbacks((value) => {
      const newValue = { ...value };
      delete newValue[key];
      return newValue;
    });
  }, []);

  const performCloseCallbacks = useCallback(async () => {
    const callbacks = Object.values(closeCallbacks);
    const result = await Promise.all(callbacks.map((callback) => callback()));
    return !result.includes(false);
  }, [closeCallbacks]);

  return (
    <StepDialogContext.Provider
      value={{ addCloseCallback, removeCloseCallback, performCloseCallbacks }}
    >
      {children}
    </StepDialogContext.Provider>
  );
};

/**
 * Because sending forms, etc. can come from outside the form itself (for example, when you close a dialog for some reason).
 * We need a context to handle such events. For this, we created a kind of event emitter.
 */
export const useStepDialogApi = () => {
  return useContext(StepDialogContext) as StepDialogApi;
};
