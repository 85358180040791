import { Box, Tab, Tabs } from '@mui/material';
import { StepContentProps } from '../../../../common/components/stepDialog';
import { useState } from 'react';
import EficaForm from './eficaForm';
import Instructions from '../../../../common/components/instructions';

const EficaDialog = ({ client, onFormLoadingStateChange, process }: StepContentProps) => {
  const [currentTab, setCurrentTab] = useState(0);
  return (
    <>
      <Tabs
        sx={{ borderBottom: 1, borderColor: '#DDDEE5' }}
        value={currentTab}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        onChange={(_, value) => setCurrentTab(value)}
      >
        <Tab label="Guide" />
        <Tab label="efica" />
      </Tabs>
      <Box sx={{ mt: 6 }}>
        <Box sx={{ display: currentTab === 0 ? 'block' : 'none' }}>
          <Instructions keyword="efica" />
        </Box>
        <Box sx={{ display: currentTab === 1 ? 'block' : 'none' }}>
          <EficaForm
            onFormLoadingStateChange={onFormLoadingStateChange}
            process={process}
            client={client}
          />
        </Box>
      </Box>
    </>
  );
};

export default EficaDialog;
