import { MoloDoc, MoloDocs } from '../types/molo/moloDoc';
import { useQuery } from '@tanstack/react-query';

export const useQueryMoloDocs = (docIds?: string[], keyword?: string) =>
  useQuery<MoloDocs>({
    queryKey: [
      '/Molo/Handler.Sanity.ashx',
      {
        query: `
        *[_id in ${JSON.stringify(docIds)} ${
          keyword ? `&& "${keyword}" in metaProperties.keywords` : ''
        }]{
          ...,
          'audienceCategories':categories.audienceCategories[]->,
          'contentTypeCategories':categories.contentTypeCategories[]->,
          'needsCategories':categories.needsCategories[]->,
          'serviceCategories':categories.serviceCategories[]->,
          author->,
          'metaImage': metaProperties.image.asset->url,
          'type': contentTypeCategories->{'name': title, 'id': _id, 'code': shortcode},
          }
        `,
      },
      'postForm',
    ],
    enabled: Boolean(docIds),
  });

export const useQueryMoloDoc = (docId?: string) =>
  useQuery<MoloDocs>({
    queryKey: [
      '/Molo/Handler.Sanity.ashx',
      {
        query: `
          *[_id == "${docId}"]{
            ...,
            'audienceCategories':categories.audienceCategories[]->,
            'contentTypeCategories':categories.contentTypeCategories[]->,
            'needsCategories':categories.needsCategories[]->,
            'serviceCategories':categories.serviceCategories[]->,
            author->,
            'metaImage': metaProperties.image.asset->url,
            'type': contentTypeCategories->{'name': title, 'id': _id, 'code': shortcode},
            'contentType': {
              ...contentType,
              'audioFile': contentType.audioFile.asset->,
              'videoFile': contentType.videoFile.asset->,
              'pdfFile': contentType.pdfFile.asset->,
              'url': contentType.url.asset->
            }
          }
        `,
      },
      'postForm',
    ],
    enabled: Boolean(docId),
  });

/**
 * Fetch first verification doc from sanity.
 * Used for client verification page.
 */
export const useQueryVerifyDoc = (): MoloDoc | undefined => {
  const { data } = useQuery<{
    result: MoloDoc[];
  }>({
    queryKey: [
      '/Molo/Handler.Sanity.ashx',
      {
        query: `*[_type == "verification"]{
          ...,
          'metaImage': metaProperties.image.asset->url,
        }`,
      },
      'postForm',
    ],
  });

  return data?.result?.[0];
};
