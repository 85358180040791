import { Box, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { DEFAULT_ERROR_MESSAGE } from '../common/contexts/configContext';
import { getAuthProfile, useMutationLogin, useMutationLoginOpsApi } from '../common/hooks/auth';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import Content from '../common/components/context';
import Layout from '../common/components/layout';

const LoginPage = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useMutationLogin();
  const [showPassword, setShowPassword] = useState(false);
  const opsApiAuthMutation = useMutationLoginOpsApi();
  const { handleSubmit, control } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const onSubmit = async (data: { username: string; password: string }) => {
    try {
      // Keep this order of mutations
      await opsApiAuthMutation.mutateAsync();
      const response = await mutation.mutateAsync(data);
      const profiles = response.authData?.Profiles.item;
      const profile = profiles ? getAuthProfile(profiles) : null;
      if (profile) {
        navigate(`/${profile.username}/dashboard`);
      }
    } catch (error) {
      enqueueSnackbar((error as Error).message || DEFAULT_ERROR_MESSAGE, { variant: 'error' });
    }
  };

  return (
    <Layout>
      <Content
        sx={{
          py: 4,
          flexGrow: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Typography variant="h3" fontWeight={500} color="primary.main">
          Hello MFP operations!
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1, mb: 6 }}>
          Please login
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="username"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                sx={{
                  mb: 4,
                  '&.MuiFormControl-root.MuiTextField-root .MuiOutlinedInput-root .MuiInputBase-input':
                    {
                      py: 4,
                    },
                  '& .MuiFormLabel-root': {
                    fontSize: 14,
                  },
                }}
                disabled={mutation.isLoading}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                label="VIA or Momentum.co.za username"
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            rules={{ required: true }}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                fullWidth
                sx={{
                  mb: 4,
                  '&.MuiFormControl-root.MuiTextField-root .MuiOutlinedInput-root .MuiInputBase-input':
                    {
                      py: 4,
                    },
                  '& .MuiFormLabel-root': {
                    fontSize: 14,
                  },
                }}
                disabled={mutation.isLoading}
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                label="Password"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={(): void => setShowPassword((previous) => !previous)}
                        edge="end"
                        size="small"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
          <LoadingButton
            loading={mutation.isLoading || opsApiAuthMutation.isLoading}
            type="submit"
            variant="contained"
            color="secondary"
          >
            Login
          </LoadingButton>
        </form>
        <Typography variant="subtitle2" sx={{ mt: 4 }}>
          Forgot your password?:{' '}
          <Box
            component="a"
            color="text.primary"
            href="https://via.momentum.co.za/for/Home/sign-in-assistance/#/forgot-password"
          >
            Staff
          </Box>{' '}
          |{' '}
          <Box
            component="a"
            color="text.primary"
            href="https://login.momentum.co.za/auth/realms/momentum/login-actions/reset-credentials"
          >
            Advisers
          </Box>
        </Typography>
      </Content>
    </Layout>
  );
};

export default LoginPage;
