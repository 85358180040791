import { Box, Stack, Tab, Tabs, TextField, Typography } from '@mui/material';
import { GridFilterModel } from '@mui/x-data-grid';
import { mainHeaderStyle, tabsStyle } from '../ops/opsPage';
import { useEffect, useState } from 'react';
import { useMyProfile } from '../common/hooks/auth';
import AdvisersDashboard from './advisersDashboard';
import ClientsInProgress from '../clients/components/clientsInProgress';
import Content from '../common/components/context';
import Layout from '../common/components/layout';

const AdvisersPage = () => {
  const profile = useMyProfile();
  const [currentTab, setCurrentTab] = useState(0);
  const [filterModel, setFilterModel] = useState<GridFilterModel>();

  const handleFilterClients = (query: string) => {
    setFilterModel({
      items: [],
      quickFilterValues: [query],
    });
  };

  // Display Respond.io widget for advisers
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'respondio__growth_tool';
    script.src = 'https://cdn.respond.io/widget/widget.js?wId=b2d90b12-1962-48c0-8991-53380dd662fd';
    script.async = true;
    document.body.append(script);
  }, []);

  if (!profile) return null;

  return (
    <Layout>
      <Content sx={mainHeaderStyle}>
        <Box
          height={52}
          marginY={2}
          ml={-1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="h3" fontWeight={400} color="secondary.main">
            Reintermediations
          </Typography>
          {currentTab !== 0 && (
            <TextField
              onChange={(event) => handleFilterClients(event.target.value)}
              placeholder="Filter clients..."
            />
          )}
        </Box>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Tabs
            sx={tabsStyle}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            value={currentTab}
            onChange={(_, newValue: number) => setCurrentTab(newValue)}
          >
            <Tab label="Dashboard" />
            <Tab label="In progress" />
            <Tab label="Closed" />
            <Tab label="Signed" />
          </Tabs>
        </Stack>
      </Content>
      <Box bgcolor="common.white" sx={{ flexGrow: 1 }} paddingY={4}>
        <Content>
          {currentTab === 0 && <AdvisersDashboard onChangeTab={setCurrentTab} />}
          {currentTab === 1 && (
            <ClientsInProgress
              filterModel={filterModel}
              apiQueryFilters={{ isAllocated: 1, allocatedAgentId: profile.id }}
            />
          )}
          {currentTab === 3 && (
            <ClientsInProgress
              filterModel={filterModel}
              apiQueryFilters={{ isAllocated: 1, isSigned: 1, allocatedAgentId: profile.id }}
            />
          )}
          {currentTab === 2 && (
            <ClientsInProgress
              filterModel={filterModel}
              apiQueryFilters={{
                isClosed: 1,
                isAllocated: 1,
                allocatedAgentId: profile.id,
              }}
            />
          )}
        </Content>
      </Box>
    </Layout>
  );
};

export default AdvisersPage;
