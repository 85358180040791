import {
  Box,
  Button,
  Dialog,
  DialogProps,
  List,
  ListItem,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { ClientFull } from '../../../common/types/client';
import { tabsStyle } from '../../../ops/opsPage';
import { useState } from 'react';
import AdviserDetailsDialog from '../adviserDialog';
import Content from '../../../common/components/context';
import DialogHeader from '../../../ops/components/dialogHeader';

interface PropertyPolicyProps {
  title: string;
  value?: string | number;
}
const listItemStyles = {
  display: 'list-item',
  color: 'text.darkGray',
  p: 1,
  ml: 5,
};
const PropertyPolicy = ({ title, value }: PropertyPolicyProps) => (
  <Box sx={{ display: 'flex', mb: 2 }}>
    <Typography variant="body2" color="text.secondary" sx={{ mr: 3 }}>
      {title}
    </Typography>
    <Typography variant="body2">{value || '-'}</Typography>
  </Box>
);

interface PropertyContactProps {
  title: string;
  value?: string[] | string;
}

const PropertyContact = ({ title, value }: PropertyContactProps) =>
  value?.length ? (
    <Box sx={{ display: 'flex', mb: 2 }}>
      <Typography variant="body2" color="text.secondary" sx={{ mr: 3 }}>
        {title}
      </Typography>
      <Box>
        {(Array.isArray(value) ? value : [value]).map((item, index) => (
          <Typography sx={{ mb: 1 }} variant="body2" key={`${item}_${index}`}>
            {item || '-'}
          </Typography>
        ))}
      </Box>
    </Box>
  ) : null;

interface InfoDialogProps extends DialogProps {
  client: ClientFull;
}

type OpenDialogState = 'adviser' | 'previousAdviser' | null;

const InfoDialog = ({ client, ...props }: InfoDialogProps) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [openedDialog, setOpenedDialog] = useState<OpenDialogState>(null);
  const handleCloseSubDialog = () => setOpenedDialog(null);
  return (
    <Dialog fullScreen {...props}>
      <DialogHeader
        onClose={(event) => props.onClose?.(event, 'escapeKeyDown')}
        title={`${client.fullname} - Info`}
      />
      <Content sx={{ py: 4 }}>
        <Tabs
          sx={tabsStyle}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          value={currentTab}
          onChange={(_, newValue: number) => setCurrentTab(newValue)}
        >
          <Tab label="Contact" />
          <Tab label="Policy" />
          <Tab label="Own adviser" />
          <Tab label="Previous adviser" />
        </Tabs>
        {currentTab === 0 && (
          <Box sx={{ mt: 4 }}>
            <PropertyContact title="Call:" value={client.phones.map(({ number }) => number)} />
            <PropertyContact title="Email:" value={client.emails.map(({ address }) => address)} />
            <PropertyContact title="ID Number:" value={client.idNumber} />
          </Box>
        )}
        {currentTab === 1 && (
          <>
            {client.policies.map((policy) => (
              <Box key={policy.id} sx={{ mt: 4 }}>
                <PropertyPolicy title="Policy number" value={policy.number} />
                <PropertyPolicy title="Monthly premium" value={policy.premium} />
                <PropertyPolicy title="Sum assured" value={policy.sumAssured} />
                <PropertyPolicy title="Investment value" value={policy.investoValue} />
                <PropertyPolicy title="Wealth value" value={policy.wealthValue} />
                <PropertyPolicy title="Ongoing adviser fees" value={policy.adviserFeeOngoing} />
                <PropertyPolicy title="Distribution fees" value={policy.adviserFeeDistribution} />
                <PropertyPolicy title="Total ongoing fees" value={policy.adviserFeeTotal} />
                <PropertyPolicy title="Notes" value={policy.note} />
              </Box>
            ))}
          </>
        )}
        {currentTab === 2 && (
          <Box mt={4}>
            <Box color="text.darkGray" mb={3}>
              <Typography sx={{ fontSize: 14, mb: 2 }}>
                This information is used to prepopulate letters and other communication you send out
                through this platform. Please add this information before you send anything out,
                otherwise some communication might be blank.
              </Typography>
            </Box>
            <PropertyPolicy
              title="Own financial adviser's name"
              value={client.adviserName || 'none'}
            />
            <PropertyPolicy title="Contact number" value={client.adviserPhone || 'none'} />
            <PropertyPolicy title="Email" value={client.adviserEmail || 'none'} />
            <Button onClick={() => setOpenedDialog('adviser')} variant="contained" color="primary">
              Update
            </Button>
          </Box>
        )}
        {currentTab === 3 && (
          <Box mt={3}>
            <List sx={{ listStyleType: 'disc', mb: 2, fontSize: 14, p: 0 }}>
              <ListItem sx={{ pl: 0, color: 'text.darkGray' }}>
                This information is used to prepopulate the following letters:
              </ListItem>
              <ListItem sx={listItemStyles}>
                Your financial adviser no longer works with Momentum
              </ListItem>
              <ListItem sx={listItemStyles}>
                We have unfortunate news about your Momentum financial adviser
              </ListItem>
              <ListItem sx={listItemStyles}>
                Sorry things aren&apos;t working out between you and your Momentum Financial Adviser
              </ListItem>
            </List>
            <Typography sx={{ fontSize: 14, mb: 3, color: 'text.darkGray' }}>
              Please add this information before you send these letters out, otherwise the letter
              will be blank.
            </Typography>
            <PropertyPolicy
              title="Previous financial adviser's name"
              value={client.previousAdviserName || 'none'}
            />
            <PropertyPolicy title="Contact number" value={client.previousAdviserPhone || 'none'} />
            <PropertyPolicy title="Email" value={client.previousAdviserEmail || 'none'} />
            <Button
              onClick={() => setOpenedDialog('previousAdviser')}
              variant="contained"
              color="primary"
            >
              Update
            </Button>
          </Box>
        )}
      </Content>
      <AdviserDetailsDialog
        client={client}
        open={openedDialog === 'adviser'}
        resetData={{
          adviserName: client.adviserName,
          adviserPhone: client.adviserPhone,
          adviserEmail: client.adviserEmail,
        }}
        defaultValues={{
          adviserName: '',
          adviserPhone: '',
          adviserEmail: '',
        }}
        name="adviserName"
        email="adviserEmail"
        phone="adviserPhone"
        onClose={handleCloseSubDialog}
      />
      <AdviserDetailsDialog
        client={client}
        open={openedDialog === 'previousAdviser'}
        onClose={handleCloseSubDialog}
        resetData={{
          previousAdviserName: client.previousAdviserName,
          previousAdviserPhone: client.previousAdviserPhone,
          previousAdviserEmail: client.previousAdviserEmail,
        }}
        defaultValues={{
          previousAdviserName: '',
          previousAdviserPhone: '',
          previousAdviserEmail: '',
        }}
        name="previousAdviserName"
        email="previousAdviserEmail"
        phone="previousAdviserPhone"
      />
    </Dialog>
  );
};

export default InfoDialog;
