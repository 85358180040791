import { useAppConfig } from '../contexts/configContext';
import { useCallback } from 'react';

/**
 * Hard change window url, even if app runs within iframe
 */
export const useBrowserNavigate = () => {
  const appConfig = useAppConfig();

  return {
    changeCurrentWindowUrl: useCallback(
      (path: string) => {
        const domain =
          process.env.NODE_ENV === 'production'
            ? appConfig?.config?.rootUrl
            : 'http://localhost:3000';
        (top ? top.window : window).location.href = `${domain}${path}`;
      },
      [appConfig?.config?.rootUrl],
    ),
    openNewTab: useCallback(
      (path: string) => {
        let result = path;
        if (!path.startsWith('http')) {
          const domain =
            process.env.NODE_ENV === 'production'
              ? appConfig?.config?.rootUrl
              : 'http://localhost:3000';
          result = `${domain}${path}`;
        }
        window.open(result, '_blank');
      },
      [appConfig?.config?.rootUrl],
    ),
  };
};
