import { AgentOps } from '../types/agent';
import { AppConfig, useAppConfig } from '../contexts/configContext';
import {
  getAuthToken,
  getProfileId,
  persistAuthTokens,
  persistProfileId,
  removeAuthToken,
} from '../lib/accessTokens';
import { MoloAgentAuth, MoloAgentProfile } from '../types/molo/moloAgentAuth';
import { MoloAgentExt } from '../types/molo/moloAgentExt';
import { MoloErrorResponse } from '../types/molo/moloErrorResponse';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

/**
 * Look for the ops adviser/agent profile within the list of current user's available profiles
 * @param availableProfiles available user profiles
 * @param targetProfileId Looking for a specific profile by id
 */
export const getAuthProfile = (
  availableProfiles: MoloAgentProfile[],
  targetProfileId?: string,
): MoloAgentProfile | undefined => {
  return targetProfileId
    ? // if profileId is provided, look for it
      availableProfiles.find(({ id }) => id === targetProfileId)
    : // look for OPS adviser/agent profile
      availableProfiles.find(({ type }) => ['OPS adviser', 'OPS agent'].includes(type));
};

export const useMyProfile = (): MoloAgentProfile | undefined => {
  const { data } = useQueryMe();
  return getAuthProfile(data?.authData?.Profiles.item || [], data?.profileId);
};

export const useAgentData = (): MoloAgentExt | null => {
  const { data } = useQueryMe();
  return data?.agentData || null;
};

export const useIsAdviser = (): boolean => {
  const agent = useAgentData();
  return agent?.item.typename === 'opsadviser';
};

/**
 * Make all requests related to the auth (app config, agent data, etc)
 */
const makeFullAuth = (
  appConfig: AppConfig,
  tokenOrFormData: string | FormData,
  profileId?: string,
) => {
  const agentAuthRequest =
    typeof tokenOrFormData === 'string'
      ? appConfig.moloApi.post<MoloAgentAuth | MoloErrorResponse>(
          '/Molo/Handler.Agent.Auth.ashx',
          null,
          {
            params: { token: tokenOrFormData },
          },
        )
      : appConfig.moloApi.post<MoloAgentAuth | MoloErrorResponse>(
          '/Molo/Handler.Agent.Auth.ashx',
          tokenOrFormData,
        );

  return agentAuthRequest
    .then(({ data }) => {
      if ('error' in data) {
        removeAuthToken();
        return null;
      }
      return data;
    })
    .then((data) => fetchFullAuthData(appConfig, data, profileId))
    .then((fullAuthData) => {
      if (fullAuthData) {
        persistProfileId(profileId);
      }
      return {
        ...fullAuthData,
        profileId,
      };
    });
};

/**
 * fetching agent data once because token valid only one time
 */
const fetchFullAuthData = async (
  appConfig: AppConfig,
  authData: MoloAgentAuth | null,
  profileId?: string,
) => {
  if (!authData) return null;
  const profile = getAuthProfile(authData.Profiles.item, profileId);
  return appConfig.moloApi
    .get<MoloAgentExt>(`/Molo/Handler.Agent.Ext.ashx?token=${profile?.token}`)
    .then(({ data: agentData }) => ({
      authData,
      agentData,
    }));
};

export const useQueryMe = () => {
  const appConfig = useAppConfig();

  return useQuery({
    queryKey: ['/auth/me'],
    // refetch current user data every 8h
    staleTime: 1000 * 60 * 60 * 8,
    queryFn: () => {
      const moloToken = getAuthToken('moloToken');
      return moloToken
        ? makeFullAuth(appConfig, moloToken, getProfileId() ?? undefined)
        : Promise.resolve(null);
    },
  });
};

export const useMutationLogin = () => {
  const queryClient = useQueryClient();
  const appConfig = useAppConfig();

  return useMutation({
    mutationFn: ({ username, password }: { username: string; password: string }) => {
      const formData = new FormData();
      formData.append('username', username);
      formData.append('password', password);
      return makeFullAuth(appConfig, formData);
    },
    onSuccess: (data) => {
      // Update "/auth/me" query to avoid extra API query
      queryClient.setQueryData(['/auth/me'], data);
    },
  });
};

/**
 * Fetch OPS API auth token separately
 */
export const useMutationLoginOpsApi = () => {
  const appConfig = useAppConfig();

  return useMutation({
    mutationFn: () => {
      return appConfig.opsApi.get<string>('/ApiAuth').then(({ data }) => data);
    },
    onSuccess: (opsToken) => {
      persistAuthTokens({ opsToken });
    },
  });
};

export const tokenLogin = ({ token, profileId }: { token: string; profileId?: string }) => {
  persistAuthTokens({ moloToken: token });
  persistProfileId(profileId);
};

export const useMutationLogout = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => {
      removeAuthToken();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['/auth/me'] });
    },
  });
};

/**
 * Fetch agent data from the ops API
 */
export const useAgentOpsData = () => {
  const agent = useAgentData();
  return useQuery<AgentOps>({
    queryKey: [`/Ops/Agent/${agent?.item.id}`],
    enabled: !!agent?.item.id,
  });
};
