import { AgentStats } from '../common/types/agentStats';
import { ArcElement, Chart, Legend, Tooltip } from 'chart.js';
import {
  Box,
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  linearProgressClasses,
  LinearProgressProps,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { Icon } from '@iconify/react';
import { ReactNode, useState } from 'react';
import { useMyProfile } from '../common/hooks/auth';
import { useQuery } from '@tanstack/react-query';

Chart.register(ArcElement, Tooltip, Legend);

export const PeriodSelect = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ButtonBase
        onClick={handleClick}
        sx={{
          backgroundColor: 'background.default',
          color: 'text.secondary',
          px: 2,
          py: 1,
          fontWeight: 600,
        }}
      >
        30 days
        <Box sx={{ ml: 1 }}>
          <Icon icon="teenyicons:caret-vertical-solid" width={14} />
        </Box>
      </ButtonBase>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleClose}>30 days</MenuItem>
        <MenuItem onClick={handleClose}>last week</MenuItem>
        <MenuItem onClick={handleClose}>today</MenuItem>
      </Menu>
    </>
  );
};

function LinearProgressWithLabel({
  textColor,
  ...props
}: LinearProgressProps & { value: number; textColor?: string }) {
  return (
    <Box>
      <Box sx={{ textAlign: 'right' }}>
        <Typography variant="caption" fontWeight={500} color={textColor}>{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
      <LinearProgress variant="determinate" {...props} />
    </Box>
  );
}

interface DashboardCardProps {
  title: string;
  percentage?: number;
  percentageColor?: string;
  percentageText?: string;
  action?: ReactNode;
  children: ReactNode;
  onClick?: () => void;
}

export const DashboardCard = ({
  title,
  children,
  percentage,
  percentageColor,
  percentageText,
  onClick,
}: DashboardCardProps) => (
  <Card elevation={6} sx={{ height: 1, cursor: onClick ? 'pointer' : undefined }} onClick={onClick}>
    <CardHeader
      sx={{ p: 3, pb: 0, '& .MuiCardHeader-action': { m: 0 } }}
      title={title}
      titleTypographyProps={{ color: 'text.disabled' }}
    />
    <CardContent sx={{ p: 3 }}>
      {children}
      {typeof percentage === 'number' && (
        <Box>
          <LinearProgressWithLabel
            textColor={percentageColor}
            value={percentage}
            sx={{
              height: 10,
              borderRadius: 5,
              [`&.${linearProgressClasses.colorPrimary}`]: {
                backgroundColor: '#F5F5F5',
              },
              [`& .${linearProgressClasses.bar}`]: {
                borderRadius: 5,
                backgroundColor: percentageColor,
              },
            }}
          />
          <Typography
            variant="caption"
            fontWeight={500}
            color="text.disabled"
            sx={{ textAlign: 'right', display: 'block' }}
          >
            {percentageText}
          </Typography>
        </Box>
      )}
    </CardContent>
  </Card>
);

interface OpsDashboardProps {
  onChangeTab: (tab: number) => void;
}

const OpsDashboard = ({ onChangeTab }: OpsDashboardProps) => {
  const profile = useMyProfile();
  const { data: agentsStats } = useQuery<AgentStats[]>({
    queryKey: [
      '/Ops/AgentClientCounts',
      {
        AgentID: profile?.id,
      },
    ],
    enabled: Boolean(profile?.id),
  });
  const agentStats = agentsStats?.[0];

  return (
    <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
      <Grid item xs={12} md={3} sm={4}>
        <DashboardCard title="Assigned clients">
          <Typography variant="h1" mb={4} color="text.darkGray">
            {agentStats?.total ?? '-'}
          </Typography>
        </DashboardCard>
      </Grid>
      <Grid item xs={12} md={3} sm={4}>
        <DashboardCard
          onClick={() => onChangeTab(1)}
          title="To allocate"
          percentage={((agentStats?.totalNotAllocated ?? 0) / (agentStats?.total ?? 0)) * 100}
          percentageColor="#FF9533"
          percentageText="of assigned clients"
        >
          <Typography variant="h1" mb={4} color="text.darkGray">
            {agentStats?.totalNotAllocated ?? '-'}
          </Typography>
        </DashboardCard>
      </Grid>
      <Grid item xs={12} md={3} sm={4}>
        <DashboardCard
          title="Closed"
          onClick={() => onChangeTab(2)}
          percentage={((agentStats?.totalClosed ?? 0) / (agentStats?.total ?? 0)) * 100}
          percentageColor="#E11B22"
          percentageText="of assigned clients"
        >
          <Typography variant="h1" mb={4} color="text.darkGray">
            {agentStats?.totalClosed ?? '-'}
          </Typography>
        </DashboardCard>
      </Grid>
      <Grid item xs={12} md={3} sm={4}>
        <DashboardCard
          title="Allocated"
          onClick={() => onChangeTab(3)}
          percentage={((agentStats?.totalAllocated ?? 0) / (agentStats?.total ?? 0)) * 100}
          percentageColor="#64AE42"
          percentageText="of assigned clients"
        >
          <Typography variant="h1" mb={4} color="text.darkGray">
            {agentStats?.totalAllocated ?? '-'}
          </Typography>
        </DashboardCard>
      </Grid>
    </Grid>
  );
};

export default OpsDashboard;
