import { Box } from '@mui/material';
import { MoloDoc } from '../../common/types/molo/moloDoc';

interface AudioContentProps {
  doc: MoloDoc;
}

const AudioContent = ({ doc }: AudioContentProps) => {
  if (doc.contentType.contentTypeSelection !== 'audioFile') return null;

  return (
    <Box>
      <audio controls src={doc.contentType.audioFile.url}>
        <code>audio</code> element.
      </audio>
    </Box>
  );
};

export default AudioContent;
