import { Box, Tab, Tabs } from '@mui/material';
import { ClientVerificationShareStatus } from '../../../../common/types/client';
import { StepContentProps } from '../../../../common/components/stepDialog';
import { useState } from 'react';
import ConfirmVerifyStatus from './confirmVerifyStatus';
import Instructions from '../../../../common/components/instructions';
import VerifyForm from './verifyForm';
import VerifyIdForm from './verifyIdForm';

const VerifyDialog = ({ client, process, onFormLoadingStateChange }: StepContentProps) => {
  const [currentTab, setCurrentTab] = useState(
    client.verifyShareStatusID === ClientVerificationShareStatus.shared ? 2 : 0,
  );
  return (
    <>
      <Tabs
        sx={{ borderBottom: 1, borderColor: '#DDDEE5' }}
        value={currentTab}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        onChange={(_, value) => setCurrentTab(value)}
      >
        <Tab label="Guide" />
        <Tab label="verify details" />
        <Tab label="verify ID" />
        <Tab label="Confirm status" />
      </Tabs>
      <Box sx={{ mt: 6 }}>
        {currentTab === 0 && <Instructions onSubmit={() => setCurrentTab(1)} keyword="verify" />}
        {currentTab === 1 && (
          <VerifyForm
            onFormLoadingStateChange={onFormLoadingStateChange}
            process={process}
            client={client}
            onSubmit={() => setCurrentTab(2)}
          />
        )}
        {currentTab === 2 && (
          <VerifyIdForm
            onFormLoadingStateChange={onFormLoadingStateChange}
            process={process}
            client={client}
            onSubmit={() => setCurrentTab(3)}
          />
        )}
        {currentTab === 3 && (
          <ConfirmVerifyStatus
            onFormLoadingStateChange={onFormLoadingStateChange}
            process={process}
            client={client}
          />
        )}
      </Box>
    </>
  );
};

export default VerifyDialog;
