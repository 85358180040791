import { DataGrid, GridColumns } from '@mui/x-data-grid';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import ExportToolbar from '../common/components/exportToolbar';

interface ProcessAverage {
  id: number;
  processID: number;
  agentID: number;
  averageTimeInStep: string;
  processNote: string;
  agentFullname: string;
}

const columns: GridColumns = [
  { field: 'processNote', headerName: 'Process', flex: 1 },
  { field: 'averageTimeInStep', headerName: 'Average Time In Step', flex: 1 },
];

const AgentReportPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: processAverageStats } = useQuery<ProcessAverage[]>({
    queryKey: ['/Ops/ClientProcessAverageTimeAgents', { AgentID: id }],
  });

  return (
    <DataGrid
      autoHeight
      components={{ Toolbar: ExportToolbar }}
      rows={processAverageStats ?? []}
      columns={columns}
      onRowClick={({ row }) => navigate(`/reports/process/${row.processID}/agents/${id}`)}
    />
  );
};

export default AgentReportPage;
