import {
  ArrayPath,
  Controller,
  ControllerFieldState,
  ControllerProps,
  ControllerRenderProps,
  FieldArray,
  FieldPath,
  FieldValues,
  useFieldArray,
  UseFormStateReturn,
} from 'react-hook-form';
import { Box, Button } from '@mui/material';
import { Icon } from '@iconify/react';

interface ArrayFieldProps<T extends FieldValues> {
  name: ArrayPath<T>;
  getItemName: (index: number) => FieldPath<T>;
  control: ControllerProps<T>['control'];
  render: ({
    field,
    fieldState,
    formState,
    item,
  }: {
    field: ControllerRenderProps<T>;
    fieldState: ControllerFieldState;
    formState: UseFormStateReturn<T>;
    item: Record<string, string>;
  }) => React.ReactElement;
  blankItem: FieldArray<T, ArrayPath<T>>;
}

const ArrayField = <T extends FieldValues>({
  name,
  getItemName,
  control,
  render,
  blankItem,
}: ArrayFieldProps<T>) => {
  const { fields, append } = useFieldArray({
    control,
    name,
  });

  return (
    <Box sx={{ display: 'grid', gap: 1 }}>
      {fields.map((field, index) => (
        <Controller
          key={field.id}
          name={getItemName(index)}
          control={control}
          render={(props) => render({ ...props, item: field })}
        />
      ))}
      <Box>
        <Button
          size="small"
          color="gray"
          variant="text"
          onClick={() => append(blankItem)}
          startIcon={<Icon icon="ic:twotone-plus" width={24} />}
        >
          Add another
        </Button>
      </Box>
    </Box>
  );
};

export default ArrayField;
