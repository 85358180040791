import { Box, Typography } from '@mui/material';
import { Client } from '../../common/types/client';
import {
  ClientProcess,
  PROCESS_AGENT_DECISION_ID,
  PROCESS_ALLOCATE_ID,
  PROCESS_CONTACT_ID,
} from '../../common/types/clientProcess';
import { DataGrid, GridColumns, GridFilterModel, GridRowParams } from '@mui/x-data-grid';
import { STATE_DONE_ID } from '../../common/types/clientState';
import { useDebounce } from 'usehooks-ts';
import { useIsAdviser, useMyProfile } from '../../common/hooks/auth';
import { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useStepDialogApi } from '../../common/contexts/stepDialogContext';
import { useUpdateClientProcessMutation } from '../../ops/mutations';
import AdviserContactDialog from '../../advisers/components/dialogs/contact';
import AdviserDecisionDialog from '../../advisers/components/dialogs/decision';
import AdviserEficaDialog from '../../advisers/components/dialogs/eficaDialog';
import AllocateDialog from '../../ops/components/dialogs/allocateDialog';
import ClientProcessSelector from './clientProcessSelector';
import ContactDialog from '../../ops/components/dialogs/contactDialog';
import EficaDialog from '../../ops/components/dialogs/eficaDialog';
import MonitoringDialog from '../../ops/components/dialogs/monitoringDialog';
import PreContactDialog from '../../ops/components/dialogs/preContactDialog';
import ProcessIndicator from '../../common/components/stepIndicator';
import StepDialog, { CloseReason, StepContentProps } from '../../common/components/stepDialog';
import VerifyDialog from '../../ops/components/dialogs/verifyDialog';

type StepComponentType = ((props: StepContentProps) => JSX.Element) & { formId?: string };

const processNameToComponent: Record<string, StepComponentType> = {
  verify: VerifyDialog,
  efica: EficaDialog,
  precontact: PreContactDialog,
  contact: ContactDialog,
  allocate: AllocateDialog,
  monitor: MonitoringDialog,
  agent_contact: AdviserContactDialog,
  agent_decision: AdviserDecisionDialog,
  agent_efica: AdviserEficaDialog,
};

interface IClientsInProgressProps {
  apiQueryFilters: Record<string, string | number>;
  // Extra clients to display in the grid (e.g. clients that are assigned)
  subQueryFilters?: Record<string, string | number>;
  statusHeaderName?: string;
  filterModel?: GridFilterModel;
}

const ClientsInProgress = ({
  apiQueryFilters,
  subQueryFilters,
  statusHeaderName = 'Status',
  filterModel,
}: IClientsInProgressProps) => {
  const profile = useMyProfile();
  const { performCloseCallbacks } = useStepDialogApi();
  const [formLoadingState, setFormLoadingState] = useState(false);
  const clientProcessMutation = useUpdateClientProcessMutation();

  const { data: clients } = useQuery<Client[]>({
    queryKey: ['/Ops/ClientLists', apiQueryFilters],
  });
  // Load additional clients to display in the grid (if needed)
  const { data: additionalClients } = useQuery<Client[]>({
    queryKey: ['/Ops/ClientLists', subQueryFilters],
    enabled: Boolean(subQueryFilters),
  });
  const clientsToDisplay = useMemo(
    () => [clients || [], additionalClients || []].flat(),
    [clients, additionalClients],
  );
  const debounceFilterModel = useDebounce(filterModel, 500);
  const [selectedClientPayload, setSelectedClientPayload] = useState<{
    clientId: number;
    process: ClientProcess;
    openNotes?: boolean;
  }>();
  const selectedClient = useMemo(
    () =>
      clients?.find(({ id }) => id === selectedClientPayload?.clientId) ||
      additionalClients?.find(({ id }) => id === selectedClientPayload?.clientId),
    [selectedClientPayload?.clientId, clients, additionalClients],
  );
  const isAdviser = useIsAdviser();

  const handleSelectProcess = (process: ClientProcess, client: Client, openNotes) => {
    setSelectedClientPayload({
      process,
      clientId: client.id,
      openNotes,
    });
  };

  const handleCloseStepDialog = async (reason: CloseReason) => {
    // Run all close listeners before closing the dialog
    const listenersResult = await performCloseCallbacks();
    // If any listener returns false, then we should not close the dialog
    if (listenersResult === false && reason !== 'close') {
      setFormLoadingState(false);
      return;
    }

    const { process, clientId } = selectedClientPayload ?? {};
    // Mark current process as done if reason of closing is 'finish'
    // After allocation API will switch to the next process automatically (no need to mark as done the allocation step)
    if (
      reason === 'finish' &&
      process &&
      clientId &&
      process.id !== PROCESS_ALLOCATE_ID &&
      process.id !== PROCESS_AGENT_DECISION_ID &&
      process.id !== PROCESS_CONTACT_ID &&
      !selectedClient?.isClosed
    ) {
      await clientProcessMutation.mutateAsync({
        ID: clientId,
        stateID: STATE_DONE_ID,
        processID: process.id,
        processNote: process.note,
        agentName: profile?.name ?? '',
      });
    }
    setFormLoadingState(false);
    setSelectedClientPayload(undefined);
  };

  const StepComponent = selectedClientPayload?.process?.name
    ? processNameToComponent[selectedClientPayload.process.name]
    : undefined;
  const columns: GridColumns<Client> = [
    {
      field: 'updated',
      type: 'date',
      hide: true,
    },
    {
      field: 'fullname',
      headerName: 'Client',
      flex: 1,
      minWidth: 180,
      renderCell: ({ row }) => (
        <Typography fontWeight={600} variant="caption">
          {row.fullname}
        </Typography>
      ),
    },
    { field: 'email', headerName: 'Email', minWidth: 250, flex: 1 },
    { field: 'phone', headerName: 'Cellphone', width: 130 },
    { field: 'idNumber', headerName: 'ID number', width: 100 },
    {
      field: 'processName',
      headerName: statusHeaderName,
      width: 270,
      valueGetter: ({ row }) => row.processID,
      renderCell: ({ row }) => <ProcessIndicator client={row} />,
    },
    {
      field: 'process',
      hide: true,
    },
    {
      field: 'actions',
      type: 'actions',
      renderHeader: () =>
        (apiQueryFilters?.isAllocated === 0 && !isAdviser) ||
        (isAdviser && !apiQueryFilters?.isSigned) ? (
          <Box width={180} justifyContent="space-between" display="flex">
            <Typography color="text.secondary" fontSize="14px" fontWeight={500}>
              Change step
            </Typography>
            <Typography color="text.secondary" fontSize="14px" ml={1} fontWeight={500}>
              History
            </Typography>
          </Box>
        ) : null,
      width: 220,
      getActions: ({ row }: GridRowParams<Client>) => [
        <ClientProcessSelector
          key="dropdown"
          client={row}
          onChange={(process, openNotes) => handleSelectProcess(process, row, openNotes)}
        />,
      ],
    },
  ];

  return (
    <Box>
      <DataGrid
        autoHeight
        disableSelectionOnClick
        filterModel={debounceFilterModel}
        initialState={{ sorting: { sortModel: [{ field: 'updated', sort: 'desc' }] } }}
        rows={clientsToDisplay}
        columns={columns}
        sx={{
          '& .MuiDataGrid-columnHeaderTitle': {
            color: 'text.secondary',
          },
        }}
        disableColumnMenu
        rowHeight={60}
        headerHeight={55}
      />
      {StepComponent && selectedClientPayload && selectedClient && (
        <StepDialog
          isLoading={clientProcessMutation.isLoading || formLoadingState}
          clientId={selectedClient.id}
          process={selectedClientPayload.process}
          onClose={handleCloseStepDialog}
          defaultOpenDialog={selectedClientPayload.openNotes ? 'history' : null}
          submitButtonLabel={selectedClientPayload.process.name === 'verify' ? 'Next' : undefined}
        >
          {(client) => (
            <StepComponent
              client={client}
              onFormLoadingStateChange={setFormLoadingState}
              process={selectedClientPayload.process}
            />
          )}
        </StepDialog>
      )}
    </Box>
  );
};

export default ClientsInProgress;
