import { Agent } from '../types/agent';
import { Controller, useForm } from 'react-hook-form';
import { Decision } from '../types/decision';
import { DEFAULT_ERROR_MESSAGE, DEFAULT_SAVED_MESSAGE } from '../contexts/configContext';
import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup } from '@mui/material';
import {
  PROCESS_AGENT_DECISION_ID,
  PROCESS_ALLOCATE_ID,
  PROCESS_SIGNED_ID,
} from '../types/clientProcess';
import { STATE_BUSY_ID, STATE_DONE_ID } from '../types/clientState';
import { StepContentProps } from './stepDialog';
import {
  useAllocateClientMutation,
  useCloseClientMutation,
  useUpdateClientProcessMutation,
  useUpdateDecisionMutation,
} from '../../ops/mutations';
import { useEffect } from 'react';
import { useMyProfile } from '../hooks/auth';
import { useQuery } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { useStepDialogApi } from '../contexts/stepDialogContext';

export const VIRTUAL_ADVISER_USER_NAME = 'OPSVirtualAdviser';

const decisionsToCloseClient = new Set([
  'Couldn’t contact',
  'Deceased',
  'They want to use their own adviser',
  'They want to withdraw their money',
  'They want to use another Momentum adviser',
  'They want to cancel / have cancelled their policy',
  'They are on a policy holiday',
  "They don't want an adviser",
]);

interface DecisionFormProps extends StepContentProps {
  decisions: string[];
}

interface FormType {
  ClientID: number;
  ProcessID: number;
  Text?: string;
}

/**
 * Component to render decision form for adviser or agent
 */
const DecisionForm = ({
  client,
  decisions,
  onFormLoadingStateChange,
  process,
}: DecisionFormProps) => {
  const { addCloseCallback, removeCloseCallback } = useStepDialogApi();
  const { enqueueSnackbar } = useSnackbar();
  const mutation = useUpdateDecisionMutation();
  const allocateMutation = useAllocateClientMutation();
  const closeClientMutation = useCloseClientMutation();
  const clientProcessMutation = useUpdateClientProcessMutation();
  const profile = useMyProfile();
  const { data: advisers } = useQuery<Agent[]>({
    queryKey: ['/OpsAdvisers'],
  });
  const virtualAdviser = advisers?.find(({ username }) => username === VIRTUAL_ADVISER_USER_NAME);
  const { data: processDecisions } = useQuery<Decision[]>({
    queryKey: ['/Ops/Decisions', { ClientID: client.id, ProcessID: process.id }],
  });
  const { handleSubmit, control, reset } = useForm<FormType>({
    defaultValues: {
      ClientID: client.id,
      ProcessID: process.id,
      Text: undefined,
    },
  });

  useEffect(() => {
    addCloseCallback('decisionForm', async () => {
      await handleSubmit(onSubmit)();
    });
    return () => {
      removeCloseCallback('decisionForm');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () =>
      onFormLoadingStateChange(
        mutation.isLoading || allocateMutation.isLoading || clientProcessMutation.isLoading,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mutation.isLoading, allocateMutation.isLoading, clientProcessMutation.isLoading],
  );

  useEffect(
    () =>
      reset({
        ClientID: client.id,
        ProcessID: process.id,
        Text: undefined,
      }),
    [client.id, reset, processDecisions, process.id],
  );

  const onSubmit = async (data: FormType) => {
    if (!data.Text) {
      return;
    }

    try {
      const response = await mutation.mutateAsync({ ...data, agentName: profile?.name ?? '' });
      reset({
        ClientID: client.id,
        ProcessID: process.id,
        Text: response.text || '',
      });

      const shouldAllocateToVirtualAdviser = data.Text === 'Allocated to virtual adviser';
      const shouldCloseClient =
        shouldAllocateToVirtualAdviser || decisionsToCloseClient.has(data.Text);

      await clientProcessMutation.mutateAsync({
        ID: client.id,
        stateID: STATE_DONE_ID,
        processID: process.id,
        processNote: process.note,
        agentName: profile?.name ?? '',
      });

      if (shouldCloseClient) {
        await closeClientMutation.mutateAsync({
          ID: client.id,
          agentName: profile?.name ?? '',
        });
      } else if (process.id === PROCESS_AGENT_DECISION_ID) {
        // if adviser are marking decision step as done, then we should move client to signed step and mark it as done too
        // to move a client to the "signed" section
        await clientProcessMutation.mutateAsync({
          ID: client.id,
          stateID: STATE_BUSY_ID,
          processID: PROCESS_SIGNED_ID,
          processNote: 'Signed',
          agentName: profile?.name ?? '',
        });
        await clientProcessMutation.mutateAsync({
          ID: client.id,
          stateID: STATE_DONE_ID,
          processID: PROCESS_SIGNED_ID,
          processNote: 'Signed',
          agentName: profile?.name ?? '',
        });
      }

      // Allocate client to the virtual adviser if needed
      if (shouldAllocateToVirtualAdviser && virtualAdviser) {
        await clientProcessMutation.mutateAsync({
          ID: client.id,
          stateID: STATE_DONE_ID,
          processID: client.processID,
          processNote: client.processNote,
          agentName: profile?.name ?? '',
        });

        await clientProcessMutation.mutateAsync({
          ID: client.id,
          stateID: STATE_BUSY_ID,
          processID: PROCESS_ALLOCATE_ID,
          processNote: 'Allocate',
          agentName: profile?.name ?? '',
        });

        await allocateMutation.mutateAsync({
          agentName: profile?.name ?? '',
          AllocatedAgentFullname: virtualAdviser.fullname,
          AllocatedAgentID: virtualAdviser.id,
          ID: client.id,
        });
      }

      enqueueSnackbar(DEFAULT_SAVED_MESSAGE, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar((error as Error).message || DEFAULT_ERROR_MESSAGE, { variant: 'error' });
    }
  };

  const isLoading = mutation.isLoading || !processDecisions;

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="Text"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl error={!!fieldState.error} variant="standard">
            <RadioGroup {...field} onChange={(_, value) => field.onChange(value)}>
              {decisions.map((decision) => (
                <FormControlLabel
                  key={decision}
                  value={decision}
                  control={<Radio />}
                  disabled={isLoading}
                  label={decision}
                />
              ))}
            </RadioGroup>
            <FormHelperText>{fieldState.error?.message}</FormHelperText>
          </FormControl>
        )}
      />
    </form>
  );
};

export default DecisionForm;
