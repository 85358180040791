import { Box, Tab, Tabs } from '@mui/material';
import { dialogsTabStyles } from '../../../../ops/components/dialogs/contactDialog';
import { StepContentProps } from '../../../../common/components/stepDialog';
import { useState } from 'react';
import AgentDocs from '../../../../agents/components/agentDocs';
import AgentLeads from '../../../../agents/components/agentLeads';
import Instructions from '../../../../common/components/instructions';

const AdviserContactDialog = ({ client, process }: StepContentProps) => {
  const [currentTab, setCurrentTab] = useState(0);
  return (
    <>
      <Tabs
        sx={dialogsTabStyles}
        value={currentTab}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        onChange={(_, value) => setCurrentTab(value)}
      >
        <Tab label="Guide" />
        <Tab label="docs" />
        <Tab label="responses" />
      </Tabs>
      <Box sx={{ mt: 6 }}>
        <Box sx={{ display: currentTab === 0 ? 'block' : 'none' }}>
          <Instructions keyword="adviser-contact" />
        </Box>
        <Box sx={{ display: currentTab === 1 ? 'block' : 'none' }}>
          <AgentDocs clientId={client.id} processId={process.id} keyword="adviser-contact" />
        </Box>
        {currentTab === 2 && <AgentLeads clientId={client.id} processId={process.id} />}
      </Box>
    </>
  );
};

export default AdviserContactDialog;
