/**
 * Pass params to the specific url with placeholders.
 * Using for typeforms and iframe urls from sanity.
 */
export const completeParametrizedUrl = (
  url: string,
  urlParameters: Record<string, string | undefined>,
): string =>
  Object.entries(urlParameters).reduce(
    (accumulator, [key, value]) => accumulator.replace(`{${key}}`, value || ''),
    url,
  );
