import { ClientProcess } from '../types/clientProcess';
import { useAgentOpsData, useIsAdviser } from './auth';
import { useQuery } from '@tanstack/react-query';

export const useQueryProcesses = (forceFetchAdviserProcesses = false) => {
  const { data: agentOps } = useAgentOpsData();
  const isAdviser = useIsAdviser();

  const result = useQuery<ClientProcess[]>({
    queryKey: [
      '/Ops/ClientProcesss',
      // fetch only allowed processes for the current agent type
      {
        AgentRoleID: forceFetchAdviserProcesses ? 0 : Number(!isAdviser),
        AdviserRoleID: forceFetchAdviserProcesses ? 1 : Number(isAdviser),
        typeID: agentOps?.clientProcessTypeID,
      },
    ],
    enabled: !!agentOps?.clientProcessTypeID,
  });

  return {
    ...result,
    data: result.data?.sort((a, b) => a.step - b.step), // sort processes by step field
  };
};
