import { MoloAgentExt, MoloAgentMedia } from '../types/molo/moloAgentExt';
import { useAppConfig } from '../contexts/configContext';
import castArray from 'lodash/castArray';

export const useAgentMediaUrl = (
  agent: MoloAgentExt | undefined,
  mediaType: MoloAgentMedia['extensiontypename'],
): string | null => {
  const appConfig = useAppConfig();
  const media =
    agent?.item.Medias.item &&
    castArray(agent.item.Medias.item).find(
      ({ extensiontypename }) => extensiontypename === mediaType,
    );
  if (!agent || !appConfig?.config || !media) return null;
  return `${appConfig.config.rootUrl}/Media/${media.extensiontypename}/${media.mediatoken}.${media.extensionname}`;
};
