import { Box, Typography } from '@mui/material';
import { useAppConfig } from '../../contexts/configContext';
import Content from '../context';

const linkSx = {
  textDecoration: 'none',
  borderBottom: 1,
  borderColor: 'gray.main',
  color: 'primary.main',
  mr: 4,
};

const LayoutFooter = () => {
  const { config } = useAppConfig();
  const getLink = (name: string) => config.externalLinks?.find((link) => link.name === name)?.url;
  const legalUrl = getLink('legal');
  const securityUrl = getLink('security');
  const termsUrl = getLink('terms');

  return (
    <Content sx={{ borderTop: 1, borderColor: 'gray.main' }}>
      <Box sx={{ py: 4, justifyContent: 'flex-end', display: 'flex' }}>
        {legalUrl && (
          <Box component="a" sx={linkSx} href={legalUrl}>
            Legal and Compliance
          </Box>
        )}
        {securityUrl && (
          <Box component="a" sx={linkSx} href={securityUrl}>
            Security and Fraud
          </Box>
        )}
        {termsUrl && (
          <Box component="a" sx={linkSx} href={termsUrl}>
            Terms and Conditions
          </Box>
        )}
      </Box>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 4 }}>
        © 2023 Momentum Financial Planning | © 2023 Momentum is a part of Momentum Metropolian Life
        Limited, an authorised financial services and registered credit provider
      </Typography>
    </Content>
  );
};

export default LayoutFooter;
